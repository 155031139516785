import { useState, useEffect } from "react"
import ApexCharts from 'apexcharts'
import { getCCAA } from "./api"
import { parseData, parseCCAAConfirmed, parseCCAADeaths, parseCCAARecovered } from "./dataUtils"
import { ApexChartsConfig, tooltipFunction, formatColors } from './config'
import { multipleLines, orderSeriesBySelected } from './utils'

/**
 * Hook para obtener los datos de la api ya formateados
 */
export const useFetchCCAAData = () => {
  const [ccaaData, setCCAAData] = useState({confirmed: [],
    recovered: [],
    deaths: []})
  
  useEffect(() => {
    getCCAA().then(data => {
      const formatedData = parseData(data)
      setCCAAData({
        confirmed: Object.values(parseCCAAConfirmed(formatedData)),
        recovered: Object.values(parseCCAARecovered(formatedData)),
        deaths: Object.values(parseCCAADeaths(formatedData))
      })
    })
  }, [])

  return [ccaaData]
}


const ccaaSerie = (ccaa, values) => {
  return values.find(element => element.name.includes(ccaa))
}

/**
 * Hack para forzar que Apex haga una animación cuando pasamos de una serie de datos, a dos o 3. 
 * @param {boolean} isMultiple Saber si estamos en modo muliple series de datos
 */
const forceAnimationMultipleLegedn = (isMultiple) => {
  if (isMultiple) {
    ApexCharts.exec('covid', 'updateSeries', [], false)
  }
}

/**
 * Hook que se encarga de modificar los datos de la serie en funcion de los párametros seleccionados por el usuario.
 * @param {Object} selectedSeries Series seleccionadas y no seleccionadas
 * @param {Object} ccaaData Object con los valores formateados de la API
 * @param {String[]} selectedCCAA Array con las comunidades seleccionadas
 */
export const useGraph = (selectedSeries, ccaaData, selectedCCAA) => {
  const [chartCCAAConfig, setChartCCAAConfig] = useState(() => ApexChartsConfig)
  useEffect(() => {
    if (!selectedCCAA[0]) {
      return ;
    }

    let series = []
    const isMultiple = multipleLines(selectedSeries)
    const selectedCCAAlength = selectedCCAA.length

    forceAnimationMultipleLegedn(isMultiple)

    if (selectedSeries.confirmed && ccaaData.confirmed.length > 0 && selectedCCAAlength > 0) {
      series = isMultiple ? series.concat(ccaaSerie(selectedCCAA[selectedCCAAlength - 1], ccaaData.confirmed)) : series.concat(ccaaData.confirmed)
    }
    if (selectedSeries.recovered && ccaaData.recovered.length > 0 && selectedCCAAlength > 0) {
      series = isMultiple ? series.concat(ccaaSerie(selectedCCAA[selectedCCAAlength - 1], ccaaData.recovered)) : series.concat(ccaaData.recovered)
    }
    if (selectedSeries.deaths && ccaaData.deaths.length > 0 && selectedCCAAlength > 0) {
      series = isMultiple ? series.concat(ccaaSerie(selectedCCAA[selectedCCAAlength - 1], ccaaData.deaths)) : series.concat(ccaaData.deaths)
    }

    setChartCCAAConfig(({options}) => {
      const newOptions = series.length === 0 ? options : {...options, ...{colors: formatColors(selectedSeries, selectedCCAA)}}
      newOptions.tooltip = {
        custom: tooltipFunction(selectedSeries, selectedCCAA)
      }

      return {
        series, options: newOptions
      }
    })
  }, [selectedSeries, ccaaData, selectedCCAA])

  return [chartCCAAConfig, setChartCCAAConfig]
}

/**
 * Hooks que se encarga de mostar u ocultar las anotaciones en el gráfico
 * @param {Function} setChartCCAAConfig Function que se encargar de cambiar el estado de la configuración de Apex
 */
export const useAnnotations = (setChartCCAAConfig) => {
  const [annotationsEnabled, setAnnotationsEnabled] = useState(true)

  useEffect(() => {
    setChartCCAAConfig(({options, series}) => {
      const newOptions = {...options, annotations: annotationsEnabled ? ApexChartsConfig.annotations : {
        yaxis: [],
        xaxis: [],
        points: []
      }}
      return {
        series, options: newOptions
      }
    })
  }, [annotationsEnabled, setChartCCAAConfig])

  return [annotationsEnabled, setAnnotationsEnabled]
}

/**
 * Hook que se encarga del manejo del ghosting en la gráfica. Se encarga de mostar u ocultar las comunidades no seleccionadas
 * y de que la seleccionadas se muestren las primeras.
 * @param {Boolean} ghosting Nos indica si esta activado o no
 * @param {Object} ccaaData Los datos formateados provenientes de la API
 * @param {String[]} selectedCCAA Array con las comunidades seleccionadas
 * @param {Object} selectedSeries Las series seleccionadas y no seleccionadas por el usuario
 * @param {Object} chartCCAConfig La configuración del gráfico
 */
export const useGhosting = (ghosting, ccaaData, selectedCCAA, selectedSeries, chartCCAConfig) => {
  useEffect(() => {
    if (!multipleLines(selectedSeries) && chartCCAConfig.series.length > 2) {
      ApexCharts.exec('covid', 'updateSeries', [], false)
      if (selectedSeries.confirmed && ccaaData.confirmed.length > 0) {
        if (ghosting) {
          const newSeries = orderSeriesBySelected([...ccaaData.confirmed], selectedCCAA)
          ApexCharts.exec('covid', 'updateSeries', newSeries, true)
        } else {
          const newSeries = ccaaData.confirmed.filter(element => {
            const name = element.name.split(" - ")[1]
            return selectedCCAA.some((ccaa) =>  name === ccaa)
          })
          ApexCharts.exec('covid', 'updateSeries', newSeries, true)
        }
      }
      if (selectedSeries.recovered && ccaaData.recovered.length > 0) {
        if (ghosting) {
          const newSeries = orderSeriesBySelected([...ccaaData.recovered], selectedCCAA)
          ApexCharts.exec('covid', 'updateSeries', newSeries, true)
        } else {
          const newSeries = ccaaData.recovered.filter(element => {
            const name = element.name.split(" - ")[1]
            return selectedCCAA.some((ccaa) =>  name === ccaa)
          })
          ApexCharts.exec('covid', 'updateSeries', newSeries, true)
        }
      }
      if (selectedSeries.deaths && ccaaData.deaths.length > 0) {
        if (ghosting) {
          const newSeries = orderSeriesBySelected([...ccaaData.deaths], selectedCCAA)
          ApexCharts.exec('covid', 'updateSeries', newSeries, true)
        } else {
          const newSeries = ccaaData.deaths.filter(element => {
            const name = element.name.split(" - ")[1]
            return selectedCCAA.some((ccaa) =>  name === ccaa)
          })
          ApexCharts.exec('covid', 'updateSeries', newSeries, true)
        }
      }
    }
  }, [ghosting, ccaaData, selectedCCAA, selectedSeries, chartCCAConfig])
}