import JSONData from '../endpoints.json'

const medianPondered5Days = (value, position, array, key, internalPosition) => {
  const nMinus2 = array[position - 2][1][internalPosition][key]
  const nMinus1 = array[position - 1][1][internalPosition][key]
  const nPlus2 = array[position + 2][1][internalPosition][key]
  const nPlus1 = array[position + 1][1][internalPosition][key]
  const total = nMinus1 + nMinus2 + nPlus1 + nPlus2 + value

  return Math.round(total / 5)
}

/**
 * Función para calcular el valor de un punto concreto. La media ponderada esta deshabilidatada, en el caso de querer habilitarse,
 * sería sustituir false por la condición que deseais para que se aplique la media ponderada
 * @param {Integer} value El valor actual del día
 * @param {Integer} position En la posición que se encuentra en el array de días
 * @param {Array} array El array de comunidades con sus valores de un dia concreto
 * @param {string} key el valor que estamos calculado, puede ser 'recovered', 'deaths', 'confirmed'
 * @param {Integer} internalPosition Posición en el array de comunidades
 */
const calculatedValue = (value, position, array, key, internalPosition) => {
  if (false) {
    return medianPondered5Days(value, position, array, key, internalPosition)
  } else {
    return value
  }
}

/**
 * Comprobar con el valor si es undefined o null
 * @param {Integer | undefined | null} value Valor que estamos comprobando
 */
const isInvalidValue = (value) => {
  return value === undefined || value === null
}

/**
 * Parseo de los datos de la API para coger los datos que nos interesa y darles un formateo inicial.
 * @param {Object[]} data Los datos en raw de la API
 */
export const parseData = data => {
  return data.timeline.map(day => {
    const date = new Date(day.fecha).getTime()

    const regions = day.regiones.map(region => {
      return {
        code: region.codigoIsoLugar,
        confirmed: region.data.casosConfirmadosDiario,
        deaths: region.data.casosFallecidosDiario,
        recovered: region.data.casosRecuperadosDiario
      }
    })
    return [date, regions]
  });
}

/**
 * Creamos un objeto que tiene como claves las comunidades y como valor un objeto con su nombre y array de valores
 * de los casos confirmados para usar con Apex
 * @param {Object[]} data Array de datos parseados con `parseData`
 */
export const parseCCAAConfirmed = data => {
  const ccaa = JSONData.ccaa

  return data.reduce((acc, [day, dataDay], idx, array) => {
    dataDay.forEach(({code, confirmed}, idx2) => {
      if (isInvalidValue(confirmed)) {
        return acc
      }

      if (acc[code]) {
        acc[code].data = [...acc[code].data, [day, calculatedValue(confirmed, idx, array, 'confirmed', idx2)]]
      } else {
        acc[code] = {name: `Confirmados - ${ccaa[code]}`, data: [[day, confirmed]]}
      }
    })
    return acc
  }, {})
}

/**
 * Creamos un objeto que tiene como claves las comunidades y como valor un objeto con su nombre y array de valores
 * de los casos recuperados para usar con Apex
 * @param {Object[]} data Array de datos parseados con `parseData`
 */
export const parseCCAARecovered = data => {
  const ccaa = JSONData.ccaa

  return data.reduce((acc, [day, dataDay], idx, array) => {
    dataDay.forEach(({code, recovered}, idx2) => {
      if (isInvalidValue(recovered)) {
        return acc
      }

      if (acc[code]) {
        acc[code].data = [...acc[code].data, [day, calculatedValue(recovered, idx, array, 'recovered', idx2)]]
      } else {
        acc[code] = {name: `Recuperados - ${ccaa[code]}`, data: [[day, recovered]]}
      }
    })
    return acc
  }, {})
}

/**
 * Creamos un objeto que tiene como claves las comunidades y como valor un objeto con su nombre y array de valores
 * de los casos muertos para usar con Apex
 * @param {Object[]} data Array de datos parseados con `parseData`
 */
export const parseCCAADeaths = data => {
  const ccaa = JSONData.ccaa

  return data.reduce((acc, [day, dataDay], idx, array) => {
    dataDay.forEach(({code, deaths}, idx2) => {
      if (isInvalidValue(deaths)) {
        return acc
      }

      if (acc[code]) {
        acc[code].data = [...acc[code].data, [day, calculatedValue(deaths, idx, array, 'deaths', idx2)]]
      } else {
        acc[code] = {name: `Fallecidos - ${ccaa[code]}`, data: [[day, deaths]]}
      }
    })
    return acc
  }, {})
}
