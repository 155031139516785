import React, { useState, useEffect } from "react"
import { Box, Flex } from '@sqymagma/elements'
import { theme } from '../../utils/themeGet'
import SubModDataEs from "../Chronology/Modules/SubModDataEs"

export default () => {
    const [confirmados, setConfirmados] = useState(null)
    const [fallecidos, setFallecidos] = useState(null)
    const [recuperados, setRecuperados] = useState(null)
    const [hosp, setHosp] = useState(null)
    const [hospUCI, setHospUCI] = useState(null)
    
    useEffect(() => {
    fetch(`https://covid19.secuoyas.io/api/v1/mod/viz`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {

            setConfirmados(resultData && {...resultData.confirmados, color: "support02"})
            setFallecidos(resultData && {...resultData.fallecidos, color: "support01"})
            setRecuperados(resultData && {...resultData.recuperados, color: "support03"})
           
            // Cambiar datos
            setHosp(resultData && {...resultData.hospitalizados, color: "support04"})
            setHospUCI(resultData && {...resultData.uci, color: "support05"})
        }) 
        .catch(err => console.log("Error getting data from API: "+err))
    }, [])




return (
    <Box width={1} overflow="hidden">
        <Flex width={1} flexWrap="wrap" bg="white" css={`{ border: 1px solid ${theme("color.ui03")} }`} borderRadius="3px">
            <SubModDataEs width={["49.5%","33%","19.8%"]} item={confirmados} title="Confirmados"/> 
            <Box my="xs" css={`{ border-right: 1px solid ${theme("color.ui03")} }`}></Box>
            <SubModDataEs width={["49.5%","33%","19.8%"]} item={hosp} title="Hospitalizados"/>  
            <Box my="xs" css={`{ border-right: 1px solid ${theme("color.ui03")} }`}></Box> 
            <SubModDataEs width={["49.5%","33%","19.8%"]} item={hospUCI} title="Hosp. UCI"/>   
            <Box my="xs" css={`{ border-right: 1px solid ${theme("color.ui03")} }`}></Box> 
            <SubModDataEs width={["49.5%","33%","19.8%"]} item={fallecidos} title="Fallecidos"/>  
            <Box my="xs" css={`{ border-right: 1px solid ${theme("color.ui03")} }`}></Box> 
            <SubModDataEs width={["49.5%","33%","19.8%"]} item={recuperados} title="Recuperados"/>  
            
        </Flex>
    </Box>
)}
