import { useState, useEffect } from 'react';

export const TOTALCOLORS = ['#FA0067', '#FA006770', '#FA006740']
export const PERCAPITACOLORS = ['#FFAA25', '#FFAA2580', '#FFAA2550']

export default (mode) => {
  const [seriesColor, setSeriesColor] = useState(null);

    useEffect(() => {
        if(mode.switched) {
            setSeriesColor( 
                [function({ value, seriesIndex, w }) {
                    if (seriesIndex === 0) {
                        return PERCAPITACOLORS[0]
                    } else if (seriesIndex === 1) {
                        return PERCAPITACOLORS[1]
                    } else if (seriesIndex === 2) {
                        return PERCAPITACOLORS[2]
                    } else {
                        return '#D9534F'
                    }
                }]
            )
            } else {
                setSeriesColor(
                [function({ value, seriesIndex, w }) {
                    if (seriesIndex === 0) {
                        return TOTALCOLORS[0]
                    } else if (seriesIndex === 1) {
                        return TOTALCOLORS[1]
                    } else if (seriesIndex === 2) {
                        return TOTALCOLORS[2]
                    } else {
                        return '#D9534F'
                    }
                }]
            )
            }
    },[mode])

  return seriesColor;
}