import React from "react"
import ContentLoader from "react-content-loader"

const LineChartLoader = (props) => (
  <ContentLoader 
    speed={2}
    style={{width:"100%"}}
    width={450}
    height={350}
    viewBox="0 0 450 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="3" width="88" height="10" /> 
    <rect x="3" y="38" rx="3" ry="3" width="120" height="6" /> 
    <rect x="2" y="69" rx="3" ry="3" width="24" height="10" /> 
    <rect x="44" y="70" rx="3" ry="3" width="333" height="3" /> 
    <circle cx="-85" cy="-65" r="20" /> 
    <rect x="136" y="38" rx="3" ry="3" width="15" height="6" /> 
    <rect x="155" y="38" rx="3" ry="3" width="30" height="6" /> 
    <rect x="202" y="38" rx="3" ry="3" width="15" height="6" /> 
    <rect x="221" y="38" rx="3" ry="3" width="30" height="6" /> 
    <rect x="265" y="38" rx="3" ry="3" width="15" height="6" /> 
    <rect x="284" y="38" rx="3" ry="3" width="30" height="6" /> 
    <rect x="331" y="38" rx="3" ry="3" width="15" height="6" /> 
    <rect x="350" y="38" rx="3" ry="3" width="30" height="6" /> 
    <rect x="2" y="99" rx="3" ry="3" width="24" height="10" /> 
    <rect x="44" y="101" rx="3" ry="3" width="333" height="3" /> 
    <rect x="2" y="135" rx="3" ry="3" width="24" height="10" /> 
    <rect x="44" y="137" rx="3" ry="3" width="333" height="3" /> 
    <rect x="2" y="166" rx="3" ry="3" width="24" height="10" /> 
    <rect x="44" y="168" rx="3" ry="3" width="333" height="3" /> 
    <rect x="3" y="199" rx="3" ry="3" width="24" height="10" /> 
    <rect x="45" y="200" rx="3" ry="3" width="333" height="3" /> 
    <rect x="3" y="229" rx="3" ry="3" width="24" height="10" /> 
    <rect x="45" y="231" rx="3" ry="3" width="333" height="3" /> 
    <rect x="3" y="265" rx="3" ry="3" width="24" height="10" /> 
    <rect x="45" y="267" rx="3" ry="3" width="333" height="3" /> 
    <rect x="3" y="296" rx="3" ry="3" width="24" height="10" /> 
    <rect x="45" y="298" rx="3" ry="3" width="333" height="3" /> 
    <rect x="47" y="314" rx="3" ry="3" width="326" height="12" />
  </ContentLoader>
)

export default LineChartLoader

