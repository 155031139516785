import React from "react";
import { Box, MaxWidth, Flex, Text } from "@sqymagma/elements";
import CCAAGraph from "../components/Viz/CCAAGraph";
import Section from "../components/Section";
import ModDataEsFull from "../components/Viz/ModDataEsFull";
import SpainMapGraph from "../components/Viz/SpainMapGraph";
import Modal from "../components/Modal";
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby";
import DownloadButton from "../components/DownloadButton";
import SEO from "../components/Seo";
import LineCharts from "../components/Viz/LineCharts";



const IndexPage = ({ data }) => {
  return (
    <Box bg="ui02">
      <SEO
        title={`Visualización de datos`}
        favicon={`/images/svg/virus-rose-ico.svg`}
        image={`/images/open-graph-image-red.png`}
        description={`Visualización de datos de la evolución del Coronavirus SARS-Cov-2 que provoca covid-19 en España`}
      />
      <DashboardHeader />
      <Section pt={["xl"]} pb={0}>
        <MaxWidth>
          <CCAAGraph />
        </MaxWidth>
      </Section>
      <Section pt={["xl"]} pb={["xl"]}>
        <SpainMapGraph />
      </Section>
      <LineCharts/>
    </Box>
  );
};

export default IndexPage;

const DashboardHeader = (_) => (
  <Flex bg="ui03" py="m">
    <MaxWidth>
      <Flex flexDirection={["column", null, "row"]}>
        <Box width={[1, null, 3 / 12]} mb={["s", null, 0]}>
          <Text as="h2" textStyle="xl" mr="s">
            Datos agregados por gravedad de los casos
          </Text>
          <Text as="p" textStyle="xs" mr="s" mt="m" color="brand02">
              <Modal textLink={"Ver diagrama del proceso y estados"}>
                  <Box position="relative">
                    <Box position="fixed" bottom="70px" right="70px" zIndex="9">
                      <DownloadButton file="covid-secuoyas-procesos-estados-poster.pdf"/>
                    </Box>
                    <Poster/>
                  </Box>
              </Modal>
          </Text>
        </Box>
        <Box width={[1, null, 9 / 12]}>
          <ModDataEsFull />
        </Box>
      </Flex>
    </MaxWidth>
  </Flex>
);


const Poster = () => {
  const imageData = useStaticQuery(graphql`
	query {
		posterImg: file(name: { eq: "procesos-estados-poster" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
        }
    }`);

  return (
      <Img fluid={imageData.posterImg.childImageSharp.fluid} height={1}/>
  )
}