export const spainTopoJson = {
    "type": "Topology",
    "transform": {
        "scale": [
            0.00036946150051793034,
            0.0002696116824146107
        ],
        "translate": [
            -18.160694999999976,
            27.637361000000112
        ]
    },
    "arcs": [
        [
            [
                30290,
                35534
            ],
            [
                68,
                -51
            ],
            [
                20,
                -76
            ],
            [
                -58,
                0
            ],
            [
                -45,
                101
            ],
            [
                15,
                26
            ]
        ],
        [
            [
                30256,
                35647
            ],
            [
                22,
                -23
            ],
            [
                -2,
                -46
            ],
            [
                -62,
                -6
            ],
            [
                42,
                75
            ]
        ],
        [
            [
                30278,
                35825
            ],
            [
                2,
                -65
            ],
            [
                18,
                -40
            ],
            [
                -12,
                -91
            ],
            [
                -36,
                22
            ],
            [
                -26,
                6
            ],
            [
                -64,
                -105
            ],
            [
                51,
                -13
            ],
            [
                14,
                -34
            ],
            [
                43,
                -11
            ],
            [
                53,
                -126
            ],
            [
                -147,
                95
            ],
            [
                -55,
                25
            ],
            [
                -139,
                35
            ],
            [
                -145,
                10
            ],
            [
                -60,
                -16
            ],
            [
                16,
                91
            ],
            [
                -32,
                20
            ],
            [
                -24,
                -84
            ],
            [
                27,
                -52
            ],
            [
                -241,
                -10
            ],
            [
                -92,
                -10
            ],
            [
                -51,
                -23
            ],
            [
                -68,
                -7
            ],
            [
                -51,
                -58
            ],
            [
                -80,
                -20
            ],
            [
                -73,
                43
            ],
            [
                -15,
                141
            ],
            [
                -21,
                66
            ],
            [
                -32,
                27
            ],
            [
                1,
                48
            ],
            [
                27,
                57
            ],
            [
                -34,
                59
            ],
            [
                -18,
                61
            ],
            [
                3,
                90
            ],
            [
                -13,
                59
            ],
            [
                22,
                77
            ],
            [
                -10,
                85
            ],
            [
                -58,
                61
            ],
            [
                44,
                46
            ],
            [
                -32,
                35
            ],
            [
                3,
                77
            ],
            [
                -36,
                80
            ],
            [
                8,
                72
            ],
            [
                -47,
                27
            ],
            [
                -17,
                74
            ],
            [
                -60,
                29
            ],
            [
                0,
                43
            ],
            [
                -28,
                57
            ],
            [
                41,
                51
            ],
            [
                58,
                169
            ],
            [
                40,
                44
            ],
            [
                1,
                39
            ],
            [
                39,
                41
            ],
            [
                32,
                67
            ],
            [
                -11,
                40
            ],
            [
                3,
                86
            ],
            [
                78,
                169
            ],
            [
                -10,
                41
            ],
            [
                36,
                45
            ],
            [
                38,
                -6
            ],
            [
                51,
                70
            ],
            [
                79,
                78
            ],
            [
                62,
                40
            ],
            [
                73,
                152
            ],
            [
                48,
                75
            ],
            [
                -7,
                77
            ],
            [
                74,
                96
            ],
            [
                -7,
                32
            ],
            [
                24,
                82
            ],
            [
                -3,
                47
            ],
            [
                -33,
                39
            ],
            [
                44,
                53
            ],
            [
                99,
                16
            ],
            [
                62,
                22
            ],
            [
                35,
                -34
            ],
            [
                82,
                33
            ],
            [
                39,
                0
            ],
            [
                4,
                107
            ],
            [
                51,
                38
            ],
            [
                48,
                -9
            ],
            [
                77,
                -43
            ],
            [
                47,
                -48
            ],
            [
                53,
                40
            ],
            [
                49,
                -15
            ],
            [
                22,
                52
            ],
            [
                -4,
                53
            ],
            [
                27,
                20
            ],
            [
                1,
                132
            ],
            [
                83,
                161
            ],
            [
                4,
                85
            ],
            [
                -31,
                16
            ],
            [
                43,
                96
            ],
            [
                59,
                81
            ]
        ],
        [
            [
                30393,
                39208
            ],
            [
                25,
                -30
            ],
            [
                51,
                7
            ],
            [
                123,
                -82
            ],
            [
                97,
                -23
            ],
            [
                74,
                18
            ],
            [
                2,
                -71
            ],
            [
                -14,
                -58
            ],
            [
                -44,
                -88
            ],
            [
                72,
                -41
            ],
            [
                53,
                11
            ],
            [
                31,
                -72
            ],
            [
                115,
                0
            ],
            [
                91,
                15
            ],
            [
                161,
                2
            ],
            [
                114,
                -165
            ],
            [
                -18,
                -96
            ],
            [
                112,
                5
            ],
            [
                56,
                -25
            ],
            [
                81,
                -9
            ],
            [
                44,
                -41
            ],
            [
                13,
                61
            ],
            [
                82,
                86
            ],
            [
                -24,
                31
            ],
            [
                105,
                10
            ],
            [
                69,
                -39
            ],
            [
                57,
                3
            ],
            [
                24,
                -84
            ],
            [
                -2,
                -36
            ],
            [
                35,
                -53
            ],
            [
                46,
                -15
            ],
            [
                70,
                -74
            ],
            [
                137,
                -25
            ],
            [
                35,
                -46
            ],
            [
                102,
                1
            ],
            [
                16,
                -38
            ],
            [
                51,
                -12
            ],
            [
                62,
                43
            ],
            [
                49,
                54
            ],
            [
                57,
                36
            ],
            [
                56,
                -1
            ],
            [
                171,
                60
            ],
            [
                68,
                -23
            ],
            [
                35,
                30
            ],
            [
                86,
                0
            ],
            [
                21,
                -16
            ],
            [
                57,
                134
            ],
            [
                -4,
                57
            ],
            [
                40,
                11
            ],
            [
                28,
                49
            ],
            [
                -5,
                57
            ],
            [
                -50,
                40
            ],
            [
                8,
                57
            ],
            [
                37,
                68
            ],
            [
                86,
                35
            ],
            [
                17,
                97
            ],
            [
                100,
                59
            ],
            [
                125,
                12
            ],
            [
                27,
                31
            ],
            [
                66,
                -20
            ],
            [
                23,
                37
            ],
            [
                47,
                17
            ],
            [
                80,
                -32
            ],
            [
                35,
                -56
            ],
            [
                -46,
                -27
            ],
            [
                30,
                -51
            ],
            [
                -65,
                -52
            ],
            [
                -32,
                3
            ],
            [
                -27,
                -39
            ],
            [
                15,
                -45
            ],
            [
                6,
                -100
            ],
            [
                61,
                -24
            ],
            [
                45,
                10
            ],
            [
                66,
                67
            ],
            [
                6,
                27
            ],
            [
                91,
                108
            ],
            [
                132,
                -24
            ],
            [
                29,
                70
            ],
            [
                38,
                -7
            ],
            [
                65,
                74
            ],
            [
                -8,
                115
            ],
            [
                48,
                28
            ],
            [
                -9,
                57
            ],
            [
                -4,
                154
            ],
            [
                -50,
                68
            ],
            [
                -31,
                133
            ],
            [
                -53,
                42
            ],
            [
                22,
                81
            ],
            [
                2,
                96
            ],
            [
                -50,
                32
            ],
            [
                2,
                53
            ],
            [
                44,
                123
            ],
            [
                41,
                45
            ],
            [
                90,
                66
            ],
            [
                43,
                -25
            ],
            [
                52,
                25
            ],
            [
                14,
                79
            ],
            [
                27,
                1
            ],
            [
                67,
                74
            ],
            [
                70,
                28
            ],
            [
                8,
                66
            ],
            [
                24,
                49
            ],
            [
                42,
                20
            ],
            [
                5,
                50
            ],
            [
                38,
                22
            ],
            [
                18,
                62
            ],
            [
                90,
                -24
            ],
            [
                71,
                -4
            ],
            [
                -4,
                47
            ],
            [
                48,
                71
            ],
            [
                106,
                63
            ],
            [
                67,
                77
            ],
            [
                33,
                8
            ],
            [
                25,
                70
            ],
            [
                30,
                -28
            ],
            [
                48,
                20
            ],
            [
                30,
                43
            ],
            [
                -20,
                86
            ],
            [
                16,
                46
            ],
            [
                138,
                11
            ],
            [
                48,
                -31
            ],
            [
                88,
                37
            ],
            [
                58,
                43
            ]
        ],
        [
            [
                35495,
                41140
            ],
            [
                60,
                -10
            ],
            [
                61,
                -68
            ],
            [
                3,
                -42
            ],
            [
                98,
                -42
            ],
            [
                77,
                -15
            ],
            [
                47,
                17
            ],
            [
                93,
                6
            ],
            [
                42,
                -25
            ],
            [
                5,
                -189
            ],
            [
                19,
                -59
            ],
            [
                68,
                -24
            ],
            [
                32,
                -37
            ],
            [
                97,
                -2
            ],
            [
                51,
                -38
            ],
            [
                133,
                -59
            ],
            [
                33,
                4
            ],
            [
                31,
                -83
            ],
            [
                77,
                -7
            ],
            [
                92,
                -84
            ],
            [
                30,
                -6
            ],
            [
                9,
                -55
            ],
            [
                28,
                -52
            ],
            [
                54,
                -31
            ],
            [
                46,
                5
            ],
            [
                178,
                -111
            ],
            [
                14,
                -45
            ],
            [
                80,
                -73
            ],
            [
                49,
                -93
            ],
            [
                48,
                5
            ],
            [
                49,
                -28
            ],
            [
                29,
                -42
            ],
            [
                31,
                15
            ],
            [
                165,
                -81
            ],
            [
                66,
                -46
            ],
            [
                54,
                -21
            ],
            [
                57,
                -1
            ],
            [
                -27,
                113
            ],
            [
                6,
                49
            ],
            [
                57,
                41
            ],
            [
                87,
                -16
            ],
            [
                182,
                -60
            ],
            [
                30,
                19
            ],
            [
                44,
                -26
            ],
            [
                45,
                11
            ],
            [
                69,
                -21
            ],
            [
                45,
                11
            ],
            [
                112,
                -17
            ],
            [
                60,
                -26
            ],
            [
                120,
                -8
            ],
            [
                32,
                11
            ],
            [
                102,
                1
            ],
            [
                102,
                29
            ],
            [
                56,
                -7
            ],
            [
                64,
                66
            ],
            [
                1,
                74
            ],
            [
                60,
                33
            ],
            [
                117,
                9
            ],
            [
                68,
                -38
            ],
            [
                26,
                39
            ],
            [
                83,
                -43
            ],
            [
                129,
                -29
            ],
            [
                84,
                -41
            ],
            [
                94,
                43
            ],
            [
                8,
                170
            ],
            [
                42,
                2
            ],
            [
                68,
                -23
            ],
            [
                28,
                -54
            ],
            [
                10,
                -84
            ],
            [
                61,
                -2
            ],
            [
                90,
                -37
            ],
            [
                52,
                8
            ],
            [
                82,
                40
            ],
            [
                125,
                106
            ],
            [
                -10,
                22
            ],
            [
                15,
                110
            ],
            [
                131,
                26
            ],
            [
                58,
                -11
            ],
            [
                68,
                -70
            ],
            [
                164,
                -28
            ],
            [
                107,
                -11
            ],
            [
                81,
                -36
            ],
            [
                53,
                -5
            ],
            [
                33,
                37
            ],
            [
                144,
                109
            ],
            [
                161,
                -235
            ],
            [
                36,
                52
            ],
            [
                -4,
                79
            ],
            [
                45,
                18
            ],
            [
                36,
                61
            ],
            [
                68,
                12
            ],
            [
                74,
                -25
            ],
            [
                58,
                -45
            ],
            [
                108,
                92
            ],
            [
                67,
                25
            ],
            [
                50,
                58
            ],
            [
                98,
                64
            ],
            [
                23,
                47
            ],
            [
                62,
                -39
            ],
            [
                18,
                -30
            ],
            [
                81,
                -49
            ],
            [
                81,
                -16
            ],
            [
                76,
                39
            ],
            [
                124,
                21
            ],
            [
                50,
                -35
            ],
            [
                37,
                -48
            ],
            [
                -28,
                -52
            ],
            [
                27,
                -86
            ],
            [
                -27,
                -71
            ],
            [
                15,
                -75
            ],
            [
                58,
                -40
            ],
            [
                41,
                11
            ],
            [
                51,
                -22
            ],
            [
                88,
                -9
            ],
            [
                -24,
                -98
            ],
            [
                35,
                -82
            ],
            [
                -16,
                -182
            ],
            [
                10,
                -20
            ],
            [
                110,
                -36
            ],
            [
                12,
                -93
            ],
            [
                -20,
                -26
            ],
            [
                9,
                -76
            ],
            [
                -36,
                -53
            ],
            [
                23,
                -51
            ],
            [
                -2,
                -72
            ],
            [
                -60,
                -46
            ],
            [
                -16,
                -63
            ],
            [
                -32,
                -27
            ],
            [
                -34,
                -62
            ],
            [
                -39,
                -19
            ],
            [
                -20,
                -70
            ],
            [
                -84,
                -88
            ],
            [
                80,
                -53
            ],
            [
                47,
                -5
            ],
            [
                71,
                -57
            ],
            [
                125,
                -36
            ],
            [
                23,
                -35
            ],
            [
                83,
                -11
            ],
            [
                54,
                -28
            ],
            [
                85,
                10
            ]
        ],
        [
            [
                42817,
                38532
            ],
            [
                117,
                -89
            ],
            [
                2,
                -44
            ],
            [
                56,
                -112
            ],
            [
                49,
                -37
            ],
            [
                57,
                -80
            ],
            [
                81,
                -44
            ],
            [
                62,
                -48
            ],
            [
                35,
                -55
            ],
            [
                55,
                32
            ],
            [
                77,
                11
            ],
            [
                67,
                -84
            ],
            [
                34,
                -11
            ],
            [
                64,
                32
            ],
            [
                110,
                -55
            ],
            [
                46,
                45
            ],
            [
                27,
                -1
            ],
            [
                54,
                -44
            ],
            [
                -56,
                -101
            ],
            [
                -3,
                -83
            ],
            [
                -33,
                -163
            ],
            [
                35,
                -174
            ],
            [
                -32,
                -31
            ],
            [
                -14,
                -70
            ],
            [
                2,
                -102
            ],
            [
                27,
                -66
            ],
            [
                39,
                -38
            ],
            [
                29,
                -99
            ],
            [
                57,
                -66
            ],
            [
                5,
                -47
            ],
            [
                225,
                -365
            ],
            [
                33,
                -40
            ],
            [
                36,
                -89
            ],
            [
                99,
                -80
            ],
            [
                -8,
                67
            ],
            [
                202,
                -33
            ],
            [
                87,
                -80
            ],
            [
                106,
                -75
            ],
            [
                96,
                -96
            ]
        ],
        [
            [
                44742,
                36117
            ],
            [
                -43,
                -15
            ],
            [
                -8,
                -42
            ],
            [
                -58,
                -8
            ],
            [
                -52,
                -72
            ],
            [
                -45,
                -117
            ],
            [
                -134,
                -152
            ],
            [
                -75,
                -115
            ],
            [
                -32,
                -6
            ],
            [
                -35,
                -72
            ],
            [
                -44,
                -171
            ],
            [
                1,
                -67
            ],
            [
                -28,
                -116
            ],
            [
                -29,
                -35
            ],
            [
                -13,
                -57
            ],
            [
                1,
                -82
            ],
            [
                -46,
                -113
            ],
            [
                -24,
                -16
            ],
            [
                -6,
                -81
            ],
            [
                -31,
                -65
            ],
            [
                -33,
                -34
            ],
            [
                13,
                -144
            ],
            [
                -25,
                -47
            ],
            [
                -75,
                9
            ],
            [
                -32,
                -21
            ],
            [
                -60,
                -129
            ],
            [
                -37,
                16
            ],
            [
                -60,
                -77
            ],
            [
                1,
                -70
            ],
            [
                18,
                -40
            ],
            [
                -15,
                -59
            ],
            [
                -62,
                -5
            ],
            [
                -101,
                -103
            ],
            [
                6,
                -105
            ],
            [
                -34,
                -34
            ],
            [
                -82,
                -31
            ],
            [
                -54,
                -68
            ],
            [
                -3,
                -42
            ],
            [
                -74,
                -5
            ],
            [
                -51,
                -27
            ],
            [
                -65,
                -8
            ],
            [
                -87,
                144
            ],
            [
                -78,
                112
            ],
            [
                -100,
                115
            ],
            [
                -71,
                47
            ],
            [
                -133,
                30
            ],
            [
                -105,
                -60
            ],
            [
                -65,
                -55
            ],
            [
                -85,
                82
            ],
            [
                -53,
                5
            ],
            [
                -55,
                -36
            ],
            [
                -63,
                1
            ],
            [
                -18,
                -21
            ],
            [
                -91,
                -18
            ],
            [
                -91,
                -136
            ],
            [
                -44,
                -165
            ],
            [
                -81,
                -108
            ],
            [
                -81,
                -35
            ],
            [
                -67,
                -45
            ],
            [
                -30,
                12
            ],
            [
                -167,
                -15
            ],
            [
                -14,
                28
            ],
            [
                -81,
                59
            ],
            [
                -71,
                -5
            ],
            [
                -36,
                -32
            ],
            [
                -33,
                19
            ],
            [
                -86,
                120
            ],
            [
                -84,
                70
            ],
            [
                -53,
                -1
            ],
            [
                -69,
                -39
            ],
            [
                -61,
                14
            ],
            [
                -51,
                -11
            ],
            [
                -65,
                22
            ],
            [
                -108,
                -7
            ],
            [
                -106,
                20
            ],
            [
                -111,
                -14
            ],
            [
                -134,
                13
            ],
            [
                -21,
                -20
            ],
            [
                -94,
                34
            ],
            [
                -45,
                -4
            ],
            [
                -118,
                -57
            ],
            [
                -47,
                14
            ],
            [
                -72,
                -35
            ],
            [
                -45,
                -67
            ],
            [
                -62,
                -12
            ],
            [
                -69,
                -36
            ],
            [
                -13,
                -25
            ],
            [
                -131,
                -1
            ],
            [
                -37,
                9
            ],
            [
                -73,
                77
            ],
            [
                -50,
                24
            ],
            [
                -76,
                -34
            ],
            [
                -62,
                28
            ],
            [
                -64,
                80
            ],
            [
                -53,
                -9
            ],
            [
                -119,
                7
            ],
            [
                -31,
                -51
            ],
            [
                -75,
                16
            ],
            [
                -88,
                -19
            ],
            [
                -41,
                35
            ],
            [
                -59,
                -25
            ],
            [
                -51,
                54
            ],
            [
                -113,
                29
            ],
            [
                -85,
                -10
            ],
            [
                -51,
                -37
            ],
            [
                -101,
                0
            ],
            [
                -93,
                -53
            ],
            [
                -112,
                46
            ],
            [
                -89,
                12
            ],
            [
                -96,
                23
            ],
            [
                -62,
                -22
            ],
            [
                -58,
                -66
            ],
            [
                -70,
                -5
            ],
            [
                -118,
                -30
            ],
            [
                -186,
                -18
            ],
            [
                -103,
                16
            ],
            [
                -145,
                -9
            ],
            [
                -24,
                15
            ],
            [
                -121,
                24
            ],
            [
                -67,
                -21
            ],
            [
                -30,
                -32
            ],
            [
                -73,
                -162
            ],
            [
                -71,
                -93
            ],
            [
                -76,
                -150
            ],
            [
                -82,
                -75
            ],
            [
                -54,
                3
            ],
            [
                -66,
                -24
            ],
            [
                -67,
                -58
            ],
            [
                -54,
                -144
            ],
            [
                -27,
                -48
            ],
            [
                -108,
                -11
            ],
            [
                -41,
                -48
            ],
            [
                -47,
                -12
            ],
            [
                -115,
                -7
            ],
            [
                -134,
                43
            ],
            [
                -152,
                40
            ],
            [
                -102,
                -2
            ],
            [
                -101,
                -26
            ],
            [
                -51,
                -59
            ],
            [
                -65,
                -23
            ],
            [
                -80,
                -70
            ],
            [
                -71,
                9
            ],
            [
                -72,
                -43
            ],
            [
                -64,
                1
            ],
            [
                -70,
                -63
            ],
            [
                -72,
                -19
            ],
            [
                -53,
                -51
            ],
            [
                -57,
                0
            ],
            [
                -110,
                -130
            ],
            [
                -45,
                -115
            ],
            [
                -33,
                -138
            ],
            [
                -33,
                -1
            ],
            [
                -37,
                -68
            ],
            [
                -68,
                -175
            ],
            [
                -48,
                -43
            ],
            [
                -38,
                -129
            ],
            [
                -27,
                -164
            ],
            [
                -39,
                9
            ],
            [
                -81,
                83
            ],
            [
                -84,
                4
            ],
            [
                -58,
                -44
            ],
            [
                -29,
                -60
            ],
            [
                32,
                -27
            ],
            [
                -20,
                -103
            ],
            [
                1,
                -113
            ],
            [
                46,
                -39
            ],
            [
                -71,
                -78
            ],
            [
                -36,
                -21
            ],
            [
                -54,
                17
            ],
            [
                -89,
                -65
            ],
            [
                -58,
                -20
            ],
            [
                -116,
                -70
            ],
            [
                -74,
                -5
            ],
            [
                -33,
                78
            ],
            [
                -70,
                67
            ],
            [
                -52,
                20
            ],
            [
                -61,
                48
            ],
            [
                -60,
                -39
            ],
            [
                -110,
                50
            ],
            [
                -53,
                57
            ],
            [
                -32,
                7
            ],
            [
                -38,
                -43
            ],
            [
                -52,
                85
            ],
            [
                -23,
                6
            ],
            [
                -49,
                109
            ],
            [
                -94,
                109
            ],
            [
                -120,
                97
            ],
            [
                -53,
                1
            ],
            [
                -88,
                -37
            ],
            [
                -40,
                2
            ],
            [
                -105,
                32
            ],
            [
                -69,
                56
            ],
            [
                -71,
                162
            ],
            [
                -19,
                87
            ],
            [
                -73,
                91
            ],
            [
                -83,
                14
            ],
            [
                -28,
                56
            ],
            [
                -34,
                120
            ],
            [
                -47,
                97
            ],
            [
                -57,
                41
            ],
            [
                -34,
                67
            ],
            [
                -47,
                146
            ],
            [
                -41,
                39
            ],
            [
                -22,
                84
            ],
            [
                -112,
                244
            ],
            [
                57,
                -41
            ],
            [
                46,
                -79
            ],
            [
                -12,
                -57
            ],
            [
                30,
                -76
            ],
            [
                43,
                -22
            ],
            [
                63,
                13
            ],
            [
                10,
                50
            ],
            [
                41,
                20
            ],
            [
                2,
                54
            ],
            [
                62,
                33
            ],
            [
                -2,
                44
            ],
            [
                -66,
                23
            ],
            [
                -39,
                -61
            ],
            [
                -56,
                -27
            ],
            [
                -61,
                62
            ],
            [
                60,
                14
            ],
            [
                26,
                104
            ],
            [
                -9,
                107
            ],
            [
                -37,
                20
            ],
            [
                -36,
                -34
            ],
            [
                -41,
                20
            ],
            [
                -11,
                49
            ],
            [
                -62,
                75
            ],
            [
                -74,
                30
            ],
            [
                -55,
                7
            ],
            [
                -59,
                -20
            ],
            [
                -72,
                44
            ],
            [
                -14,
                93
            ],
            [
                -34,
                49
            ],
            [
                -78,
                182
            ],
            [
                -9,
                67
            ],
            [
                53,
                22
            ],
            [
                35,
                60
            ],
            [
                33,
                5
            ],
            [
                139,
                112
            ],
            [
                -119,
                43
            ],
            [
                -19,
                26
            ],
            [
                -79,
                220
            ],
            [
                -85,
                184
            ],
            [
                -162,
                207
            ],
            [
                -212,
                176
            ],
            [
                -158,
                114
            ],
            [
                -339,
                229
            ],
            [
                -258,
                125
            ],
            [
                -132,
                127
            ],
            [
                -41,
                69
            ],
            [
                86,
                115
            ],
            [
                -33,
                8
            ],
            [
                -53,
                -83
            ],
            [
                -20,
                8
            ],
            [
                -75,
                303
            ]
        ],
        [
            [
                47185,
                56681
            ],
            [
                -14,
                -79
            ],
            [
                41,
                -45
            ],
            [
                52,
                -25
            ],
            [
                49,
                14
            ],
            [
                38,
                -23
            ],
            [
                12,
                -45
            ],
            [
                80,
                -49
            ],
            [
                34,
                -50
            ],
            [
                49,
                -24
            ],
            [
                4,
                -107
            ],
            [
                84,
                16
            ],
            [
                2,
                -94
            ],
            [
                41,
                -3
            ],
            [
                25,
                45
            ],
            [
                57,
                22
            ],
            [
                -5,
                51
            ],
            [
                62,
                56
            ],
            [
                63,
                -66
            ],
            [
                68,
                -21
            ],
            [
                23,
                -30
            ],
            [
                67,
                36
            ],
            [
                96,
                -16
            ],
            [
                54,
                55
            ],
            [
                56,
                79
            ],
            [
                38,
                -13
            ],
            [
                64,
                27
            ],
            [
                74,
                -18
            ],
            [
                17,
                -34
            ],
            [
                93,
                -33
            ],
            [
                3,
                -40
            ],
            [
                62,
                -44
            ],
            [
                44,
                -1
            ],
            [
                105,
                -55
            ],
            [
                99,
                -108
            ],
            [
                37,
                -63
            ],
            [
                9,
                -51
            ],
            [
                101,
                -7
            ],
            [
                19,
                -90
            ],
            [
                34,
                0
            ],
            [
                93,
                -37
            ],
            [
                42,
                2
            ],
            [
                70,
                61
            ],
            [
                62,
                -22
            ],
            [
                56,
                29
            ],
            [
                53,
                49
            ],
            [
                47,
                -25
            ],
            [
                72,
                20
            ],
            [
                136,
                76
            ],
            [
                61,
                -22
            ],
            [
                55,
                -46
            ],
            [
                88,
                -5
            ],
            [
                31,
                -94
            ],
            [
                65,
                -65
            ],
            [
                86,
                65
            ],
            [
                -4,
                57
            ],
            [
                53,
                18
            ],
            [
                42,
                47
            ],
            [
                41,
                -36
            ],
            [
                45,
                -8
            ],
            [
                11,
                -67
            ],
            [
                70,
                -13
            ],
            [
                125,
                17
            ],
            [
                131,
                -11
            ],
            [
                24,
                38
            ],
            [
                54,
                -2
            ],
            [
                86,
                -29
            ],
            [
                37,
                33
            ],
            [
                68,
                -26
            ],
            [
                119,
                -17
            ]
        ],
        [
            [
                50941,
                55835
            ],
            [
                55,
                -92
            ],
            [
                40,
                -28
            ],
            [
                13,
                -96
            ],
            [
                23,
                -43
            ],
            [
                85,
                -32
            ],
            [
                75,
                -5
            ],
            [
                -24,
                -145
            ],
            [
                -29,
                -74
            ],
            [
                -63,
                -81
            ],
            [
                23,
                -96
            ],
            [
                -54,
                -64
            ],
            [
                -41,
                -3
            ],
            [
                -3,
                -46
            ],
            [
                31,
                -57
            ],
            [
                5,
                -83
            ],
            [
                43,
                -35
            ],
            [
                54,
                -156
            ],
            [
                -62,
                -71
            ],
            [
                97,
                -64
            ],
            [
                -51,
                -43
            ],
            [
                9,
                -39
            ],
            [
                57,
                -14
            ],
            [
                -53,
                -79
            ],
            [
                -3,
                -58
            ],
            [
                -28,
                -137
            ],
            [
                -51,
                -88
            ],
            [
                -14,
                -103
            ],
            [
                -30,
                -91
            ],
            [
                42,
                -34
            ],
            [
                -49,
                -43
            ],
            [
                17,
                -111
            ],
            [
                -18,
                -85
            ],
            [
                -28,
                -27
            ],
            [
                -44,
                -114
            ],
            [
                6,
                -51
            ],
            [
                -39,
                -26
            ],
            [
                -13,
                -52
            ],
            [
                15,
                -91
            ],
            [
                -110,
                -54
            ],
            [
                -19,
                -68
            ],
            [
                -46,
                0
            ],
            [
                -73,
                -91
            ],
            [
                -9,
                -43
            ],
            [
                109,
                -38
            ],
            [
                -27,
                -141
            ],
            [
                42,
                -23
            ],
            [
                -13,
                -34
            ],
            [
                -63,
                -21
            ],
            [
                0,
                -46
            ],
            [
                -62,
                12
            ],
            [
                -11,
                -71
            ],
            [
                -29,
                -55
            ],
            [
                -87,
                1
            ],
            [
                -31,
                -50
            ],
            [
                -42,
                -21
            ],
            [
                -26,
                -53
            ],
            [
                -11,
                -80
            ],
            [
                -187,
                -33
            ],
            [
                -15,
                -69
            ],
            [
                -28,
                8
            ],
            [
                -44,
                -96
            ],
            [
                -72,
                -66
            ],
            [
                -37,
                -57
            ],
            [
                13,
                -80
            ],
            [
                43,
                -84
            ],
            [
                -8,
                -56
            ],
            [
                10,
                -83
            ],
            [
                142,
                -25
            ],
            [
                71,
                36
            ],
            [
                20,
                -65
            ],
            [
                -15,
                -73
            ],
            [
                45,
                -85
            ],
            [
                -78,
                -92
            ],
            [
                -55,
                -97
            ],
            [
                -73,
                -25
            ],
            [
                -63,
                12
            ],
            [
                -16,
                -26
            ],
            [
                18,
                -139
            ],
            [
                -17,
                -110
            ],
            [
                -59,
                -70
            ],
            [
                108,
                -90
            ],
            [
                37,
                -75
            ],
            [
                -68,
                -76
            ],
            [
                46,
                -33
            ],
            [
                0,
                -63
            ],
            [
                54,
                -93
            ],
            [
                -58,
                -190
            ],
            [
                -62,
                13
            ],
            [
                -51,
                -22
            ],
            [
                -44,
                -147
            ],
            [
                -7,
                -79
            ],
            [
                -31,
                -29
            ],
            [
                -97,
                -47
            ],
            [
                -22,
                -51
            ],
            [
                -76,
                9
            ],
            [
                -43,
                -15
            ],
            [
                13,
                -43
            ],
            [
                -22,
                -53
            ],
            [
                1,
                -66
            ],
            [
                51,
                -7
            ],
            [
                -5,
                -74
            ],
            [
                29,
                -16
            ],
            [
                15,
                -89
            ],
            [
                76,
                -29
            ],
            [
                -10,
                -48
            ],
            [
                60,
                -99
            ],
            [
                9,
                -83
            ],
            [
                -27,
                -104
            ],
            [
                -55,
                -63
            ],
            [
                -22,
                -121
            ],
            [
                26,
                -47
            ],
            [
                -6,
                -54
            ],
            [
                18,
                -77
            ],
            [
                50,
                -67
            ],
            [
                -36,
                -27
            ],
            [
                -20,
                -50
            ],
            [
                -65,
                -49
            ],
            [
                3,
                -41
            ],
            [
                -29,
                -39
            ],
            [
                -33,
                7
            ],
            [
                -59,
                -63
            ],
            [
                -40,
                17
            ],
            [
                -12,
                -82
            ]
        ],
        [
            [
                49617,
                48572
            ],
            [
                -19,
                -35
            ],
            [
                -61,
                -19
            ],
            [
                -78,
                33
            ],
            [
                -108,
                -48
            ],
            [
                -82,
                -86
            ],
            [
                -43,
                14
            ],
            [
                5,
                68
            ],
            [
                -34,
                56
            ],
            [
                -90,
                11
            ],
            [
                -36,
                -32
            ],
            [
                -91,
                18
            ],
            [
                -59,
                40
            ],
            [
                -57,
                19
            ],
            [
                -70,
                54
            ],
            [
                -38,
                64
            ],
            [
                7,
                38
            ],
            [
                -135,
                -14
            ],
            [
                -80,
                -102
            ],
            [
                -24,
                -104
            ],
            [
                -8,
                -130
            ],
            [
                -38,
                -1
            ],
            [
                -78,
                -40
            ],
            [
                -74,
                -60
            ],
            [
                -29,
                -1
            ],
            [
                -32,
                62
            ],
            [
                -74,
                -10
            ],
            [
                -60,
                -38
            ],
            [
                20,
                -62
            ],
            [
                -24,
                -101
            ],
            [
                156,
                -76
            ],
            [
                28,
                31
            ],
            [
                50,
                -1
            ],
            [
                -8,
                -188
            ],
            [
                16,
                -61
            ],
            [
                -31,
                -104
            ],
            [
                73,
                -70
            ],
            [
                -42,
                -98
            ],
            [
                -115,
                -43
            ],
            [
                -40,
                -56
            ],
            [
                40,
                -68
            ],
            [
                69,
                -61
            ],
            [
                16,
                -43
            ],
            [
                44,
                -42
            ],
            [
                7,
                -59
            ],
            [
                -86,
                -44
            ],
            [
                -71,
                -68
            ],
            [
                -2,
                -41
            ],
            [
                -136,
                -99
            ],
            [
                -23,
                -30
            ],
            [
                47,
                -59
            ],
            [
                -3,
                -50
            ],
            [
                -120,
                -78
            ],
            [
                -64,
                -32
            ],
            [
                -49,
                18
            ],
            [
                -67,
                -42
            ],
            [
                -94,
                39
            ],
            [
                -40,
                46
            ],
            [
                -41,
                -154
            ],
            [
                4,
                -34
            ],
            [
                -44,
                -104
            ],
            [
                9,
                -65
            ],
            [
                -44,
                -89
            ],
            [
                -60,
                -21
            ],
            [
                -53,
                -88
            ],
            [
                4,
                -96
            ],
            [
                -51,
                -31
            ],
            [
                -49,
                -61
            ],
            [
                -51,
                -26
            ],
            [
                -54,
                8
            ],
            [
                -43,
                -29
            ],
            [
                -78,
                -3
            ],
            [
                -40,
                13
            ],
            [
                -13,
                -116
            ],
            [
                -61,
                -75
            ],
            [
                -37,
                2
            ],
            [
                -93,
                -51
            ],
            [
                -5,
                -129
            ],
            [
                26,
                -130
            ],
            [
                69,
                -55
            ],
            [
                19,
                -41
            ],
            [
                -85,
                -37
            ],
            [
                -104,
                -90
            ],
            [
                -57,
                20
            ],
            [
                -65,
                75
            ],
            [
                13,
                104
            ],
            [
                -17,
                65
            ],
            [
                30,
                52
            ],
            [
                -9,
                45
            ],
            [
                -50,
                57
            ],
            [
                -54,
                13
            ],
            [
                -126,
                70
            ],
            [
                -92,
                -27
            ],
            [
                -85,
                22
            ],
            [
                -131,
                -20
            ],
            [
                -64,
                -45
            ],
            [
                -37,
                33
            ]
        ],
        [
            [
                46063,
                45749
            ],
            [
                -14,
                62
            ],
            [
                -44,
                43
            ],
            [
                -4,
                37
            ]
        ],
        [
            [
                46001,
                45891
            ],
            [
                78,
                19
            ],
            [
                141,
                78
            ],
            [
                34,
                55
            ],
            [
                -1,
                35
            ],
            [
                -108,
                120
            ],
            [
                -58,
                17
            ],
            [
                -38,
                61
            ],
            [
                -49,
                4
            ],
            [
                -113,
                -18
            ],
            [
                -36,
                20
            ],
            [
                -66,
                2
            ],
            [
                -20,
                101
            ],
            [
                -87,
                105
            ],
            [
                -33,
                103
            ],
            [
                -50,
                7
            ],
            [
                -21,
                -61
            ],
            [
                15,
                -77
            ],
            [
                -6,
                -65
            ],
            [
                -45,
                -16
            ],
            [
                -57,
                -49
            ],
            [
                -53,
                42
            ],
            [
                -45,
                -22
            ],
            [
                -78,
                30
            ],
            [
                -72,
                11
            ]
        ],
        [
            [
                45233,
                46393
            ],
            [
                18,
                45
            ],
            [
                8,
                146
            ],
            [
                -95,
                -44
            ],
            [
                -99,
                69
            ],
            [
                -80,
                -48
            ],
            [
                -80,
                85
            ],
            [
                -61,
                84
            ],
            [
                -68,
                46
            ],
            [
                -112,
                125
            ],
            [
                -18,
                45
            ],
            [
                -66,
                56
            ],
            [
                -28,
                -9
            ],
            [
                -27,
                -56
            ],
            [
                -44,
                -14
            ],
            [
                -7,
                55
            ],
            [
                25,
                57
            ],
            [
                34,
                30
            ],
            [
                -35,
                53
            ],
            [
                -56,
                32
            ],
            [
                -71,
                122
            ],
            [
                -60,
                55
            ],
            [
                -45,
                4
            ],
            [
                15,
                59
            ],
            [
                41,
                -4
            ],
            [
                24,
                64
            ],
            [
                76,
                120
            ],
            [
                40,
                36
            ],
            [
                112,
                53
            ],
            [
                -26,
                191
            ],
            [
                44,
                154
            ],
            [
                52,
                8
            ],
            [
                87,
                -8
            ],
            [
                96,
                -66
            ],
            [
                117,
                89
            ],
            [
                28,
                34
            ],
            [
                -53,
                73
            ],
            [
                35,
                105
            ],
            [
                35,
                55
            ],
            [
                -3,
                87
            ],
            [
                12,
                21
            ],
            [
                -48,
                70
            ],
            [
                -23,
                125
            ],
            [
                60,
                78
            ],
            [
                1,
                119
            ],
            [
                -14,
                87
            ],
            [
                -33,
                47
            ],
            [
                -45,
                10
            ],
            [
                -81,
                105
            ],
            [
                -46,
                73
            ],
            [
                -15,
                71
            ],
            [
                28,
                35
            ],
            [
                21,
                72
            ],
            [
                -26,
                60
            ],
            [
                -48,
                33
            ],
            [
                -70,
                95
            ],
            [
                -37,
                21
            ],
            [
                -109,
                104
            ],
            [
                -91,
                109
            ],
            [
                -61,
                93
            ],
            [
                -53,
                35
            ],
            [
                -32,
                49
            ],
            [
                -38,
                23
            ],
            [
                -106,
                27
            ],
            [
                -37,
                20
            ],
            [
                -57,
                94
            ],
            [
                -85,
                4
            ],
            [
                -29,
                16
            ],
            [
                -43,
                104
            ],
            [
                -29,
                21
            ],
            [
                -133,
                -59
            ],
            [
                -82,
                -5
            ],
            [
                -36,
                -31
            ]
        ],
        [
            [
                43601,
                50107
            ],
            [
                -69,
                61
            ],
            [
                -7,
                29
            ],
            [
                -179,
                50
            ],
            [
                -8,
                127
            ],
            [
                -39,
                239
            ],
            [
                -28,
                18
            ],
            [
                17,
                86
            ],
            [
                -8,
                28
            ],
            [
                37,
                69
            ],
            [
                -17,
                67
            ],
            [
                36,
                2
            ],
            [
                80,
                88
            ],
            [
                9,
                168
            ],
            [
                42,
                77
            ],
            [
                147,
                -42
            ],
            [
                31,
                -28
            ],
            [
                2,
                -83
            ],
            [
                -24,
                -27
            ],
            [
                39,
                -49
            ],
            [
                53,
                7
            ],
            [
                21,
                29
            ],
            [
                85,
                27
            ],
            [
                46,
                28
            ],
            [
                43,
                -4
            ],
            [
                -30,
                82
            ],
            [
                -12,
                122
            ],
            [
                -45,
                70
            ],
            [
                -30,
                19
            ],
            [
                16,
                110
            ],
            [
                -9,
                39
            ],
            [
                30,
                72
            ],
            [
                -17,
                44
            ],
            [
                -55,
                71
            ],
            [
                1,
                85
            ],
            [
                96,
                18
            ],
            [
                95,
                -24
            ],
            [
                25,
                66
            ],
            [
                43,
                60
            ],
            [
                64,
                21
            ],
            [
                41,
                87
            ],
            [
                124,
                -3
            ],
            [
                19,
                13
            ],
            [
                -8,
                76
            ],
            [
                20,
                43
            ],
            [
                40,
                12
            ],
            [
                5,
                65
            ],
            [
                26,
                29
            ],
            [
                -33,
                34
            ],
            [
                -58,
                10
            ],
            [
                17,
                54
            ],
            [
                -50,
                55
            ],
            [
                -11,
                46
            ],
            [
                -75,
                41
            ],
            [
                25,
                61
            ],
            [
                68,
                89
            ],
            [
                -10,
                131
            ],
            [
                -48,
                37
            ],
            [
                4,
                51
            ],
            [
                -50,
                76
            ],
            [
                6,
                101
            ],
            [
                -4,
                110
            ]
        ],
        [
            [
                44130,
                53147
            ],
            [
                -24,
                42
            ],
            [
                57,
                36
            ],
            [
                -8,
                76
            ]
        ],
        [
            [
                44155,
                53301
            ],
            [
                36,
                -48
            ],
            [
                64,
                11
            ],
            [
                48,
                -31
            ],
            [
                69,
                23
            ],
            [
                89,
                -118
            ],
            [
                51,
                -26
            ],
            [
                79,
                -13
            ],
            [
                25,
                35
            ],
            [
                61,
                9
            ],
            [
                101,
                -51
            ],
            [
                56,
                -91
            ],
            [
                103,
                -49
            ],
            [
                95,
                -15
            ],
            [
                58,
                54
            ],
            [
                75,
                1
            ],
            [
                56,
                -33
            ],
            [
                85,
                -10
            ],
            [
                30,
                22
            ],
            [
                38,
                74
            ],
            [
                39,
                16
            ],
            [
                -10,
                51
            ],
            [
                32,
                19
            ],
            [
                24,
                81
            ],
            [
                101,
                163
            ],
            [
                63,
                56
            ],
            [
                -16,
                109
            ],
            [
                -113,
                3
            ],
            [
                -34,
                72
            ],
            [
                -5,
                53
            ],
            [
                -87,
                72
            ],
            [
                17,
                86
            ],
            [
                -15,
                26
            ],
            [
                18,
                86
            ],
            [
                -10,
                41
            ],
            [
                -49,
                43
            ],
            [
                -8,
                73
            ],
            [
                45,
                87
            ],
            [
                27,
                123
            ],
            [
                -25,
                47
            ],
            [
                42,
                46
            ],
            [
                84,
                133
            ],
            [
                63,
                59
            ],
            [
                -19,
                61
            ],
            [
                -64,
                24
            ],
            [
                46,
                110
            ],
            [
                4,
                59
            ],
            [
                71,
                94
            ],
            [
                68,
                30
            ],
            [
                47,
                93
            ],
            [
                -7,
                49
            ],
            [
                -31,
                11
            ],
            [
                -4,
                90
            ],
            [
                63,
                126
            ],
            [
                88,
                -50
            ],
            [
                80,
                23
            ],
            [
                -4,
                78
            ],
            [
                45,
                60
            ],
            [
                50,
                39
            ],
            [
                31,
                49
            ],
            [
                14,
                137
            ],
            [
                87,
                -9
            ],
            [
                89,
                5
            ],
            [
                49,
                -18
            ],
            [
                83,
                25
            ],
            [
                -2,
                59
            ],
            [
                19,
                42
            ],
            [
                -18,
                60
            ],
            [
                30,
                31
            ],
            [
                159,
                14
            ],
            [
                56,
                28
            ],
            [
                34,
                85
            ],
            [
                35,
                36
            ],
            [
                60,
                -5
            ],
            [
                1,
                66
            ],
            [
                74,
                -5
            ],
            [
                37,
                36
            ],
            [
                33,
                64
            ],
            [
                -34,
                46
            ],
            [
                23,
                34
            ],
            [
                -15,
                146
            ],
            [
                114,
                111
            ],
            [
                -11,
                88
            ],
            [
                48,
                27
            ],
            [
                49,
                51
            ],
            [
                80,
                11
            ],
            [
                70,
                -20
            ]
        ],
        [
            [
                40267,
                57723
            ],
            [
                -39,
                24
            ],
            [
                15,
                46
            ],
            [
                -1,
                82
            ],
            [
                -32,
                65
            ],
            [
                102,
                31
            ],
            [
                48,
                -27
            ],
            [
                -6,
                -46
            ],
            [
                -47,
                -49
            ],
            [
                -7,
                -62
            ],
            [
                17,
                -42
            ],
            [
                -50,
                -22
            ]
        ],
        [
            [
                40620,
                58291
            ],
            [
                10,
                -126
            ],
            [
                -21,
                -67
            ],
            [
                -97,
                -10
            ],
            [
                -68,
                -44
            ],
            [
                -19,
                82
            ],
            [
                -79,
                -45
            ],
            [
                -99,
                -23
            ],
            [
                -11,
                38
            ],
            [
                -77,
                -31
            ],
            [
                -53,
                8
            ],
            [
                8,
                -61
            ],
            [
                -116,
                -28
            ],
            [
                -23,
                -66
            ],
            [
                -105,
                -40
            ],
            [
                -37,
                -77
            ],
            [
                31,
                -58
            ],
            [
                -3,
                -128
            ],
            [
                30,
                -68
            ],
            [
                13,
                -72
            ]
        ],
        [
            [
                39904,
                57475
            ],
            [
                -73,
                -7
            ],
            [
                -39,
                17
            ],
            [
                -75,
                -10
            ],
            [
                -58,
                12
            ],
            [
                -96,
                43
            ],
            [
                -113,
                24
            ],
            [
                -49,
                -18
            ],
            [
                -24,
                73
            ],
            [
                -104,
                43
            ],
            [
                -14,
                -38
            ],
            [
                -76,
                -105
            ],
            [
                1,
                -23
            ],
            [
                -96,
                -104
            ],
            [
                -99,
                -34
            ],
            [
                -3,
                -52
            ],
            [
                -88,
                -26
            ],
            [
                -56,
                32
            ],
            [
                -29,
                -21
            ],
            [
                -81,
                -4
            ],
            [
                51,
                -60
            ],
            [
                -49,
                -58
            ],
            [
                2,
                -26
            ],
            [
                -112,
                3
            ],
            [
                -50,
                -96
            ],
            [
                -98,
                -38
            ],
            [
                -42,
                -49
            ],
            [
                -81,
                -229
            ],
            [
                49,
                -71
            ],
            [
                90,
                -6
            ],
            [
                76,
                49
            ],
            [
                54,
                8
            ],
            [
                11,
                86
            ],
            [
                55,
                23
            ],
            [
                74,
                -56
            ],
            [
                26,
                -45
            ],
            [
                -9,
                -36
            ],
            [
                -54,
                1
            ],
            [
                -41,
                -71
            ],
            [
                -83,
                19
            ],
            [
                17,
                -62
            ],
            [
                -55,
                -105
            ],
            [
                38,
                3
            ],
            [
                51,
                -32
            ],
            [
                32,
                20
            ],
            [
                -15,
                68
            ],
            [
                28,
                55
            ],
            [
                73,
                -41
            ],
            [
                31,
                -38
            ],
            [
                -23,
                -61
            ],
            [
                47,
                -60
            ],
            [
                1,
                -94
            ],
            [
                -15,
                -38
            ],
            [
                -113,
                -51
            ],
            [
                -84,
                67
            ],
            [
                -41,
                -68
            ],
            [
                -4,
                -67
            ],
            [
                -53,
                23
            ],
            [
                -95,
                -59
            ],
            [
                -43,
                36
            ],
            [
                -49,
                3
            ],
            [
                10,
                64
            ],
            [
                -11,
                140
            ],
            [
                -56,
                -18
            ],
            [
                11,
                -44
            ],
            [
                -91,
                -102
            ],
            [
                12,
                -49
            ],
            [
                -96,
                -19
            ],
            [
                -34,
                44
            ],
            [
                -12,
                66
            ],
            [
                -66,
                13
            ],
            [
                -74,
                -20
            ],
            [
                -96,
                104
            ],
            [
                54,
                40
            ],
            [
                54,
                3
            ],
            [
                46,
                64
            ],
            [
                7,
                39
            ],
            [
                -62,
                34
            ],
            [
                -87,
                -27
            ],
            [
                -49,
                -51
            ],
            [
                -27,
                0
            ],
            [
                -45,
                52
            ],
            [
                6,
                60
            ],
            [
                -36,
                156
            ],
            [
                -4,
                138
            ],
            [
                -65,
                41
            ],
            [
                -99,
                4
            ],
            [
                -131,
                21
            ],
            [
                -74,
                102
            ],
            [
                -63,
                131
            ],
            [
                -92,
                40
            ],
            [
                -42,
                48
            ],
            [
                -42,
                5
            ],
            [
                -34,
                -79
            ],
            [
                -55,
                -1
            ],
            [
                -68,
                33
            ],
            [
                -101,
                -103
            ],
            [
                -43,
                27
            ],
            [
                -44,
                -4
            ],
            [
                -42,
                37
            ],
            [
                -87,
                -71
            ],
            [
                -125,
                35
            ],
            [
                -66,
                -4
            ],
            [
                -30,
                -29
            ],
            [
                -64,
                41
            ],
            [
                25,
                70
            ],
            [
                -27,
                35
            ],
            [
                -59,
                -3
            ],
            [
                -1,
                70
            ],
            [
                -180,
                104
            ],
            [
                -8,
                51
            ],
            [
                -42,
                36
            ],
            [
                45,
                119
            ],
            [
                -46,
                55
            ],
            [
                29,
                29
            ]
        ],
        [
            [
                36052,
                57651
            ],
            [
                59,
                -7
            ],
            [
                80,
                25
            ],
            [
                32,
                24
            ],
            [
                87,
                -30
            ],
            [
                32,
                24
            ],
            [
                -6,
                69
            ],
            [
                39,
                76
            ],
            [
                2,
                36
            ],
            [
                -27,
                67
            ],
            [
                70,
                29
            ],
            [
                74,
                11
            ],
            [
                50,
                -13
            ],
            [
                66,
                15
            ],
            [
                29,
                73
            ],
            [
                70,
                38
            ],
            [
                128,
                -52
            ],
            [
                -5,
                -47
            ],
            [
                45,
                -6
            ],
            [
                39,
                38
            ],
            [
                7,
                109
            ],
            [
                -21,
                66
            ],
            [
                11,
                38
            ],
            [
                -45,
                22
            ],
            [
                23,
                55
            ],
            [
                -9,
                36
            ],
            [
                64,
                45
            ],
            [
                -4,
                47
            ]
        ],
        [
            [
                36942,
                58439
            ],
            [
                30,
                16
            ],
            [
                96,
                -20
            ],
            [
                57,
                20
            ],
            [
                130,
                -2
            ],
            [
                41,
                -25
            ],
            [
                92,
                60
            ],
            [
                49,
                -58
            ],
            [
                96,
                4
            ],
            [
                158,
                35
            ],
            [
                37,
                -20
            ],
            [
                110,
                36
            ],
            [
                181,
                48
            ],
            [
                88,
                76
            ],
            [
                13,
                -30
            ],
            [
                80,
                25
            ],
            [
                34,
                -13
            ],
            [
                53,
                53
            ],
            [
                35,
                -26
            ],
            [
                47,
                17
            ],
            [
                55,
                -14
            ],
            [
                52,
                97
            ],
            [
                53,
                19
            ],
            [
                180,
                13
            ],
            [
                127,
                65
            ],
            [
                72,
                -11
            ],
            [
                -5,
                -57
            ],
            [
                32,
                -35
            ],
            [
                -68,
                -20
            ],
            [
                -38,
                -75
            ],
            [
                28,
                -42
            ],
            [
                -3,
                -47
            ],
            [
                70,
                16
            ],
            [
                23,
                101
            ],
            [
                68,
                -16
            ],
            [
                -3,
                43
            ],
            [
                60,
                22
            ],
            [
                -3,
                33
            ],
            [
                56,
                31
            ],
            [
                51,
                -15
            ],
            [
                66,
                56
            ],
            [
                61,
                33
            ],
            [
                55,
                -10
            ],
            [
                59,
                62
            ],
            [
                56,
                -22
            ],
            [
                86,
                4
            ],
            [
                13,
                -58
            ],
            [
                70,
                3
            ],
            [
                4,
                -69
            ],
            [
                102,
                -37
            ],
            [
                113,
                -3
            ],
            [
                22,
                -76
            ],
            [
                -33,
                -26
            ],
            [
                -69,
                11
            ],
            [
                -68,
                32
            ],
            [
                15,
                -91
            ],
            [
                60,
                -97
            ],
            [
                44,
                94
            ],
            [
                40,
                -44
            ],
            [
                83,
                25
            ],
            [
                88,
                -21
            ],
            [
                106,
                15
            ],
            [
                120,
                -48
            ],
            [
                72,
                -1
            ],
            [
                91,
                -24
            ],
            [
                31,
                -49
            ],
            [
                127,
                -112
            ],
            [
                30,
                1
            ]
        ],
        [
            [
                38038,
                56342
            ],
            [
                -16,
                -41
            ],
            [
                33,
                -85
            ],
            [
                32,
                22
            ],
            [
                2,
                69
            ],
            [
                -51,
                35
            ]
        ],
        [
            [
                41493,
                56210
            ],
            [
                235,
                0
            ],
            [
                33,
                8
            ],
            [
                57,
                -40
            ],
            [
                86,
                -14
            ],
            [
                118,
                -55
            ],
            [
                30,
                21
            ],
            [
                59,
                -18
            ],
            [
                35,
                -57
            ],
            [
                84,
                46
            ],
            [
                -11,
                -80
            ],
            [
                -28,
                -60
            ],
            [
                -47,
                -53
            ],
            [
                -49,
                -14
            ],
            [
                -2,
                -41
            ],
            [
                40,
                -22
            ],
            [
                44,
                -60
            ],
            [
                80,
                -19
            ],
            [
                24,
                66
            ],
            [
                47,
                -9
            ],
            [
                -3,
                -71
            ],
            [
                16,
                -69
            ],
            [
                -57,
                -28
            ],
            [
                -50,
                40
            ],
            [
                -22,
                -19
            ],
            [
                -54,
                26
            ],
            [
                -77,
                -17
            ],
            [
                -58,
                3
            ],
            [
                -46,
                49
            ],
            [
                -6,
                34
            ],
            [
                -122,
                18
            ],
            [
                -51,
                -48
            ],
            [
                -135,
                15
            ],
            [
                -45,
                122
            ],
            [
                -30,
                -4
            ],
            [
                -80,
                41
            ],
            [
                -10,
                37
            ],
            [
                -42,
                21
            ],
            [
                -27,
                44
            ],
            [
                2,
                118
            ],
            [
                62,
                89
            ]
        ],
        [
            [
                39904,
                57475
            ],
            [
                78,
                25
            ],
            [
                48,
                42
            ],
            [
                56,
                11
            ],
            [
                23,
                46
            ],
            [
                89,
                21
            ],
            [
                32,
                38
            ],
            [
                119,
                59
            ],
            [
                81,
                -98
            ],
            [
                42,
                27
            ],
            [
                60,
                -38
            ],
            [
                48,
                35
            ],
            [
                68,
                -38
            ],
            [
                -51,
                -52
            ],
            [
                1,
                -41
            ],
            [
                -55,
                -83
            ],
            [
                32,
                -42
            ],
            [
                97,
                -46
            ],
            [
                -65,
                -86
            ],
            [
                46,
                -20
            ],
            [
                -11,
                -71
            ],
            [
                4,
                -82
            ],
            [
                -72,
                6
            ],
            [
                3,
                -51
            ],
            [
                66,
                -31
            ],
            [
                55,
                3
            ],
            [
                96,
                -22
            ],
            [
                78,
                7
            ],
            [
                57,
                -94
            ],
            [
                -16,
                -18
            ],
            [
                90,
                -78
            ],
            [
                42,
                2
            ],
            [
                40,
                -52
            ],
            [
                -64,
                -44
            ],
            [
                -30,
                -61
            ],
            [
                -119,
                -33
            ],
            [
                -80,
                -10
            ],
            [
                -51,
                26
            ],
            [
                -49,
                -7
            ],
            [
                -14,
                32
            ],
            [
                -52,
                25
            ],
            [
                9,
                49
            ],
            [
                -90,
                44
            ],
            [
                -119,
                19
            ],
            [
                -64,
                -139
            ],
            [
                -78,
                -39
            ],
            [
                -14,
                -97
            ],
            [
                153,
                -169
            ],
            [
                45,
                53
            ],
            [
                51,
                93
            ],
            [
                47,
                -45
            ],
            [
                63,
                15
            ],
            [
                65,
                111
            ],
            [
                56,
                -41
            ],
            [
                -22,
                -71
            ],
            [
                -75,
                -92
            ],
            [
                -19,
                -81
            ],
            [
                -30,
                -36
            ],
            [
                52,
                -33
            ],
            [
                32,
                -43
            ],
            [
                -23,
                -69
            ],
            [
                57,
                -23
            ],
            [
                40,
                29
            ],
            [
                54,
                0
            ],
            [
                45,
                -26
            ],
            [
                63,
                -80
            ],
            [
                62,
                10
            ],
            [
                29,
                -67
            ],
            [
                48,
                -15
            ],
            [
                7,
                -38
            ],
            [
                153,
                -3
            ],
            [
                76,
                -54
            ],
            [
                20,
                -53
            ],
            [
                -16,
                -43
            ],
            [
                13,
                -47
            ],
            [
                76,
                -60
            ],
            [
                27,
                -2
            ]
        ],
        [
            [
                41419,
                55639
            ],
            [
                -69,
                -51
            ],
            [
                -57,
                7
            ],
            [
                -53,
                -17
            ],
            [
                -25,
                47
            ],
            [
                -100,
                24
            ],
            [
                -26,
                -21
            ],
            [
                -67,
                33
            ],
            [
                -36,
                -45
            ],
            [
                -62,
                30
            ],
            [
                -77,
                -5
            ],
            [
                -32,
                -28
            ],
            [
                56,
                -107
            ],
            [
                -21,
                -50
            ],
            [
                -49,
                -25
            ],
            [
                11,
                -30
            ],
            [
                -63,
                -75
            ],
            [
                -78,
                -44
            ],
            [
                25,
                -33
            ],
            [
                48,
                -16
            ],
            [
                49,
                35
            ],
            [
                47,
                -39
            ],
            [
                -20,
                -106
            ],
            [
                -38,
                -28
            ],
            [
                11,
                -47
            ],
            [
                43,
                -9
            ],
            [
                74,
                -116
            ],
            [
                -47,
                -75
            ],
            [
                -2,
                -77
            ],
            [
                30,
                -114
            ],
            [
                -31,
                -1
            ],
            [
                -49,
                89
            ],
            [
                12,
                69
            ],
            [
                -63,
                3
            ],
            [
                16,
                -43
            ],
            [
                -24,
                -73
            ],
            [
                53,
                0
            ],
            [
                58,
                -84
            ],
            [
                -1,
                -25
            ],
            [
                -123,
                -15
            ],
            [
                -4,
                -50
            ],
            [
                28,
                -37
            ],
            [
                -17,
                -59
            ],
            [
                46,
                -147
            ],
            [
                -22,
                -37
            ],
            [
                25,
                -64
            ],
            [
                -38,
                -50
            ],
            [
                -9,
                -76
            ],
            [
                -62,
                -40
            ],
            [
                37,
                -47
            ],
            [
                1,
                -35
            ],
            [
                68,
                -72
            ],
            [
                9,
                -89
            ],
            [
                74,
                -32
            ],
            [
                67,
                -152
            ],
            [
                29,
                13
            ],
            [
                53,
                -22
            ],
            [
                29,
                15
            ],
            [
                89,
                -12
            ],
            [
                53,
                19
            ],
            [
                23,
                -31
            ],
            [
                46,
                -156
            ],
            [
                5,
                -60
            ],
            [
                60,
                -21
            ],
            [
                20,
                -28
            ],
            [
                69,
                49
            ],
            [
                4,
                23
            ],
            [
                90,
                45
            ],
            [
                30,
                -17
            ],
            [
                40,
                38
            ],
            [
                3,
                96
            ],
            [
                -18,
                29
            ],
            [
                36,
                51
            ],
            [
                -4,
                54
            ],
            [
                45,
                55
            ],
            [
                126,
                -3
            ],
            [
                59,
                -63
            ],
            [
                -5,
                -72
            ],
            [
                -71,
                -98
            ],
            [
                -10,
                -38
            ],
            [
                -52,
                -63
            ],
            [
                31,
                -89
            ],
            [
                51,
                23
            ],
            [
                58,
                1
            ],
            [
                50,
                -51
            ],
            [
                37,
                -13
            ],
            [
                38,
                27
            ],
            [
                89,
                5
            ],
            [
                127,
                -38
            ],
            [
                39,
                65
            ],
            [
                44,
                140
            ],
            [
                45,
                13
            ],
            [
                48,
                51
            ],
            [
                -21,
                52
            ],
            [
                21,
                117
            ],
            [
                66,
                -13
            ],
            [
                86,
                16
            ],
            [
                55,
                82
            ],
            [
                137,
                19
            ],
            [
                186,
                10
            ],
            [
                100,
                -50
            ],
            [
                1,
                -86
            ],
            [
                33,
                -74
            ],
            [
                29,
                -5
            ],
            [
                57,
                56
            ],
            [
                37,
                -15
            ],
            [
                41,
                24
            ],
            [
                195,
                -6
            ],
            [
                26,
                -22
            ],
            [
                0,
                -60
            ],
            [
                -94,
                -53
            ],
            [
                19,
                -73
            ],
            [
                70,
                -77
            ],
            [
                33,
                -18
            ],
            [
                25,
                -69
            ],
            [
                -44,
                -28
            ],
            [
                38,
                -139
            ],
            [
                91,
                -26
            ],
            [
                38,
                14
            ],
            [
                75,
                -30
            ],
            [
                17,
                25
            ],
            [
                125,
                -126
            ],
            [
                56,
                4
            ],
            [
                108,
                52
            ],
            [
                40,
                42
            ],
            [
                79,
                25
            ],
            [
                57,
                52
            ]
        ],
        [
            [
                43601,
                50107
            ],
            [
                3,
                -77
            ],
            [
                -32,
                -48
            ],
            [
                -8,
                -64
            ],
            [
                47,
                -65
            ],
            [
                -22,
                -32
            ],
            [
                -42,
                10
            ],
            [
                -13,
                36
            ],
            [
                -110,
                77
            ],
            [
                -39,
                57
            ],
            [
                -32,
                -58
            ],
            [
                -65,
                -27
            ],
            [
                -21,
                -43
            ],
            [
                -154,
                49
            ],
            [
                -85,
                -109
            ],
            [
                -57,
                -8
            ],
            [
                -24,
                22
            ],
            [
                -81,
                -55
            ],
            [
                -86,
                111
            ],
            [
                -75,
                -45
            ],
            [
                -52,
                -47
            ],
            [
                -50,
                -5
            ],
            [
                -59,
                63
            ],
            [
                -73,
                3
            ],
            [
                0,
                44
            ],
            [
                -30,
                88
            ],
            [
                -77,
                19
            ],
            [
                -22,
                103
            ],
            [
                -53,
                59
            ],
            [
                -14,
                -42
            ],
            [
                -107,
                -38
            ],
            [
                -40,
                40
            ],
            [
                30,
                36
            ],
            [
                19,
                67
            ],
            [
                -67,
                34
            ],
            [
                26,
                46
            ],
            [
                10,
                87
            ],
            [
                -75,
                -19
            ],
            [
                -102,
                79
            ],
            [
                -39,
                -13
            ],
            [
                -109,
                55
            ],
            [
                -20,
                88
            ],
            [
                -36,
                -15
            ],
            [
                -83,
                9
            ],
            [
                -59,
                -75
            ],
            [
                -107,
                10
            ],
            [
                -28,
                26
            ],
            [
                -93,
                41
            ],
            [
                3,
                64
            ],
            [
                -20,
                25
            ],
            [
                17,
                54
            ],
            [
                -23,
                31
            ],
            [
                -85,
                23
            ],
            [
                -74,
                -53
            ],
            [
                -43,
                -77
            ],
            [
                -44,
                2
            ],
            [
                -107,
                -33
            ],
            [
                -33,
                18
            ],
            [
                -119,
                -58
            ],
            [
                -126,
                53
            ],
            [
                -153,
                28
            ],
            [
                -64,
                32
            ],
            [
                -50,
                1
            ],
            [
                -52,
                -23
            ],
            [
                -31,
                -69
            ],
            [
                -58,
                -36
            ],
            [
                -45,
                20
            ],
            [
                -39,
                -64
            ],
            [
                -88,
                15
            ],
            [
                -37,
                -33
            ],
            [
                -89,
                27
            ],
            [
                -82,
                -21
            ],
            [
                -26,
                -159
            ],
            [
                -94,
                13
            ],
            [
                -44,
                -90
            ],
            [
                -58,
                -40
            ],
            [
                -51,
                -4
            ],
            [
                -42,
                -62
            ],
            [
                -64,
                10
            ]
        ],
        [
            [
                39574,
                50175
            ],
            [
                -80,
                -21
            ],
            [
                -38,
                6
            ],
            [
                -82,
                -44
            ],
            [
                -50,
                -57
            ],
            [
                -50,
                -98
            ],
            [
                -81,
                -86
            ],
            [
                -40,
                -4
            ],
            [
                -119,
                -109
            ],
            [
                -28,
                -69
            ],
            [
                -87,
                -130
            ],
            [
                -90,
                -51
            ],
            [
                -41,
                -1
            ],
            [
                -119,
                -41
            ],
            [
                -57,
                -30
            ],
            [
                -61,
                -89
            ],
            [
                -13,
                -49
            ],
            [
                -41,
                -10
            ],
            [
                1,
                -41
            ],
            [
                -42,
                -84
            ],
            [
                26,
                -17
            ],
            [
                -9,
                -89
            ],
            [
                -50,
                -98
            ],
            [
                8,
                -60
            ],
            [
                -48,
                -83
            ],
            [
                5,
                -45
            ],
            [
                -67,
                3
            ],
            [
                -51,
                -27
            ],
            [
                -69,
                34
            ],
            [
                -62,
                13
            ],
            [
                -29,
                -63
            ],
            [
                -43,
                -45
            ],
            [
                -3,
                -62
            ],
            [
                -47,
                -21
            ],
            [
                -65,
                -110
            ],
            [
                -16,
                -65
            ],
            [
                -73,
                -58
            ],
            [
                10,
                -134
            ],
            [
                -13,
                -47
            ],
            [
                32,
                -27
            ],
            [
                -146,
                -69
            ],
            [
                -74,
                -9
            ],
            [
                -53,
                12
            ],
            [
                -28,
                87
            ],
            [
                -44,
                -12
            ],
            [
                -4,
                -65
            ],
            [
                18,
                -48
            ],
            [
                -17,
                -115
            ],
            [
                -90,
                -49
            ],
            [
                4,
                -71
            ],
            [
                -17,
                -91
            ],
            [
                12,
                -22
            ],
            [
                0,
                -193
            ],
            [
                -44,
                -65
            ],
            [
                42,
                -50
            ],
            [
                -27,
                -36
            ],
            [
                -49,
                17
            ],
            [
                -67,
                -22
            ],
            [
                -100,
                11
            ],
            [
                -49,
                -47
            ],
            [
                -21,
                -66
            ],
            [
                0,
                -110
            ],
            [
                -47,
                -21
            ],
            [
                4,
                -92
            ],
            [
                -81,
                5
            ],
            [
                -51,
                -23
            ],
            [
                -61,
                86
            ],
            [
                -35,
                -39
            ],
            [
                29,
                -130
            ],
            [
                -68,
                -60
            ],
            [
                -6,
                -44
            ],
            [
                -34,
                -27
            ],
            [
                27,
                -87
            ],
            [
                -18,
                -59
            ],
            [
                -36,
                -2
            ]
        ],
        [
            [
                36761,
                46660
            ],
            [
                -103,
                -64
            ],
            [
                -100,
                -7
            ],
            [
                -24,
                29
            ],
            [
                -64,
                18
            ],
            [
                -19,
                112
            ],
            [
                17,
                31
            ],
            [
                -39,
                29
            ],
            [
                20,
                34
            ],
            [
                -10,
                58
            ],
            [
                -93,
                -42
            ],
            [
                -28,
                17
            ],
            [
                -50,
                -54
            ],
            [
                -117,
                53
            ],
            [
                -37,
                -75
            ],
            [
                28,
                -73
            ],
            [
                -88,
                -94
            ],
            [
                -59,
                -17
            ],
            [
                -131,
                -110
            ],
            [
                -39,
                -21
            ],
            [
                18,
                -54
            ],
            [
                -20,
                -72
            ],
            [
                -83,
                -10
            ],
            [
                -14,
                -44
            ],
            [
                -121,
                -44
            ],
            [
                -2,
                80
            ],
            [
                -28,
                54
            ],
            [
                -4,
                49
            ],
            [
                -63,
                -29
            ],
            [
                -56,
                2
            ],
            [
                -105,
                -91
            ],
            [
                -63,
                -85
            ],
            [
                -127,
                -72
            ],
            [
                -36,
                12
            ],
            [
                -46,
                -19
            ],
            [
                -26,
                36
            ],
            [
                -4,
                53
            ],
            [
                -156,
                10
            ],
            [
                -122,
                -9
            ],
            [
                -55,
                32
            ]
        ],
        [
            [
                34712,
                46283
            ],
            [
                -3,
                31
            ],
            [
                -83,
                148
            ],
            [
                10,
                145
            ],
            [
                -15,
                56
            ],
            [
                31,
                51
            ],
            [
                41,
                119
            ],
            [
                -57,
                -26
            ],
            [
                -39,
                3
            ],
            [
                -45,
                -28
            ],
            [
                -47,
                18
            ],
            [
                -47,
                -11
            ],
            [
                -27,
                -69
            ],
            [
                -76,
                -35
            ],
            [
                -8,
                -61
            ],
            [
                -163,
                -48
            ],
            [
                -46,
                9
            ],
            [
                -116,
                67
            ],
            [
                -52,
                1
            ],
            [
                -9,
                66
            ],
            [
                -34,
                53
            ],
            [
                -63,
                8
            ],
            [
                -28,
                28
            ],
            [
                8,
                48
            ],
            [
                -97,
                81
            ],
            [
                -122,
                7
            ],
            [
                -74,
                -49
            ],
            [
                -76,
                22
            ],
            [
                -1,
                50
            ],
            [
                31,
                45
            ],
            [
                -42,
                152
            ],
            [
                -52,
                -9
            ],
            [
                -82,
                -85
            ],
            [
                -29,
                24
            ],
            [
                -72,
                -29
            ],
            [
                -65,
                -135
            ],
            [
                -34,
                -35
            ],
            [
                -56,
                13
            ],
            [
                -175,
                81
            ],
            [
                -36,
                61
            ],
            [
                10,
                66
            ],
            [
                -98,
                4
            ],
            [
                -121,
                56
            ],
            [
                39,
                25
            ],
            [
                -5,
                62
            ],
            [
                25,
                8
            ],
            [
                0,
                79
            ],
            [
                -79,
                43
            ],
            [
                -69,
                21
            ],
            [
                28,
                53
            ],
            [
                -75,
                4
            ],
            [
                -29,
                77
            ],
            [
                -78,
                35
            ],
            [
                -33,
                56
            ],
            [
                -111,
                17
            ],
            [
                -41,
                -59
            ],
            [
                -107,
                -67
            ],
            [
                -76,
                -6
            ],
            [
                -60,
                -28
            ],
            [
                -56,
                -76
            ],
            [
                10,
                -57
            ],
            [
                -39,
                -25
            ],
            [
                -116,
                -3
            ],
            [
                -56,
                -66
            ],
            [
                4,
                -33
            ],
            [
                -65,
                0
            ],
            [
                -201,
                -95
            ],
            [
                -65,
                -67
            ],
            [
                -12,
                -54
            ],
            [
                24,
                -38
            ],
            [
                0,
                -50
            ],
            [
                -84,
                -74
            ],
            [
                -129,
                -23
            ],
            [
                -14,
                16
            ],
            [
                -86,
                -21
            ],
            [
                -45,
                -62
            ],
            [
                -40,
                17
            ],
            [
                -41,
                67
            ],
            [
                -101,
                -85
            ],
            [
                -70,
                10
            ],
            [
                -51,
                -26
            ],
            [
                -100,
                25
            ],
            [
                -75,
                81
            ]
        ],
        [
            [
                30574,
                46857
            ],
            [
                2,
                95
            ],
            [
                64,
                64
            ],
            [
                61,
                43
            ],
            [
                38,
                -2
            ],
            [
                36,
                36
            ],
            [
                -8,
                83
            ],
            [
                33,
                27
            ],
            [
                -110,
                118
            ],
            [
                -45,
                71
            ],
            [
                -30,
                136
            ],
            [
                39,
                89
            ],
            [
                34,
                29
            ],
            [
                32,
                81
            ],
            [
                42,
                23
            ],
            [
                -27,
                104
            ],
            [
                12,
                41
            ],
            [
                -120,
                58
            ],
            [
                18,
                99
            ],
            [
                48,
                66
            ],
            [
                40,
                84
            ],
            [
                19,
                82
            ],
            [
                -7,
                84
            ],
            [
                -31,
                113
            ],
            [
                6,
                31
            ],
            [
                -53,
                97
            ],
            [
                13,
                157
            ],
            [
                23,
                43
            ],
            [
                -18,
                132
            ],
            [
                55,
                51
            ],
            [
                4,
                54
            ],
            [
                -22,
                82
            ],
            [
                -76,
                27
            ],
            [
                -30,
                47
            ],
            [
                4,
                83
            ],
            [
                -33,
                95
            ],
            [
                -42,
                37
            ],
            [
                -40,
                75
            ],
            [
                -53,
                69
            ],
            [
                -32,
                11
            ],
            [
                -27,
                53
            ],
            [
                1,
                47
            ],
            [
                44,
                33
            ],
            [
                126,
                -43
            ],
            [
                94,
                6
            ],
            [
                67,
                30
            ],
            [
                2,
                33
            ],
            [
                50,
                45
            ],
            [
                56,
                115
            ],
            [
                40,
                57
            ],
            [
                -44,
                74
            ],
            [
                7,
                41
            ],
            [
                74,
                53
            ],
            [
                36,
                54
            ],
            [
                70,
                61
            ],
            [
                36,
                83
            ],
            [
                -10,
                20
            ],
            [
                85,
                96
            ],
            [
                33,
                51
            ],
            [
                53,
                -18
            ],
            [
                23,
                18
            ],
            [
                91,
                18
            ],
            [
                16,
                -50
            ],
            [
                81,
                28
            ],
            [
                10,
                42
            ],
            [
                80,
                62
            ],
            [
                51,
                -35
            ],
            [
                54,
                37
            ],
            [
                -29,
                53
            ],
            [
                56,
                44
            ],
            [
                53,
                -9
            ],
            [
                31,
                24
            ],
            [
                32,
                110
            ],
            [
                29,
                48
            ],
            [
                62,
                7
            ],
            [
                34,
                24
            ],
            [
                -37,
                50
            ],
            [
                8,
                53
            ],
            [
                52,
                29
            ],
            [
                51,
                -50
            ],
            [
                100,
                43
            ],
            [
                -31,
                98
            ],
            [
                46,
                11
            ],
            [
                41,
                59
            ],
            [
                -29,
                53
            ],
            [
                50,
                59
            ],
            [
                8,
                50
            ],
            [
                38,
                28
            ],
            [
                32,
                94
            ],
            [
                57,
                35
            ],
            [
                54,
                81
            ],
            [
                15,
                59
            ],
            [
                61,
                59
            ],
            [
                -53,
                90
            ],
            [
                -74,
                29
            ],
            [
                -50,
                96
            ],
            [
                -130,
                107
            ],
            [
                -141,
                54
            ],
            [
                -54,
                -10
            ],
            [
                -85,
                27
            ],
            [
                -126,
                0
            ],
            [
                -13,
                -53
            ],
            [
                -85,
                -34
            ],
            [
                -65,
                14
            ],
            [
                -40,
                52
            ],
            [
                -56,
                38
            ],
            [
                -15,
                119
            ],
            [
                -29,
                127
            ],
            [
                55,
                118
            ],
            [
                4,
                77
            ],
            [
                28,
                58
            ],
            [
                40,
                204
            ],
            [
                -145,
                31
            ],
            [
                26,
                47
            ],
            [
                42,
                129
            ],
            [
                -9,
                51
            ],
            [
                -69,
                60
            ],
            [
                -39,
                8
            ],
            [
                -28,
                -54
            ],
            [
                -47,
                -27
            ],
            [
                -51,
                4
            ],
            [
                -174,
                -33
            ],
            [
                -137,
                39
            ],
            [
                -45,
                115
            ],
            [
                -4,
                35
            ],
            [
                -115,
                31
            ],
            [
                3,
                -85
            ],
            [
                -31,
                -88
            ],
            [
                -74,
                -13
            ],
            [
                -56,
                22
            ],
            [
                -42,
                -30
            ],
            [
                -135,
                22
            ],
            [
                -25,
                -6
            ],
            [
                -37,
                90
            ],
            [
                -69,
                15
            ]
        ],
        [
            [
                30253,
                53171
            ],
            [
                -22,
                48
            ],
            [
                83,
                153
            ],
            [
                -33,
                45
            ],
            [
                -4,
                59
            ],
            [
                -103,
                -9
            ],
            [
                -50,
                39
            ],
            [
                -7,
                41
            ],
            [
                37,
                33
            ],
            [
                46,
                3
            ],
            [
                1,
                46
            ],
            [
                36,
                42
            ],
            [
                -1,
                50
            ],
            [
                90,
                31
            ],
            [
                27,
                60
            ],
            [
                22,
                118
            ],
            [
                64,
                44
            ],
            [
                37,
                1
            ],
            [
                33,
                45
            ],
            [
                6,
                47
            ],
            [
                46,
                34
            ],
            [
                71,
                17
            ],
            [
                75,
                -28
            ],
            [
                67,
                -9
            ],
            [
                -33,
                76
            ],
            [
                42,
                57
            ],
            [
                56,
                12
            ],
            [
                3,
                51
            ],
            [
                67,
                102
            ],
            [
                14,
                46
            ],
            [
                -13,
                65
            ],
            [
                18,
                113
            ],
            [
                -64,
                32
            ],
            [
                -44,
                42
            ],
            [
                -127,
                25
            ],
            [
                -61,
                75
            ],
            [
                78,
                78
            ],
            [
                -19,
                24
            ],
            [
                -2,
                76
            ],
            [
                37,
                58
            ],
            [
                -66,
                84
            ],
            [
                -73,
                -13
            ],
            [
                -45,
                39
            ],
            [
                -103,
                41
            ],
            [
                -28,
                34
            ],
            [
                -95,
                -6
            ],
            [
                -36,
                -88
            ],
            [
                -84,
                37
            ],
            [
                -65,
                -23
            ],
            [
                -63,
                40
            ],
            [
                -59,
                -7
            ],
            [
                37,
                84
            ],
            [
                -30,
                32
            ],
            [
                41,
                81
            ],
            [
                3,
                44
            ],
            [
                56,
                46
            ],
            [
                -43,
                51
            ],
            [
                12,
                23
            ],
            [
                -4,
                106
            ],
            [
                103,
                61
            ],
            [
                -18,
                58
            ],
            [
                -44,
                40
            ],
            [
                -36,
                71
            ],
            [
                93,
                108
            ],
            [
                113,
                21
            ],
            [
                3,
                67
            ],
            [
                31,
                30
            ],
            [
                26,
                -52
            ],
            [
                31,
                -3
            ],
            [
                85,
                70
            ],
            [
                36,
                65
            ],
            [
                63,
                37
            ],
            [
                -5,
                51
            ],
            [
                79,
                83
            ],
            [
                -52,
                94
            ],
            [
                -32,
                94
            ],
            [
                101,
                30
            ],
            [
                17,
                24
            ],
            [
                -55,
                96
            ],
            [
                64,
                2
            ]
        ],
        [
            [
                30684,
                56665
            ],
            [
                63,
                -18
            ],
            [
                82,
                -43
            ],
            [
                58,
                -16
            ],
            [
                72,
                86
            ],
            [
                27,
                -9
            ],
            [
                59,
                27
            ],
            [
                141,
                12
            ],
            [
                28,
                -22
            ],
            [
                42,
                19
            ],
            [
                41,
                -22
            ],
            [
                153,
                -23
            ],
            [
                18,
                36
            ],
            [
                65,
                24
            ],
            [
                56,
                -31
            ],
            [
                34,
                51
            ],
            [
                95,
                19
            ],
            [
                23,
                93
            ],
            [
                -111,
                24
            ],
            [
                -11,
                70
            ],
            [
                88,
                -28
            ],
            [
                58,
                21
            ],
            [
                47,
                44
            ],
            [
                -10,
                64
            ],
            [
                46,
                -1
            ],
            [
                -8,
                81
            ],
            [
                64,
                10
            ],
            [
                42,
                58
            ],
            [
                67,
                -38
            ],
            [
                18,
                -56
            ],
            [
                85,
                -17
            ],
            [
                27,
                35
            ],
            [
                55,
                -71
            ],
            [
                103,
                -30
            ],
            [
                35,
                140
            ],
            [
                53,
                -15
            ],
            [
                22,
                25
            ],
            [
                102,
                -60
            ],
            [
                32,
                -46
            ],
            [
                83,
                31
            ],
            [
                44,
                33
            ],
            [
                -14,
                40
            ],
            [
                8,
                67
            ],
            [
                27,
                32
            ],
            [
                40,
                -37
            ],
            [
                41,
                9
            ],
            [
                86,
                -49
            ],
            [
                125,
                37
            ],
            [
                10,
                -87
            ],
            [
                37,
                -85
            ],
            [
                62,
                -28
            ],
            [
                121,
                -118
            ],
            [
                69,
                -1
            ],
            [
                36,
                -42
            ],
            [
                117,
                -9
            ],
            [
                112,
                15
            ],
            [
                -2,
                63
            ],
            [
                42,
                72
            ],
            [
                7,
                43
            ],
            [
                34,
                23
            ],
            [
                20,
                71
            ],
            [
                109,
                53
            ],
            [
                122,
                -70
            ],
            [
                42,
                -14
            ],
            [
                64,
                7
            ],
            [
                79,
                -14
            ],
            [
                12,
                -35
            ],
            [
                81,
                -16
            ],
            [
                32,
                27
            ],
            [
                61,
                11
            ],
            [
                33,
                -27
            ],
            [
                35,
                77
            ],
            [
                89,
                49
            ],
            [
                156,
                -23
            ],
            [
                20,
                141
            ],
            [
                37,
                27
            ],
            [
                66,
                -1
            ],
            [
                9,
                -39
            ],
            [
                57,
                -16
            ],
            [
                24,
                18
            ],
            [
                80,
                -25
            ],
            [
                37,
                37
            ],
            [
                76,
                4
            ],
            [
                55,
                -18
            ],
            [
                85,
                60
            ],
            [
                56,
                9
            ],
            [
                55,
                47
            ],
            [
                19,
                -45
            ],
            [
                105,
                -4
            ],
            [
                29,
                103
            ],
            [
                -10,
                50
            ],
            [
                44,
                65
            ],
            [
                7,
                78
            ],
            [
                33,
                -8
            ],
            [
                116,
                21
            ],
            [
                53,
                -33
            ],
            [
                13,
                34
            ],
            [
                88,
                100
            ],
            [
                59,
                47
            ],
            [
                62,
                9
            ],
            [
                18,
                35
            ],
            [
                52,
                6
            ],
            [
                90,
                -74
            ],
            [
                20,
                -82
            ],
            [
                32,
                -58
            ]
        ],
        [
            [
                37453,
                48273
            ],
            [
                150,
                9
            ],
            [
                31,
                34
            ],
            [
                -36,
                43
            ],
            [
                -43,
                19
            ],
            [
                -86,
                -51
            ],
            [
                -16,
                -54
            ]
        ],
        [
            [
                45233,
                46393
            ],
            [
                -23,
                -38
            ],
            [
                39,
                -64
            ],
            [
                96,
                -61
            ],
            [
                -35,
                -30
            ],
            [
                101,
                -202
            ],
            [
                17,
                -83
            ],
            [
                34,
                -16
            ],
            [
                77,
                16
            ],
            [
                134,
                -31
            ],
            [
                104,
                -49
            ],
            [
                85,
                24
            ],
            [
                67,
                0
            ],
            [
                72,
                32
            ]
        ],
        [
            [
                46063,
                45749
            ],
            [
                -32,
                -29
            ],
            [
                -135,
                -54
            ],
            [
                -14,
                -79
            ],
            [
                30,
                -72
            ],
            [
                -5,
                -183
            ],
            [
                -30,
                -79
            ],
            [
                -11,
                -109
            ],
            [
                -52,
                -96
            ],
            [
                -40,
                -38
            ],
            [
                -71,
                -132
            ],
            [
                11,
                -89
            ],
            [
                25,
                -52
            ],
            [
                -48,
                -78
            ],
            [
                -90,
                -28
            ],
            [
                -150,
                71
            ],
            [
                -134,
                -128
            ],
            [
                -50,
                -97
            ],
            [
                -70,
                -194
            ],
            [
                -39,
                -28
            ],
            [
                -75,
                -19
            ],
            [
                -37,
                -46
            ],
            [
                33,
                -74
            ],
            [
                -4,
                -88
            ],
            [
                22,
                -27
            ],
            [
                -36,
                -64
            ],
            [
                -4,
                -94
            ],
            [
                -29,
                -55
            ],
            [
                63,
                -65
            ],
            [
                32,
                -12
            ],
            [
                3,
                -79
            ],
            [
                36,
                -9
            ],
            [
                34,
                -71
            ],
            [
                93,
                -32
            ],
            [
                16,
                38
            ],
            [
                59,
                -47
            ],
            [
                45,
                -8
            ],
            [
                44,
                -38
            ],
            [
                42,
                -60
            ],
            [
                92,
                12
            ],
            [
                98,
                -54
            ],
            [
                73,
                -5
            ],
            [
                86,
                -41
            ],
            [
                23,
                11
            ],
            [
                99,
                -16
            ],
            [
                44,
                -35
            ],
            [
                -35,
                -97
            ],
            [
                -4,
                -181
            ],
            [
                -12,
                -69
            ],
            [
                -31,
                -67
            ],
            [
                -6,
                -49
            ],
            [
                -44,
                -68
            ],
            [
                -44,
                -39
            ],
            [
                -37,
                -141
            ],
            [
                -41,
                -28
            ],
            [
                0,
                -82
            ],
            [
                -27,
                -32
            ],
            [
                5,
                -112
            ],
            [
                101,
                -76
            ],
            [
                178,
                -259
            ],
            [
                38,
                -94
            ],
            [
                110,
                -4
            ],
            [
                251,
                40
            ],
            [
                38,
                38
            ],
            [
                59,
                -25
            ],
            [
                49,
                7
            ],
            [
                2,
                -61
            ],
            [
                48,
                -106
            ],
            [
                44,
                -29
            ],
            [
                -20,
                -45
            ],
            [
                -10,
                -88
            ],
            [
                12,
                -97
            ],
            [
                -4,
                -68
            ],
            [
                10,
                -102
            ],
            [
                -88,
                -34
            ],
            [
                42,
                -141
            ],
            [
                82,
                -151
            ],
            [
                -45,
                -68
            ],
            [
                -81,
                -77
            ],
            [
                -173,
                -5
            ]
        ],
        [
            [
                46377,
                40867
            ],
            [
                -51,
                24
            ],
            [
                -192,
                178
            ],
            [
                -11,
                103
            ],
            [
                -173,
                62
            ],
            [
                -177,
                -60
            ],
            [
                -44,
                -40
            ],
            [
                -57,
                -82
            ],
            [
                -69,
                -37
            ],
            [
                -83,
                -67
            ],
            [
                -65,
                100
            ],
            [
                -96,
                -48
            ],
            [
                -54,
                -54
            ],
            [
                -69,
                -102
            ],
            [
                13,
                -48
            ],
            [
                -25,
                -203
            ],
            [
                -95,
                -69
            ],
            [
                -30,
                -115
            ],
            [
                22,
                -61
            ],
            [
                26,
                -129
            ],
            [
                8,
                -222
            ],
            [
                8,
                -45
            ],
            [
                -10,
                -121
            ],
            [
                -172,
                -125
            ],
            [
                -17,
                -36
            ],
            [
                -111,
                -80
            ],
            [
                -31,
                15
            ],
            [
                -76,
                -22
            ],
            [
                -111,
                11
            ],
            [
                -62,
                93
            ],
            [
                29,
                84
            ],
            [
                -49,
                54
            ],
            [
                -46,
                -26
            ],
            [
                -71,
                51
            ],
            [
                -76,
                -10
            ],
            [
                1,
                -51
            ],
            [
                -36,
                -32
            ],
            [
                -72,
                -18
            ],
            [
                -30,
                -56
            ],
            [
                -87,
                -23
            ],
            [
                -39,
                -47
            ],
            [
                -105,
                -72
            ],
            [
                -192,
                -59
            ],
            [
                -59,
                7
            ],
            [
                -56,
                61
            ],
            [
                -76,
                20
            ],
            [
                -40,
                -31
            ],
            [
                -34,
                -55
            ],
            [
                -21,
                -65
            ],
            [
                -80,
                -100
            ],
            [
                -150,
                -44
            ],
            [
                -120,
                -91
            ],
            [
                -24,
                -90
            ],
            [
                -78,
                -142
            ],
            [
                2,
                -40
            ],
            [
                -31,
                -57
            ],
            [
                -86,
                -96
            ],
            [
                -54,
                -34
            ],
            [
                -44,
                -47
            ],
            [
                -40,
                -131
            ],
            [
                8,
                -15
            ]
        ],
        [
            [
                35495,
                41140
            ],
            [
                82,
                23
            ],
            [
                34,
                -9
            ],
            [
                38,
                25
            ],
            [
                46,
                78
            ],
            [
                33,
                108
            ],
            [
                19,
                149
            ],
            [
                28,
                90
            ],
            [
                77,
                123
            ],
            [
                134,
                -2
            ],
            [
                47,
                -18
            ],
            [
                19,
                141
            ],
            [
                22,
                71
            ],
            [
                -52,
                30
            ],
            [
                -66,
                2
            ],
            [
                -77,
                57
            ],
            [
                -36,
                -23
            ],
            [
                -42,
                69
            ],
            [
                -9,
                80
            ],
            [
                -22,
                43
            ],
            [
                -47,
                185
            ],
            [
                71,
                -7
            ],
            [
                146,
                -89
            ],
            [
                58,
                -4
            ],
            [
                32,
                51
            ],
            [
                44,
                3
            ],
            [
                19,
                61
            ],
            [
                -27,
                30
            ],
            [
                -61,
                23
            ],
            [
                -44,
                82
            ],
            [
                13,
                53
            ],
            [
                34,
                34
            ],
            [
                10,
                108
            ],
            [
                59,
                47
            ],
            [
                58,
                130
            ],
            [
                99,
                -37
            ],
            [
                84,
                19
            ],
            [
                77,
                -26
            ],
            [
                102,
                -66
            ],
            [
                74,
                9
            ],
            [
                -43,
                52
            ],
            [
                -47,
                25
            ],
            [
                -61,
                77
            ],
            [
                -23,
                114
            ],
            [
                -49,
                39
            ],
            [
                -62,
                210
            ],
            [
                -18,
                34
            ],
            [
                149,
                37
            ],
            [
                -11,
                56
            ],
            [
                16,
                69
            ],
            [
                27,
                34
            ],
            [
                30,
                101
            ],
            [
                37,
                88
            ],
            [
                -56,
                101
            ],
            [
                -66,
                -63
            ],
            [
                -110,
                -71
            ],
            [
                -55,
                -69
            ],
            [
                -82,
                2
            ],
            [
                -71,
                -30
            ],
            [
                -34,
                -36
            ],
            [
                -45,
                -2
            ],
            [
                -26,
                -40
            ],
            [
                -87,
                25
            ],
            [
                -83,
                75
            ],
            [
                -43,
                -8
            ],
            [
                -158,
                153
            ],
            [
                -48,
                66
            ],
            [
                -50,
                92
            ],
            [
                -40,
                46
            ],
            [
                -67,
                4
            ],
            [
                -54,
                68
            ],
            [
                -54,
                47
            ],
            [
                -100,
                148
            ],
            [
                -112,
                113
            ],
            [
                26,
                60
            ],
            [
                84,
                130
            ],
            [
                -3,
                45
            ],
            [
                30,
                22
            ],
            [
                -14,
                47
            ],
            [
                13,
                82
            ],
            [
                38,
                70
            ],
            [
                -51,
                89
            ],
            [
                -47,
                44
            ],
            [
                42,
                33
            ],
            [
                -19,
                75
            ],
            [
                -4,
                74
            ],
            [
                -106,
                -8
            ],
            [
                -103,
                -114
            ],
            [
                -28,
                -53
            ],
            [
                -34,
                -1
            ],
            [
                -76,
                32
            ],
            [
                -37,
                -2
            ],
            [
                -20,
                46
            ],
            [
                13,
                53
            ],
            [
                -14,
                35
            ],
            [
                11,
                103
            ],
            [
                16,
                44
            ],
            [
                62,
                78
            ],
            [
                -29,
                65
            ],
            [
                -87,
                64
            ],
            [
                -116,
                -13
            ],
            [
                -67,
                -35
            ],
            [
                -19,
                24
            ],
            [
                16,
                116
            ],
            [
                81,
                249
            ],
            [
                8,
                101
            ],
            [
                -10,
                102
            ],
            [
                -21,
                77
            ],
            [
                15,
                73
            ],
            [
                -2,
                78
            ],
            [
                26,
                31
            ],
            [
                63,
                27
            ]
        ],
        [
            [
                36761,
                46660
            ],
            [
                10,
                -38
            ],
            [
                107,
                -28
            ],
            [
                52,
                22
            ],
            [
                -2,
                30
            ],
            [
                101,
                61
            ],
            [
                88,
                11
            ],
            [
                27,
                17
            ],
            [
                27,
                75
            ],
            [
                77,
                96
            ],
            [
                41,
                20
            ],
            [
                -11,
                45
            ],
            [
                21,
                48
            ],
            [
                63,
                -17
            ],
            [
                -2,
                -144
            ],
            [
                44,
                -115
            ],
            [
                83,
                -21
            ],
            [
                40,
                -56
            ],
            [
                67,
                52
            ],
            [
                37,
                98
            ],
            [
                32,
                53
            ],
            [
                45,
                8
            ],
            [
                69,
                -26
            ],
            [
                2,
                73
            ],
            [
                31,
                31
            ],
            [
                32,
                -13
            ],
            [
                34,
                -77
            ],
            [
                62,
                -31
            ],
            [
                -18,
                -55
            ],
            [
                53,
                -3
            ],
            [
                73,
                -26
            ],
            [
                89,
                86
            ],
            [
                53,
                -51
            ],
            [
                76,
                -8
            ],
            [
                -6,
                -48
            ],
            [
                91,
                -98
            ],
            [
                107,
                10
            ],
            [
                -9,
                -51
            ],
            [
                68,
                20
            ],
            [
                56,
                -52
            ],
            [
                123,
                -10
            ],
            [
                33,
                -65
            ],
            [
                48,
                -31
            ],
            [
                94,
                53
            ],
            [
                43,
                -48
            ],
            [
                34,
                -86
            ],
            [
                72,
                -25
            ],
            [
                58,
                53
            ],
            [
                88,
                -53
            ],
            [
                68,
                13
            ],
            [
                26,
                -40
            ],
            [
                135,
                -61
            ],
            [
                -8,
                -95
            ],
            [
                -50,
                -31
            ],
            [
                20,
                -70
            ],
            [
                -127,
                -103
            ],
            [
                -32,
                -46
            ],
            [
                -57,
                11
            ],
            [
                -55,
                -67
            ],
            [
                -24,
                -52
            ],
            [
                -45,
                -23
            ],
            [
                24,
                -45
            ],
            [
                -38,
                -59
            ],
            [
                -154,
                8
            ],
            [
                -58,
                -54
            ],
            [
                -60,
                -3
            ],
            [
                -65,
                -40
            ],
            [
                27,
                -95
            ],
            [
                86,
                -12
            ],
            [
                53,
                -52
            ],
            [
                69,
                52
            ],
            [
                28,
                43
            ],
            [
                62,
                38
            ],
            [
                30,
                70
            ],
            [
                90,
                54
            ],
            [
                37,
                -30
            ],
            [
                55,
                52
            ],
            [
                42,
                19
            ],
            [
                84,
                10
            ],
            [
                -19,
                74
            ],
            [
                118,
                49
            ],
            [
                13,
                42
            ],
            [
                163,
                19
            ],
            [
                38,
                92
            ],
            [
                69,
                16
            ],
            [
                56,
                -24
            ],
            [
                107,
                -9
            ],
            [
                13,
                31
            ],
            [
                83,
                -43
            ],
            [
                0,
                37
            ],
            [
                48,
                16
            ],
            [
                -1,
                94
            ],
            [
                88,
                21
            ],
            [
                46,
                -4
            ],
            [
                42,
                -41
            ],
            [
                24,
                -55
            ],
            [
                71,
                -24
            ],
            [
                37,
                34
            ],
            [
                51,
                -11
            ],
            [
                96,
                36
            ],
            [
                86,
                100
            ],
            [
                28,
                4
            ],
            [
                14,
                -97
            ],
            [
                118,
                -13
            ],
            [
                79,
                33
            ],
            [
                75,
                74
            ],
            [
                18,
                60
            ],
            [
                -64,
                96
            ],
            [
                -51,
                37
            ],
            [
                -3,
                41
            ],
            [
                56,
                73
            ],
            [
                -26,
                76
            ],
            [
                21,
                112
            ],
            [
                -54,
                136
            ],
            [
                2,
                54
            ],
            [
                -38,
                39
            ],
            [
                -130,
                -108
            ],
            [
                -34,
                -65
            ],
            [
                -62,
                32
            ],
            [
                -1,
                37
            ],
            [
                34,
                43
            ],
            [
                15,
                170
            ],
            [
                67,
                62
            ],
            [
                26,
                97
            ],
            [
                -26,
                35
            ],
            [
                39,
                32
            ],
            [
                23,
                108
            ],
            [
                -31,
                26
            ],
            [
                -38,
                101
            ],
            [
                -116,
                11
            ],
            [
                -4,
                78
            ],
            [
                13,
                56
            ],
            [
                -18,
                63
            ],
            [
                4,
                72
            ],
            [
                -118,
                82
            ],
            [
                -15,
                25
            ],
            [
                -60,
                -32
            ],
            [
                -68,
                8
            ],
            [
                41,
                56
            ],
            [
                -2,
                63
            ],
            [
                -76,
                38
            ],
            [
                -54,
                66
            ],
            [
                23,
                55
            ],
            [
                -4,
                67
            ],
            [
                -113,
                66
            ],
            [
                6,
                26
            ],
            [
                -26,
                85
            ],
            [
                -74,
                51
            ],
            [
                -13,
                -80
            ],
            [
                -101,
                30
            ],
            [
                -53,
                54
            ],
            [
                16,
                61
            ],
            [
                59,
                80
            ],
            [
                -34,
                38
            ],
            [
                9,
                73
            ],
            [
                -61,
                87
            ],
            [
                -100,
                18
            ],
            [
                72,
                138
            ],
            [
                23,
                112
            ],
            [
                -15,
                30
            ],
            [
                65,
                47
            ],
            [
                13,
                40
            ],
            [
                -44,
                64
            ],
            [
                30,
                29
            ],
            [
                38,
                77
            ],
            [
                1,
                46
            ],
            [
                50,
                115
            ],
            [
                -4,
                38
            ],
            [
                64,
                47
            ],
            [
                -9,
                27
            ],
            [
                -109,
                131
            ],
            [
                33,
                113
            ],
            [
                -17,
                43
            ],
            [
                -44,
                -11
            ],
            [
                -74,
                52
            ],
            [
                -33,
                -7
            ],
            [
                -11,
                73
            ],
            [
                -52,
                76
            ],
            [
                -79,
                61
            ],
            [
                2,
                54
            ]
        ],
        [
            [
                54540,
                54930
            ],
            [
                -80,
                24
            ],
            [
                5,
                91
            ],
            [
                68,
                56
            ],
            [
                -4,
                -67
            ],
            [
                17,
                -47
            ],
            [
                -6,
                -57
            ]
        ],
        [
            [
                50549,
                47793
            ],
            [
                -179,
                68
            ],
            [
                -36,
                32
            ],
            [
                -14,
                111
            ],
            [
                -112,
                100
            ],
            [
                -119,
                3
            ],
            [
                -81,
                25
            ],
            [
                -99,
                58
            ],
            [
                -34,
                53
            ],
            [
                6,
                57
            ],
            [
                64,
                107
            ],
            [
                -82,
                65
            ],
            [
                -54,
                -21
            ],
            [
                -67,
                120
            ],
            [
                -53,
                -31
            ],
            [
                -72,
                32
            ]
        ],
        [
            [
                50941,
                55835
            ],
            [
                59,
                67
            ],
            [
                -25,
                40
            ],
            [
                -58,
                171
            ],
            [
                -3,
                72
            ],
            [
                26,
                49
            ],
            [
                28,
                95
            ],
            [
                -25,
                59
            ],
            [
                128,
                79
            ],
            [
                40,
                -5
            ],
            [
                34,
                -42
            ],
            [
                139,
                -48
            ],
            [
                41,
                18
            ],
            [
                84,
                -46
            ],
            [
                69,
                -10
            ],
            [
                72,
                -46
            ],
            [
                112,
                -88
            ],
            [
                93,
                58
            ],
            [
                64,
                -71
            ],
            [
                61,
                16
            ],
            [
                100,
                -36
            ],
            [
                79,
                6
            ],
            [
                96,
                -41
            ],
            [
                63,
                -79
            ],
            [
                4,
                -80
            ],
            [
                88,
                -70
            ],
            [
                137,
                42
            ],
            [
                37,
                29
            ],
            [
                55,
                -28
            ],
            [
                180,
                -5
            ],
            [
                45,
                22
            ],
            [
                66,
                -18
            ],
            [
                -10,
                -76
            ],
            [
                61,
                -16
            ],
            [
                34,
                -34
            ],
            [
                -6,
                -67
            ],
            [
                34,
                -11
            ],
            [
                40,
                -65
            ],
            [
                74,
                -181
            ],
            [
                -24,
                -88
            ],
            [
                31,
                -34
            ],
            [
                -49,
                -29
            ],
            [
                -34,
                -86
            ],
            [
                70,
                30
            ],
            [
                35,
                -20
            ],
            [
                4,
                -42
            ],
            [
                41,
                -60
            ],
            [
                -70,
                -58
            ],
            [
                -47,
                -6
            ],
            [
                -18,
                -36
            ],
            [
                59,
                -31
            ],
            [
                9,
                -126
            ],
            [
                193,
                -47
            ],
            [
                100,
                17
            ],
            [
                12,
                76
            ],
            [
                80,
                29
            ],
            [
                181,
                21
            ],
            [
                24,
                25
            ],
            [
                0,
                72
            ],
            [
                112,
                -14
            ],
            [
                61,
                56
            ],
            [
                13,
                -46
            ],
            [
                43,
                11
            ],
            [
                43,
                -29
            ],
            [
                80,
                -8
            ],
            [
                34,
                18
            ],
            [
                92,
                -42
            ],
            [
                23,
                -39
            ],
            [
                94,
                -37
            ],
            [
                6,
                -32
            ],
            [
                85,
                -14
            ],
            [
                46,
                33
            ],
            [
                27,
                -37
            ],
            [
                -4,
                -54
            ],
            [
                47,
                -26
            ],
            [
                -9,
                -42
            ],
            [
                24,
                -109
            ],
            [
                58,
                -76
            ],
            [
                70,
                -32
            ],
            [
                128,
                19
            ],
            [
                82,
                57
            ],
            [
                74,
                34
            ],
            [
                33,
                114
            ],
            [
                105,
                41
            ],
            [
                34,
                -22
            ],
            [
                72,
                -7
            ],
            [
                46,
                32
            ],
            [
                60,
                10
            ],
            [
                29,
                38
            ],
            [
                94,
                -59
            ],
            [
                57,
                21
            ],
            [
                32,
                -39
            ],
            [
                53,
                -4
            ],
            [
                46,
                -52
            ],
            [
                72,
                -10
            ],
            [
                74,
                -30
            ],
            [
                51,
                4
            ],
            [
                5,
                -66
            ],
            [
                84,
                -54
            ],
            [
                46,
                -78
            ],
            [
                45,
                13
            ],
            [
                71,
                -37
            ],
            [
                43,
                2
            ],
            [
                40,
                73
            ],
            [
                39,
                19
            ],
            [
                110,
                -47
            ],
            [
                172,
                -14
            ],
            [
                -1,
                55
            ],
            [
                -57,
                116
            ],
            [
                47,
                -6
            ],
            [
                22,
                75
            ],
            [
                37,
                -2
            ],
            [
                65,
                55
            ],
            [
                100,
                18
            ],
            [
                61,
                -53
            ],
            [
                61,
                24
            ],
            [
                50,
                80
            ],
            [
                27,
                -2
            ],
            [
                33,
                74
            ],
            [
                58,
                -15
            ],
            [
                26,
                46
            ],
            [
                129,
                -42
            ],
            [
                37,
                68
            ],
            [
                36,
                20
            ],
            [
                60,
                -54
            ],
            [
                58,
                31
            ],
            [
                65,
                -28
            ],
            [
                56,
                31
            ],
            [
                66,
                -107
            ],
            [
                101,
                -77
            ],
            [
                69,
                49
            ],
            [
                64,
                -19
            ],
            [
                71,
                3
            ],
            [
                -4,
                -60
            ],
            [
                -33,
                -49
            ],
            [
                9,
                -55
            ],
            [
                -14,
                -40
            ],
            [
                21,
                -69
            ],
            [
                66,
                -31
            ],
            [
                -5,
                -33
            ],
            [
                122,
                30
            ],
            [
                28,
                -53
            ],
            [
                73,
                13
            ],
            [
                57,
                -39
            ],
            [
                75,
                -23
            ],
            [
                5,
                -35
            ],
            [
                -70,
                -45
            ],
            [
                15,
                -58
            ],
            [
                -60,
                -11
            ],
            [
                32,
                -66
            ],
            [
                -20,
                -38
            ],
            [
                -63,
                -17
            ],
            [
                -26,
                -36
            ],
            [
                -41,
                28
            ],
            [
                -38,
                -61
            ],
            [
                -96,
                48
            ],
            [
                -6,
                47
            ],
            [
                -49,
                26
            ],
            [
                -67,
                -46
            ],
            [
                -52,
                -89
            ],
            [
                -23,
                -96
            ],
            [
                -2,
                -64
            ],
            [
                13,
                -135
            ],
            [
                28,
                -81
            ],
            [
                33,
                -2
            ],
            [
                128,
                -86
            ],
            [
                61,
                -110
            ],
            [
                17,
                -65
            ],
            [
                -42,
                -28
            ],
            [
                -10,
                -91
            ],
            [
                16,
                -110
            ],
            [
                32,
                -74
            ],
            [
                55,
                -21
            ],
            [
                5,
                -68
            ],
            [
                -40,
                -29
            ],
            [
                -7,
                -92
            ],
            [
                -37,
                -99
            ],
            [
                -52,
                -12
            ],
            [
                -32,
                -94
            ],
            [
                -54,
                -4
            ],
            [
                -19,
                -46
            ],
            [
                -118,
                -18
            ],
            [
                -21,
                -41
            ],
            [
                -66,
                -65
            ],
            [
                -6,
                -56
            ],
            [
                -62,
                -83
            ],
            [
                -93,
                -24
            ],
            [
                -42,
                -40
            ],
            [
                -44,
                -12
            ],
            [
                -119,
                -143
            ],
            [
                -122,
                -67
            ],
            [
                -95,
                -5
            ],
            [
                -94,
                -42
            ],
            [
                -6,
                -20
            ],
            [
                -84,
                -63
            ],
            [
                -18,
                -55
            ],
            [
                -103,
                -37
            ],
            [
                -61,
                -46
            ],
            [
                -317,
                -131
            ],
            [
                -108,
                -61
            ],
            [
                -16,
                6
            ],
            [
                -116,
                -65
            ],
            [
                -20,
                -20
            ],
            [
                -114,
                -46
            ],
            [
                -150,
                -130
            ],
            [
                -116,
                -70
            ],
            [
                -158,
                -51
            ],
            [
                -108,
                -60
            ],
            [
                -54,
                -65
            ],
            [
                -53,
                -113
            ],
            [
                -52,
                -58
            ],
            [
                -18,
                -44
            ],
            [
                -61,
                -38
            ],
            [
                -79,
                -196
            ],
            [
                -25,
                -44
            ],
            [
                -72,
                -72
            ],
            [
                -84,
                -37
            ],
            [
                -149,
                -48
            ],
            [
                -84,
                -8
            ],
            [
                -121,
                1
            ],
            [
                -130,
                -40
            ],
            [
                -93,
                -45
            ],
            [
                -39,
                -30
            ],
            [
                -132,
                2
            ],
            [
                -59,
                -42
            ],
            [
                -110,
                -19
            ],
            [
                -98,
                -40
            ],
            [
                -30,
                3
            ],
            [
                -60,
                -36
            ],
            [
                -119,
                -23
            ],
            [
                -266,
                -37
            ],
            [
                -232,
                -84
            ],
            [
                -113,
                -71
            ],
            [
                -26,
                -34
            ],
            [
                -57,
                10
            ],
            [
                -81,
                -29
            ],
            [
                -60,
                15
            ],
            [
                -68,
                -8
            ],
            [
                -69,
                -56
            ],
            [
                -34,
                2
            ],
            [
                -74,
                -73
            ],
            [
                -46,
                36
            ],
            [
                -64,
                -59
            ],
            [
                -49,
                -122
            ],
            [
                -31,
                4
            ],
            [
                -46,
                63
            ],
            [
                -68,
                6
            ],
            [
                -88,
                -37
            ],
            [
                -111,
                -4
            ],
            [
                -112,
                -74
            ],
            [
                -32,
                -2
            ],
            [
                -106,
                -91
            ],
            [
                -52,
                -78
            ],
            [
                -48,
                -29
            ],
            [
                -62,
                -68
            ],
            [
                -50,
                -74
            ],
            [
                -33,
                -15
            ],
            [
                -96,
                -164
            ],
            [
                -82,
                -105
            ],
            [
                -13,
                -31
            ],
            [
                -104,
                -73
            ],
            [
                -31,
                -85
            ],
            [
                -97,
                -52
            ],
            [
                -17,
                -25
            ],
            [
                23,
                -61
            ],
            [
                57,
                -61
            ],
            [
                110,
                -23
            ],
            [
                23,
                40
            ],
            [
                -127,
                59
            ],
            [
                43,
                13
            ],
            [
                65,
                -9
            ],
            [
                146,
                -202
            ],
            [
                25,
                -19
            ],
            [
                64,
                11
            ],
            [
                44,
                -12
            ],
            [
                12,
                -48
            ],
            [
                -26,
                -84
            ],
            [
                -57,
                -60
            ],
            [
                -215,
                -120
            ],
            [
                -89,
                -98
            ],
            [
                -87,
                -149
            ],
            [
                -94,
                -77
            ],
            [
                -95,
                -26
            ],
            [
                -83,
                30
            ],
            [
                -20,
                52
            ],
            [
                58,
                42
            ],
            [
                47,
                -38
            ],
            [
                82,
                8
            ],
            [
                22,
                39
            ],
            [
                82,
                4
            ],
            [
                29,
                27
            ],
            [
                68,
                106
            ],
            [
                13,
                38
            ],
            [
                -49,
                12
            ],
            [
                -83,
                -9
            ],
            [
                -87,
                -40
            ],
            [
                -97,
                -15
            ],
            [
                -72,
                0
            ],
            [
                -106,
                -136
            ],
            [
                -64,
                -68
            ],
            [
                -43,
                -93
            ],
            [
                -19,
                -71
            ]
        ],
        [
            [
                41162,
                28505
            ],
            [
                18,
                -61
            ],
            [
                37,
                -53
            ],
            [
                -11,
                -43
            ],
            [
                -64,
                39
            ],
            [
                0,
                76
            ],
            [
                20,
                42
            ]
        ],
        [
            [
                34631,
                30714
            ],
            [
                60,
                -69
            ],
            [
                92,
                -34
            ],
            [
                59,
                62
            ],
            [
                26,
                -37
            ],
            [
                -82,
                -49
            ],
            [
                -33,
                7
            ],
            [
                -55,
                -42
            ],
            [
                -98,
                34
            ],
            [
                -21,
                103
            ],
            [
                52,
                25
            ]
        ],
        [
            [
                44155,
                53301
            ],
            [
                -22,
                -20
            ],
            [
                -85,
                93
            ],
            [
                -40,
                8
            ],
            [
                -12,
                138
            ],
            [
                36,
                67
            ],
            [
                142,
                160
            ],
            [
                6,
                51
            ],
            [
                44,
                43
            ],
            [
                61,
                -44
            ],
            [
                46,
                -15
            ],
            [
                47,
                47
            ],
            [
                68,
                -2
            ],
            [
                53,
                -60
            ],
            [
                81,
                55
            ],
            [
                -29,
                92
            ],
            [
                53,
                71
            ],
            [
                -59,
                13
            ],
            [
                -15,
                51
            ],
            [
                -87,
                10
            ],
            [
                -106,
                45
            ],
            [
                -54,
                -6
            ],
            [
                -54,
                38
            ],
            [
                -29,
                42
            ],
            [
                -61,
                51
            ],
            [
                -63,
                3
            ],
            [
                27,
                63
            ],
            [
                -12,
                48
            ],
            [
                -69,
                10
            ],
            [
                -50,
                35
            ],
            [
                15,
                38
            ],
            [
                -62,
                41
            ],
            [
                -29,
                48
            ],
            [
                -57,
                -6
            ],
            [
                -2,
                34
            ],
            [
                -84,
                77
            ],
            [
                -64,
                18
            ],
            [
                -71,
                -35
            ],
            [
                -66,
                -61
            ],
            [
                -54,
                16
            ],
            [
                30,
                81
            ],
            [
                -24,
                55
            ],
            [
                -76,
                59
            ],
            [
                29,
                61
            ],
            [
                -76,
                20
            ],
            [
                -68,
                -41
            ],
            [
                -49,
                -8
            ],
            [
                -28,
                40
            ],
            [
                -49,
                -13
            ],
            [
                -39,
                39
            ],
            [
                -59,
                16
            ],
            [
                8,
                38
            ],
            [
                -72,
                35
            ],
            [
                -34,
                -8
            ],
            [
                -132,
                76
            ],
            [
                -16,
                -53
            ],
            [
                -38,
                -9
            ],
            [
                -103,
                70
            ],
            [
                -44,
                5
            ],
            [
                -55,
                65
            ]
        ],
        [
            [
                42603,
                55086
            ],
            [
                -4,
                84
            ],
            [
                81,
                19
            ],
            [
                -22,
                97
            ],
            [
                28,
                22
            ],
            [
                -25,
                181
            ],
            [
                -70,
                25
            ],
            [
                -51,
                -111
            ],
            [
                -50,
                47
            ],
            [
                -77,
                -7
            ],
            [
                -25,
                115
            ],
            [
                46,
                -16
            ],
            [
                50,
                56
            ],
            [
                39,
                74
            ],
            [
                93,
                57
            ],
            [
                57,
                -6
            ],
            [
                50,
                -38
            ],
            [
                1,
                -50
            ],
            [
                77,
                -12
            ],
            [
                41,
                45
            ],
            [
                119,
                33
            ],
            [
                -14,
                85
            ],
            [
                -43,
                -25
            ],
            [
                -17,
                119
            ],
            [
                -22,
                77
            ],
            [
                19,
                57
            ],
            [
                35,
                -12
            ],
            [
                82,
                21
            ],
            [
                30,
                24
            ],
            [
                -50,
                137
            ],
            [
                33,
                37
            ],
            [
                3,
                68
            ],
            [
                86,
                78
            ],
            [
                9,
                34
            ],
            [
                -22,
                79
            ],
            [
                8,
                209
            ],
            [
                47,
                44
            ],
            [
                27,
                62
            ],
            [
                49,
                -3
            ],
            [
                33,
                -42
            ],
            [
                62,
                17
            ],
            [
                75,
                -11
            ],
            [
                59,
                35
            ],
            [
                34,
                88
            ],
            [
                115,
                14
            ],
            [
                39,
                17
            ],
            [
                27,
                66
            ],
            [
                -36,
                115
            ],
            [
                63,
                88
            ],
            [
                -12,
                43
            ],
            [
                35,
                31
            ],
            [
                84,
                31
            ],
            [
                35,
                50
            ],
            [
                49,
                8
            ],
            [
                65,
                67
            ],
            [
                52,
                105
            ],
            [
                -43,
                23
            ],
            [
                12,
                54
            ],
            [
                -17,
                82
            ],
            [
                52,
                91
            ],
            [
                35,
                -14
            ],
            [
                110,
                63
            ],
            [
                66,
                16
            ],
            [
                17,
                38
            ],
            [
                64,
                29
            ],
            [
                3,
                110
            ],
            [
                29,
                38
            ],
            [
                147,
                35
            ]
        ],
        [
            [
                44475,
                58079
            ],
            [
                93,
                58
            ],
            [
                72,
                11
            ],
            [
                123,
                -52
            ],
            [
                -21,
                -59
            ],
            [
                37,
                -102
            ],
            [
                23,
                -19
            ],
            [
                90,
                -6
            ],
            [
                66,
                155
            ],
            [
                120,
                4
            ],
            [
                40,
                -45
            ],
            [
                144,
                -54
            ],
            [
                66,
                26
            ],
            [
                80,
                -75
            ],
            [
                16,
                -94
            ],
            [
                -17,
                -16
            ],
            [
                2,
                -125
            ],
            [
                -49,
                -43
            ],
            [
                -1,
                -85
            ],
            [
                -34,
                -99
            ],
            [
                -70,
                -79
            ],
            [
                -83,
                -59
            ],
            [
                -2,
                -38
            ],
            [
                83,
                -131
            ],
            [
                238,
                -67
            ],
            [
                30,
                98
            ],
            [
                -10,
                134
            ],
            [
                38,
                64
            ],
            [
                34,
                18
            ],
            [
                134,
                20
            ],
            [
                -80,
                -96
            ],
            [
                -24,
                -86
            ],
            [
                105,
                -52
            ],
            [
                16,
                -40
            ],
            [
                42,
                -6
            ],
            [
                51,
                48
            ],
            [
                131,
                -87
            ],
            [
                18,
                19
            ],
            [
                79,
                -43
            ],
            [
                32,
                -64
            ],
            [
                49,
                55
            ],
            [
                23,
                -32
            ],
            [
                66,
                -14
            ],
            [
                28,
                -48
            ],
            [
                173,
                -26
            ],
            [
                21,
                -48
            ],
            [
                69,
                -55
            ],
            [
                81,
                -36
            ],
            [
                52,
                2
            ],
            [
                31,
                36
            ],
            [
                47,
                -29
            ],
            [
                87,
                -18
            ],
            [
                151,
                2
            ],
            [
                52,
                46
            ],
            [
                60,
                19
            ],
            [
                43,
                -7
            ],
            [
                22,
                -62
            ],
            [
                35,
                -20
            ],
            [
                -8,
                -53
            ],
            [
                16,
                -43
            ]
        ],
        [
            [
                50549,
                47793
            ],
            [
                -30,
                -76
            ],
            [
                -74,
                -139
            ],
            [
                -28,
                -16
            ],
            [
                -72,
                -128
            ],
            [
                -10,
                -40
            ],
            [
                -49,
                -52
            ],
            [
                -40,
                -148
            ],
            [
                -43,
                -77
            ],
            [
                -59,
                -60
            ],
            [
                -49,
                -106
            ],
            [
                -40,
                -52
            ],
            [
                -99,
                -88
            ],
            [
                -58,
                -71
            ],
            [
                -29,
                -115
            ],
            [
                -83,
                -34
            ],
            [
                -90,
                -70
            ],
            [
                -43,
                -61
            ],
            [
                -133,
                -334
            ],
            [
                -73,
                -64
            ],
            [
                -66,
                3
            ],
            [
                -82,
                -69
            ],
            [
                -52,
                -100
            ],
            [
                -30,
                -125
            ],
            [
                -37,
                -39
            ],
            [
                -18,
                -111
            ],
            [
                -24,
                -84
            ],
            [
                -55,
                -53
            ],
            [
                -79,
                -96
            ],
            [
                -82,
                -67
            ],
            [
                -43,
                -84
            ],
            [
                -48,
                -66
            ],
            [
                -88,
                -173
            ],
            [
                -81,
                -133
            ],
            [
                -42,
                -109
            ],
            [
                -11,
                -116
            ],
            [
                -51,
                -109
            ],
            [
                -46,
                -26
            ],
            [
                -61,
                -82
            ],
            [
                -19,
                -45
            ],
            [
                -39,
                -148
            ],
            [
                -34,
                -32
            ],
            [
                -65,
                -126
            ],
            [
                -20,
                -127
            ],
            [
                7,
                -78
            ],
            [
                33,
                -55
            ],
            [
                -41,
                -24
            ],
            [
                -23,
                -64
            ],
            [
                14,
                -100
            ],
            [
                37,
                -149
            ],
            [
                73,
                -227
            ],
            [
                33,
                -60
            ],
            [
                87,
                -216
            ],
            [
                56,
                -125
            ],
            [
                -48,
                -69
            ],
            [
                13,
                -129
            ],
            [
                76,
                -236
            ],
            [
                56,
                -134
            ],
            [
                142,
                -249
            ],
            [
                76,
                -102
            ],
            [
                93,
                -112
            ],
            [
                156,
                -149
            ],
            [
                134,
                -42
            ],
            [
                54,
                13
            ],
            [
                158,
                -60
            ],
            [
                25,
                -44
            ],
            [
                72,
                -13
            ],
            [
                44,
                -49
            ],
            [
                94,
                -60
            ],
            [
                -46,
                -27
            ],
            [
                25,
                -78
            ],
            [
                49,
                -48
            ],
            [
                37,
                10
            ],
            [
                22,
                -115
            ],
            [
                -69,
                -2
            ],
            [
                -62,
                -41
            ],
            [
                -101,
                -125
            ],
            [
                -43,
                -5
            ],
            [
                -39,
                -39
            ],
            [
                -38,
                -8
            ],
            [
                -74,
                -95
            ],
            [
                -3,
                -25
            ],
            [
                -68,
                1
            ],
            [
                -56,
                -51
            ],
            [
                -34,
                14
            ],
            [
                -78,
                -16
            ],
            [
                -39,
                -32
            ],
            [
                -91,
                -136
            ],
            [
                -6,
                -39
            ],
            [
                39,
                -19
            ],
            [
                -71,
                -109
            ],
            [
                -64,
                -43
            ],
            [
                -36,
                35
            ],
            [
                -94,
                7
            ],
            [
                -32,
                -20
            ],
            [
                -31,
                -54
            ],
            [
                -71,
                -6
            ],
            [
                -95,
                -49
            ],
            [
                -101,
                -30
            ],
            [
                -90,
                -45
            ],
            [
                -144,
                -122
            ],
            [
                -50,
                -22
            ],
            [
                -39,
                -55
            ],
            [
                -12,
                -52
            ],
            [
                -28,
                -24
            ],
            [
                -27,
                -207
            ],
            [
                -54,
                14
            ],
            [
                -31,
                29
            ],
            [
                -91,
                -72
            ],
            [
                -42,
                -55
            ],
            [
                -49,
                -21
            ],
            [
                -24,
                -129
            ],
            [
                20,
                -204
            ],
            [
                18,
                -76
            ],
            [
                -9,
                -45
            ],
            [
                -50,
                -42
            ],
            [
                -101,
                8
            ],
            [
                -80,
                -11
            ],
            [
                -46,
                -49
            ],
            [
                -70,
                -144
            ],
            [
                -34,
                -279
            ],
            [
                -4,
                -198
            ],
            [
                15,
                -12
            ],
            [
                -35,
                -80
            ],
            [
                -48,
                -39
            ],
            [
                -40,
                1
            ],
            [
                -29,
                -64
            ],
            [
                -4,
                -53
            ],
            [
                -36,
                -21
            ],
            [
                -3,
                -91
            ],
            [
                -58,
                -8
            ],
            [
                -26,
                -40
            ],
            [
                -6,
                -77
            ],
            [
                -25,
                -48
            ],
            [
                -2,
                -66
            ]
        ],
        [
            [
                47091,
                37868
            ],
            [
                -76,
                5
            ],
            [
                -35,
                40
            ],
            [
                -72,
                19
            ],
            [
                -73,
                78
            ],
            [
                -117,
                140
            ],
            [
                -57,
                80
            ],
            [
                -35,
                90
            ],
            [
                -67,
                112
            ],
            [
                -19,
                51
            ],
            [
                -88,
                143
            ],
            [
                -64,
                88
            ],
            [
                -22,
                82
            ],
            [
                -1,
                69
            ],
            [
                -16,
                80
            ],
            [
                75,
                109
            ],
            [
                54,
                122
            ],
            [
                34,
                153
            ],
            [
                23,
                55
            ],
            [
                -11,
                130
            ],
            [
                -46,
                110
            ],
            [
                -106,
                68
            ],
            [
                -82,
                27
            ],
            [
                -71,
                2
            ],
            [
                -10,
                78
            ],
            [
                0,
                133
            ],
            [
                18,
                142
            ],
            [
                108,
                104
            ],
            [
                75,
                101
            ],
            [
                -33,
                94
            ],
            [
                27,
                54
            ],
            [
                40,
                133
            ],
            [
                -29,
                122
            ],
            [
                -11,
                109
            ],
            [
                -27,
                76
            ]
        ],
        [
            [
                30393,
                39208
            ],
            [
                -29,
                36
            ],
            [
                -38,
                -4
            ],
            [
                -42,
                -55
            ],
            [
                -23,
                34
            ],
            [
                -40,
                -28
            ],
            [
                -45,
                -1
            ],
            [
                -45,
                -78
            ],
            [
                -81,
                15
            ],
            [
                -70,
                -63
            ],
            [
                -35,
                35
            ],
            [
                -56,
                120
            ],
            [
                -68,
                109
            ],
            [
                0,
                78
            ],
            [
                -35,
                64
            ],
            [
                -53,
                35
            ],
            [
                -42,
                72
            ],
            [
                -30,
                79
            ],
            [
                -125,
                150
            ],
            [
                -71,
                113
            ],
            [
                -27,
                86
            ],
            [
                -90,
                63
            ],
            [
                9,
                70
            ],
            [
                34,
                23
            ],
            [
                -51,
                75
            ],
            [
                17,
                105
            ],
            [
                30,
                23
            ],
            [
                0,
                86
            ],
            [
                107,
                166
            ],
            [
                9,
                78
            ],
            [
                40,
                67
            ],
            [
                -57,
                4
            ],
            [
                -16,
                38
            ],
            [
                17,
                160
            ],
            [
                -2,
                68
            ],
            [
                19,
                86
            ],
            [
                60,
                52
            ],
            [
                26,
                -1
            ],
            [
                69,
                57
            ],
            [
                16,
                48
            ],
            [
                95,
                61
            ],
            [
                38,
                70
            ],
            [
                61,
                55
            ],
            [
                78,
                3
            ],
            [
                76,
                143
            ],
            [
                79,
                38
            ],
            [
                17,
                52
            ],
            [
                -52,
                104
            ],
            [
                70,
                65
            ],
            [
                34,
                90
            ],
            [
                73,
                103
            ],
            [
                75,
                152
            ],
            [
                21,
                23
            ],
            [
                -45,
                59
            ],
            [
                23,
                55
            ],
            [
                -58,
                124
            ],
            [
                -41,
                43
            ],
            [
                -87,
                60
            ],
            [
                -69,
                8
            ],
            [
                -126,
                -47
            ],
            [
                -14,
                -22
            ],
            [
                -105,
                35
            ],
            [
                10,
                155
            ],
            [
                22,
                53
            ],
            [
                -94,
                62
            ],
            [
                -25,
                -3
            ],
            [
                -123,
                47
            ],
            [
                -54,
                56
            ],
            [
                -10,
                65
            ],
            [
                9,
                61
            ],
            [
                -16,
                41
            ],
            [
                44,
                93
            ],
            [
                -104,
                96
            ],
            [
                4,
                17
            ],
            [
                -113,
                119
            ],
            [
                12,
                38
            ],
            [
                -13,
                66
            ],
            [
                -33,
                48
            ],
            [
                35,
                62
            ],
            [
                23,
                89
            ],
            [
                -19,
                29
            ],
            [
                8,
                58
            ],
            [
                31,
                40
            ],
            [
                -84,
                69
            ],
            [
                -51,
                10
            ],
            [
                -103,
                51
            ],
            [
                -23,
                42
            ],
            [
                5,
                79
            ],
            [
                -31,
                34
            ],
            [
                -72,
                -4
            ],
            [
                -41,
                39
            ],
            [
                -26,
                69
            ],
            [
                -42,
                56
            ],
            [
                -88,
                47
            ],
            [
                -29,
                109
            ],
            [
                -46,
                59
            ],
            [
                7,
                28
            ],
            [
                -25,
                92
            ],
            [
                216,
                -18
            ],
            [
                102,
                -50
            ],
            [
                41,
                -10
            ],
            [
                71,
                17
            ],
            [
                119,
                -36
            ],
            [
                127,
                75
            ],
            [
                88,
                21
            ],
            [
                88,
                -25
            ],
            [
                48,
                7
            ],
            [
                135,
                -33
            ],
            [
                63,
                36
            ],
            [
                107,
                2
            ],
            [
                42,
                -19
            ],
            [
                49,
                5
            ],
            [
                107,
                40
            ],
            [
                21,
                50
            ],
            [
                6,
                66
            ],
            [
                48,
                99
            ],
            [
                -16,
                49
            ],
            [
                18,
                80
            ],
            [
                27,
                33
            ],
            [
                -26,
                95
            ],
            [
                -1,
                46
            ],
            [
                45,
                56
            ],
            [
                33,
                -5
            ],
            [
                62,
                54
            ],
            [
                11,
                51
            ],
            [
                46,
                25
            ],
            [
                29,
                44
            ],
            [
                12,
                115
            ],
            [
                -20,
                82
            ],
            [
                57,
                60
            ],
            [
                -3,
                146
            ],
            [
                29,
                48
            ],
            [
                4,
                117
            ],
            [
                -18,
                66
            ],
            [
                -73,
                91
            ],
            [
                -27,
                7
            ],
            [
                -8,
                70
            ],
            [
                -90,
                106
            ],
            [
                -122,
                13
            ],
            [
                -43,
                61
            ],
            [
                -4,
                152
            ],
            [
                -36,
                28
            ],
            [
                25,
                44
            ],
            [
                22,
                110
            ],
            [
                40,
                37
            ],
            [
                51,
                19
            ],
            [
                45,
                -7
            ],
            [
                29,
                70
            ],
            [
                69,
                3
            ],
            [
                51,
                -17
            ],
            [
                106,
                39
            ],
            [
                9,
                23
            ]
        ],
        [
            [
                25009,
                54763
            ],
            [
                -31,
                -107
            ],
            [
                -38,
                28
            ],
            [
                42,
                64
            ],
            [
                27,
                15
            ]
        ],
        [
            [
                25130,
                55403
            ],
            [
                26,
                -39
            ],
            [
                14,
                -67
            ],
            [
                -19,
                -59
            ],
            [
                -37,
                13
            ],
            [
                17,
                63
            ],
            [
                -31,
                64
            ],
            [
                30,
                25
            ]
        ],
        [
            [
                30066,
                58741
            ],
            [
                -25,
                -2
            ],
            [
                -77,
                -75
            ],
            [
                -34,
                -100
            ],
            [
                -73,
                -6
            ],
            [
                -33,
                20
            ],
            [
                -84,
                -1
            ],
            [
                -10,
                -59
            ],
            [
                22,
                -41
            ],
            [
                -27,
                -57
            ],
            [
                60,
                -50
            ],
            [
                47,
                32
            ],
            [
                31,
                -40
            ],
            [
                -14,
                -92
            ],
            [
                19,
                -59
            ],
            [
                29,
                -21
            ],
            [
                48,
                -91
            ],
            [
                87,
                -9
            ],
            [
                -17,
                -104
            ],
            [
                17,
                -80
            ],
            [
                50,
                -68
            ],
            [
                34,
                -11
            ],
            [
                38,
                -56
            ],
            [
                91,
                -41
            ],
            [
                42,
                8
            ],
            [
                16,
                -64
            ],
            [
                -42,
                -75
            ],
            [
                19,
                -64
            ],
            [
                44,
                -52
            ],
            [
                43,
                -14
            ],
            [
                59,
                34
            ],
            [
                130,
                145
            ],
            [
                88,
                -51
            ],
            [
                47,
                -85
            ],
            [
                -16,
                -75
            ],
            [
                -102,
                -82
            ],
            [
                -16,
                -36
            ],
            [
                -52,
                -3
            ],
            [
                -68,
                -63
            ],
            [
                -21,
                31
            ],
            [
                -77,
                -21
            ],
            [
                -39,
                -55
            ],
            [
                8,
                -63
            ],
            [
                -16,
                -43
            ],
            [
                -70,
                -24
            ],
            [
                54,
                -111
            ],
            [
                51,
                -1
            ],
            [
                -22,
                113
            ],
            [
                48,
                22
            ],
            [
                16,
                -78
            ],
            [
                82,
                -79
            ],
            [
                81,
                4
            ],
            [
                120,
                -124
            ],
            [
                4,
                -62
            ],
            [
                -20,
                -48
            ],
            [
                48,
                -49
            ]
        ],
        [
            [
                30253,
                53171
            ],
            [
                -42,
                -37
            ],
            [
                -20,
                -46
            ],
            [
                -128,
                -5
            ],
            [
                -63,
                10
            ],
            [
                -29,
                80
            ],
            [
                -85,
                49
            ],
            [
                -61,
                15
            ],
            [
                -62,
                -42
            ],
            [
                -42,
                0
            ],
            [
                -18,
                -37
            ],
            [
                12,
                -65
            ],
            [
                -5,
                -70
            ],
            [
                28,
                -52
            ],
            [
                -62,
                -145
            ],
            [
                -32,
                1
            ],
            [
                -67,
                -48
            ],
            [
                -67,
                -5
            ],
            [
                -70,
                -51
            ],
            [
                -47,
                -2
            ],
            [
                -38,
                -33
            ],
            [
                -70,
                6
            ],
            [
                -42,
                -14
            ],
            [
                -20,
                46
            ],
            [
                -70,
                -39
            ],
            [
                -51,
                -95
            ],
            [
                -59,
                17
            ],
            [
                5,
                42
            ],
            [
                -41,
                25
            ],
            [
                -24,
                95
            ],
            [
                -88,
                -14
            ],
            [
                -17,
                37
            ],
            [
                -59,
                -34
            ],
            [
                20,
                -42
            ],
            [
                -40,
                -32
            ],
            [
                -144,
                -46
            ],
            [
                -68,
                13
            ],
            [
                -34,
                50
            ],
            [
                76,
                119
            ],
            [
                -191,
                9
            ],
            [
                -136,
                97
            ],
            [
                -79,
                -56
            ],
            [
                -81,
                25
            ],
            [
                -41,
                -45
            ],
            [
                -65,
                -18
            ],
            [
                -116,
                -3
            ],
            [
                9,
                -56
            ],
            [
                -66,
                -27
            ],
            [
                -59,
                27
            ],
            [
                10,
                85
            ],
            [
                -9,
                140
            ],
            [
                -50,
                1
            ],
            [
                -10,
                -73
            ],
            [
                -31,
                -95
            ],
            [
                -74,
                -40
            ],
            [
                -70,
                16
            ],
            [
                -38,
                -29
            ],
            [
                -15,
                -49
            ],
            [
                -50,
                -77
            ],
            [
                -77,
                -31
            ],
            [
                -45,
                -35
            ],
            [
                -98,
                -28
            ],
            [
                -29,
                13
            ],
            [
                -71,
                -10
            ],
            [
                -92,
                34
            ],
            [
                9,
                159
            ],
            [
                -21,
                58
            ],
            [
                -65,
                -1
            ],
            [
                -60,
                83
            ],
            [
                -4,
                53
            ],
            [
                52,
                98
            ],
            [
                62,
                58
            ],
            [
                32,
                102
            ],
            [
                60,
                23
            ],
            [
                29,
                55
            ],
            [
                41,
                5
            ],
            [
                79,
                43
            ],
            [
                4,
                114
            ],
            [
                -33,
                93
            ],
            [
                -60,
                37
            ],
            [
                -52,
                -24
            ],
            [
                -32,
                13
            ],
            [
                -56,
                -45
            ],
            [
                -42,
                20
            ],
            [
                0,
                95
            ],
            [
                -25,
                185
            ],
            [
                -83,
                -57
            ],
            [
                -75,
                15
            ],
            [
                -26,
                -59
            ],
            [
                -28,
                12
            ],
            [
                -82,
                -75
            ],
            [
                -54,
                -13
            ],
            [
                -22,
                -61
            ],
            [
                -67,
                23
            ],
            [
                -39,
                -7
            ],
            [
                -29,
                -44
            ],
            [
                -62,
                15
            ],
            [
                -66,
                -30
            ],
            [
                -60,
                38
            ],
            [
                -130,
                -16
            ],
            [
                -51,
                5
            ],
            [
                -20,
                -59
            ],
            [
                -58,
                -36
            ],
            [
                -105,
                -6
            ],
            [
                -26,
                17
            ],
            [
                -112,
                -35
            ],
            [
                -61,
                -80
            ],
            [
                -6,
                -84
            ],
            [
                -43,
                -42
            ],
            [
                -105,
                -44
            ],
            [
                -81,
                -58
            ],
            [
                -4,
                -79
            ],
            [
                -24,
                -37
            ],
            [
                -80,
                -59
            ],
            [
                -88,
                -22
            ],
            [
                -88,
                -127
            ],
            [
                -60,
                -26
            ],
            [
                -24,
                40
            ],
            [
                19,
                70
            ],
            [
                -27,
                60
            ],
            [
                -7,
                259
            ],
            [
                10,
                79
            ],
            [
                -4,
                149
            ],
            [
                -33,
                75
            ],
            [
                4,
                119
            ],
            [
                -12,
                48
            ],
            [
                60,
                -5
            ],
            [
                41,
                38
            ],
            [
                83,
                -35
            ],
            [
                43,
                86
            ],
            [
                -34,
                47
            ],
            [
                28,
                49
            ],
            [
                24,
                109
            ],
            [
                80,
                47
            ],
            [
                7,
                62
            ],
            [
                40,
                43
            ],
            [
                39,
                -18
            ],
            [
                40,
                51
            ],
            [
                51,
                1
            ],
            [
                33,
                71
            ],
            [
                70,
                17
            ],
            [
                43,
                77
            ],
            [
                39,
                21
            ],
            [
                49,
                -21
            ],
            [
                37,
                37
            ],
            [
                -19,
                53
            ],
            [
                27,
                70
            ],
            [
                -1,
                67
            ],
            [
                -42,
                15
            ],
            [
                -39,
                -51
            ],
            [
                17,
                -36
            ],
            [
                -53,
                -27
            ],
            [
                -18,
                -33
            ],
            [
                27,
                -47
            ],
            [
                -60,
                -26
            ],
            [
                -49,
                -45
            ],
            [
                -54,
                -1
            ],
            [
                -50,
                30
            ],
            [
                -14,
                -50
            ],
            [
                -76,
                -53
            ],
            [
                -28,
                16
            ],
            [
                -51,
                -63
            ],
            [
                -52,
                53
            ],
            [
                -32,
                -34
            ],
            [
                -83,
                39
            ],
            [
                -26,
                60
            ],
            [
                18,
                86
            ],
            [
                103,
                -100
            ],
            [
                -9,
                83
            ],
            [
                13,
                38
            ],
            [
                -44,
                129
            ],
            [
                94,
                -12
            ],
            [
                21,
                -42
            ],
            [
                109,
                56
            ],
            [
                20,
                94
            ],
            [
                42,
                33
            ],
            [
                50,
                68
            ],
            [
                36,
                4
            ],
            [
                43,
                41
            ],
            [
                22,
                51
            ],
            [
                -34,
                13
            ],
            [
                -10,
                52
            ],
            [
                -41,
                -15
            ],
            [
                -158,
                -150
            ],
            [
                -134,
                28
            ],
            [
                -61,
                -59
            ],
            [
                -63,
                60
            ],
            [
                -57,
                79
            ],
            [
                23,
                40
            ],
            [
                -29,
                79
            ],
            [
                -85,
                30
            ],
            [
                -29,
                -44
            ],
            [
                -57,
                51
            ],
            [
                18,
                53
            ],
            [
                83,
                -16
            ],
            [
                41,
                41
            ],
            [
                7,
                38
            ],
            [
                83,
                -3
            ],
            [
                9,
                -90
            ],
            [
                -47,
                -44
            ],
            [
                10,
                -41
            ],
            [
                88,
                38
            ],
            [
                45,
                -20
            ],
            [
                -29,
                118
            ],
            [
                59,
                48
            ],
            [
                -17,
                66
            ],
            [
                -32,
                19
            ],
            [
                1,
                78
            ],
            [
                -26,
                41
            ],
            [
                32,
                94
            ],
            [
                52,
                9
            ],
            [
                107,
                77
            ],
            [
                -32,
                46
            ],
            [
                57,
                94
            ],
            [
                -19,
                47
            ],
            [
                -84,
                -55
            ],
            [
                -77,
                49
            ],
            [
                24,
                37
            ],
            [
                -40,
                23
            ],
            [
                -60,
                -18
            ],
            [
                -17,
                -74
            ],
            [
                -14,
                -130
            ],
            [
                -68,
                51
            ],
            [
                29,
                62
            ],
            [
                -60,
                14
            ],
            [
                -60,
                -85
            ],
            [
                -42,
                -40
            ],
            [
                46,
                -76
            ],
            [
                -90,
                -6
            ],
            [
                -30,
                -64
            ],
            [
                -48,
                3
            ],
            [
                -3,
                -93
            ],
            [
                -25,
                -38
            ],
            [
                -67,
                -55
            ],
            [
                -75,
                16
            ],
            [
                30,
                125
            ],
            [
                -50,
                70
            ],
            [
                -40,
                0
            ],
            [
                -41,
                -34
            ],
            [
                4,
                114
            ],
            [
                41,
                27
            ],
            [
                10,
                49
            ],
            [
                58,
                89
            ],
            [
                -6,
                106
            ],
            [
                30,
                34
            ],
            [
                -5,
                59
            ],
            [
                26,
                59
            ],
            [
                29,
                -5
            ],
            [
                12,
                66
            ],
            [
                39,
                8
            ],
            [
                13,
                45
            ],
            [
                88,
                15
            ],
            [
                74,
                139
            ],
            [
                53,
                30
            ],
            [
                44,
                -28
            ],
            [
                -7,
                113
            ],
            [
                -52,
                -62
            ],
            [
                -55,
                21
            ],
            [
                -47,
                -81
            ],
            [
                -73,
                18
            ],
            [
                -24,
                -31
            ],
            [
                -72,
                18
            ],
            [
                11,
                48
            ],
            [
                -144,
                -47
            ],
            [
                47,
                -44
            ],
            [
                -29,
                -44
            ],
            [
                -59,
                -23
            ],
            [
                -27,
                -56
            ],
            [
                -33,
                63
            ],
            [
                -42,
                -9
            ],
            [
                1,
                59
            ],
            [
                -62,
                99
            ],
            [
                76,
                65
            ],
            [
                13,
                42
            ],
            [
                -22,
                81
            ],
            [
                -53,
                1
            ],
            [
                -33,
                56
            ],
            [
                -5,
                79
            ],
            [
                60,
                85
            ],
            [
                -96,
                26
            ],
            [
                -31,
                118
            ],
            [
                -52,
                -100
            ],
            [
                -45,
                2
            ],
            [
                -23,
                90
            ],
            [
                -126,
                -95
            ],
            [
                15,
                -48
            ],
            [
                -30,
                -88
            ],
            [
                -32,
                49
            ],
            [
                11,
                39
            ],
            [
                -15,
                59
            ],
            [
                -39,
                9
            ],
            [
                36,
                124
            ],
            [
                65,
                63
            ],
            [
                7,
                108
            ],
            [
                -66,
                67
            ],
            [
                -7,
                103
            ],
            [
                68,
                17
            ],
            [
                25,
                73
            ],
            [
                -16,
                77
            ],
            [
                82,
                -6
            ],
            [
                30,
                49
            ],
            [
                133,
                4
            ],
            [
                19,
                80
            ],
            [
                -22,
                17
            ],
            [
                -74,
                -26
            ],
            [
                -50,
                86
            ],
            [
                90,
                83
            ],
            [
                13,
                59
            ],
            [
                52,
                -6
            ],
            [
                5,
                48
            ],
            [
                46,
                -15
            ],
            [
                108,
                5
            ],
            [
                44,
                -44
            ],
            [
                51,
                6
            ],
            [
                51,
                29
            ],
            [
                79,
                112
            ],
            [
                58,
                -22
            ],
            [
                16,
                39
            ],
            [
                42,
                -9
            ],
            [
                48,
                24
            ],
            [
                27,
                41
            ],
            [
                -57,
                74
            ],
            [
                -49,
                16
            ],
            [
                -4,
                61
            ],
            [
                82,
                42
            ],
            [
                66,
                -13
            ],
            [
                60,
                17
            ],
            [
                -19,
                94
            ],
            [
                175,
                11
            ],
            [
                6,
                61
            ],
            [
                49,
                3
            ],
            [
                2,
                -40
            ],
            [
                61,
                -10
            ],
            [
                10,
                -49
            ],
            [
                74,
                -49
            ],
            [
                34,
                7
            ],
            [
                55,
                -31
            ],
            [
                65,
                -15
            ],
            [
                57,
                7
            ],
            [
                206,
                95
            ],
            [
                84,
                16
            ],
            [
                114,
                -38
            ],
            [
                105,
                56
            ],
            [
                46,
                130
            ],
            [
                48,
                -26
            ],
            [
                97,
                104
            ],
            [
                45,
                -18
            ],
            [
                60,
                48
            ],
            [
                39,
                -91
            ],
            [
                -18,
                -28
            ],
            [
                79,
                -65
            ],
            [
                41,
                28
            ],
            [
                -6,
                35
            ],
            [
                32,
                73
            ],
            [
                -35,
                70
            ],
            [
                48,
                21
            ],
            [
                97,
                -16
            ],
            [
                5,
                -48
            ],
            [
                84,
                -8
            ],
            [
                9,
                -40
            ],
            [
                40,
                -32
            ],
            [
                -18,
                -38
            ],
            [
                79,
                -62
            ],
            [
                52,
                11
            ],
            [
                -29,
                59
            ],
            [
                -16,
                117
            ],
            [
                39,
                82
            ],
            [
                46,
                17
            ],
            [
                -24,
                48
            ],
            [
                -40,
                -23
            ],
            [
                -67,
                36
            ],
            [
                -26,
                -39
            ],
            [
                -35,
                10
            ],
            [
                -51,
                53
            ],
            [
                -50,
                -3
            ],
            [
                -45,
                24
            ],
            [
                13,
                46
            ],
            [
                95,
                33
            ],
            [
                113,
                -22
            ],
            [
                88,
                11
            ],
            [
                34,
                53
            ],
            [
                50,
                20
            ],
            [
                -19,
                52
            ],
            [
                -52,
                -21
            ],
            [
                -12,
                -39
            ],
            [
                -133,
                -4
            ],
            [
                -46,
                -32
            ],
            [
                -87,
                -27
            ],
            [
                -55,
                32
            ],
            [
                -70,
                4
            ],
            [
                49,
                49
            ],
            [
                21,
                64
            ],
            [
                -32,
                23
            ],
            [
                21,
                69
            ],
            [
                50,
                -5
            ],
            [
                15,
                53
            ],
            [
                -73,
                75
            ],
            [
                45,
                38
            ],
            [
                28,
                -44
            ],
            [
                46,
                10
            ],
            [
                48,
                -15
            ],
            [
                128,
                99
            ],
            [
                35,
                49
            ],
            [
                14,
                58
            ],
            [
                99,
                8
            ],
            [
                58,
                74
            ],
            [
                86,
                28
            ],
            [
                -8,
                29
            ],
            [
                68,
                43
            ],
            [
                54,
                83
            ],
            [
                12,
                83
            ],
            [
                60,
                32
            ],
            [
                64,
                -7
            ],
            [
                28,
                -29
            ],
            [
                115,
                63
            ],
            [
                115,
                86
            ],
            [
                33,
                62
            ],
            [
                82,
                19
            ],
            [
                50,
                -5
            ],
            [
                -10,
                -89
            ],
            [
                -19,
                -38
            ],
            [
                45,
                -19
            ],
            [
                8,
                -63
            ],
            [
                -59,
                -9
            ],
            [
                -22,
                -44
            ],
            [
                38,
                -108
            ],
            [
                63,
                37
            ],
            [
                -44,
                30
            ],
            [
                -22,
                54
            ],
            [
                29,
                32
            ],
            [
                69,
                -7
            ],
            [
                10,
                32
            ],
            [
                56,
                13
            ],
            [
                26,
                58
            ],
            [
                57,
                6
            ],
            [
                131,
                62
            ],
            [
                37,
                57
            ],
            [
                67,
                35
            ],
            [
                39,
                45
            ],
            [
                36,
                -38
            ],
            [
                -38,
                -29
            ],
            [
                -66,
                -123
            ],
            [
                49,
                -25
            ],
            [
                33,
                69
            ],
            [
                44,
                -9
            ],
            [
                44,
                27
            ],
            [
                23,
                -28
            ],
            [
                13,
                -107
            ],
            [
                49,
                6
            ],
            [
                -21,
                -65
            ],
            [
                7,
                -106
            ],
            [
                95,
                76
            ],
            [
                -27,
                54
            ],
            [
                96,
                37
            ],
            [
                6,
                57
            ],
            [
                59,
                14
            ],
            [
                43,
                -38
            ],
            [
                57,
                5
            ],
            [
                55,
                -22
            ],
            [
                -25,
                -36
            ],
            [
                62,
                -51
            ],
            [
                132,
                -32
            ],
            [
                32,
                -36
            ],
            [
                73,
                -12
            ],
            [
                35,
                -24
            ],
            [
                11,
                -61
            ],
            [
                117,
                -138
            ],
            [
                35,
                -83
            ],
            [
                36,
                -10
            ],
            [
                168,
                -98
            ],
            [
                102,
                -8
            ],
            [
                33,
                -25
            ],
            [
                61,
                -1
            ],
            [
                102,
                22
            ],
            [
                50,
                -22
            ],
            [
                136,
                5
            ],
            [
                12,
                -92
            ],
            [
                -34,
                -45
            ],
            [
                -12,
                -61
            ],
            [
                4,
                -108
            ]
        ],
        [
            [
                53000,
                41370
            ],
            [
                20,
                -1
            ],
            [
                22,
                -87
            ],
            [
                9,
                -85
            ],
            [
                19,
                -40
            ],
            [
                88,
                -77
            ],
            [
                -1,
                -34
            ],
            [
                59,
                -64
            ],
            [
                80,
                -49
            ],
            [
                85,
                70
            ],
            [
                25,
                -2
            ],
            [
                32,
                -53
            ],
            [
                6,
                -57
            ],
            [
                -37,
                -45
            ],
            [
                -131,
                12
            ],
            [
                -36,
                47
            ],
            [
                -92,
                60
            ],
            [
                -79,
                10
            ],
            [
                -29,
                -26
            ],
            [
                -69,
                -103
            ],
            [
                -42,
                -34
            ],
            [
                -31,
                23
            ],
            [
                13,
                68
            ],
            [
                -21,
                102
            ],
            [
                24,
                9
            ],
            [
                -7,
                64
            ],
            [
                20,
                61
            ],
            [
                76,
                17
            ],
            [
                25,
                43
            ],
            [
                9,
                72
            ],
            [
                -37,
                99
            ]
        ],
        [
            [
                53305,
                42585
            ],
            [
                68,
                -96
            ],
            [
                25,
                19
            ],
            [
                101,
                -30
            ],
            [
                -46,
                -95
            ],
            [
                54,
                -105
            ],
            [
                -70,
                -97
            ],
            [
                -5,
                -67
            ],
            [
                -78,
                1
            ],
            [
                -36,
                -43
            ],
            [
                -25,
                -84
            ],
            [
                1,
                -46
            ],
            [
                -51,
                -8
            ],
            [
                -14,
                -40
            ],
            [
                -45,
                -22
            ],
            [
                -25,
                -42
            ],
            [
                -78,
                -19
            ],
            [
                -32,
                -25
            ],
            [
                -43,
                5
            ],
            [
                -54,
                -109
            ],
            [
                13,
                -61
            ],
            [
                -15,
                -38
            ],
            [
                -57,
                -53
            ],
            [
                -62,
                105
            ],
            [
                -38,
                29
            ],
            [
                -54,
                -32
            ],
            [
                -45,
                1
            ],
            [
                -23,
                44
            ],
            [
                -87,
                12
            ],
            [
                -63,
                -45
            ],
            [
                -49,
                31
            ],
            [
                -3,
                43
            ],
            [
                -32,
                67
            ],
            [
                62,
                133
            ],
            [
                -57,
                72
            ],
            [
                42,
                58
            ],
            [
                42,
                -28
            ],
            [
                49,
                5
            ],
            [
                96,
                35
            ],
            [
                -43,
                79
            ],
            [
                36,
                46
            ],
            [
                -18,
                60
            ],
            [
                57,
                64
            ],
            [
                84,
                34
            ],
            [
                45,
                76
            ],
            [
                48,
                -40
            ],
            [
                24,
                53
            ],
            [
                82,
                2
            ],
            [
                33,
                53
            ],
            [
                30,
                -38
            ],
            [
                38,
                56
            ],
            [
                56,
                29
            ],
            [
                63,
                -20
            ],
            [
                25,
                41
            ],
            [
                74,
                30
            ]
        ],
        [
            [
                57146,
                42759
            ],
            [
                63,
                -29
            ],
            [
                -60,
                -84
            ],
            [
                3,
                -37
            ],
            [
                -81,
                23
            ],
            [
                -24,
                23
            ],
            [
                23,
                70
            ],
            [
                36,
                -2
            ],
            [
                40,
                36
            ]
        ],
        [
            [
                57851,
                45714
            ],
            [
                -31,
                -72
            ],
            [
                -63,
                -22
            ],
            [
                -69,
                -52
            ],
            [
                -33,
                17
            ],
            [
                -64,
                -64
            ],
            [
                -84,
                -4
            ],
            [
                -18,
                -83
            ],
            [
                25,
                -70
            ],
            [
                53,
                -39
            ],
            [
                61,
                44
            ],
            [
                44,
                -11
            ],
            [
                82,
                56
            ],
            [
                34,
                39
            ],
            [
                39,
                -8
            ],
            [
                -51,
                -68
            ],
            [
                29,
                -48
            ],
            [
                -60,
                -78
            ],
            [
                -95,
                -11
            ],
            [
                -33,
                20
            ],
            [
                -26,
                -66
            ],
            [
                9,
                -75
            ],
            [
                39,
                -72
            ],
            [
                46,
                -53
            ],
            [
                105,
                -55
            ],
            [
                128,
                -83
            ],
            [
                132,
                36
            ],
            [
                56,
                51
            ],
            [
                61,
                21
            ],
            [
                15,
                88
            ],
            [
                42,
                16
            ],
            [
                78,
                -84
            ],
            [
                67,
                -6
            ],
            [
                76,
                -71
            ],
            [
                69,
                -1
            ],
            [
                12,
                -24
            ],
            [
                -26,
                -62
            ],
            [
                54,
                0
            ],
            [
                15,
                -25
            ],
            [
                -59,
                -35
            ],
            [
                5,
                -59
            ],
            [
                -13,
                -74
            ],
            [
                7,
                -52
            ],
            [
                -44,
                -3
            ],
            [
                -15,
                -83
            ],
            [
                -84,
                13
            ],
            [
                -30,
                -63
            ],
            [
                -20,
                -76
            ],
            [
                26,
                -84
            ],
            [
                -42,
                3
            ],
            [
                -9,
                -62
            ],
            [
                -133,
                -122
            ],
            [
                -64,
                -84
            ],
            [
                -49,
                -125
            ],
            [
                -17,
                -78
            ],
            [
                -9,
                -125
            ],
            [
                -56,
                -55
            ],
            [
                -26,
                -87
            ],
            [
                -40,
                -68
            ],
            [
                -99,
                -29
            ],
            [
                -19,
                -63
            ],
            [
                -46,
                -32
            ],
            [
                -74,
                -1
            ],
            [
                -101,
                -93
            ],
            [
                -85,
                -119
            ],
            [
                -64,
                -11
            ],
            [
                -21,
                54
            ],
            [
                -33,
                11
            ],
            [
                -34,
                48
            ],
            [
                -20,
                61
            ],
            [
                -65,
                13
            ],
            [
                12,
                84
            ],
            [
                -35,
                51
            ],
            [
                -54,
                43
            ],
            [
                -77,
                2
            ],
            [
                -57,
                -28
            ],
            [
                -73,
                34
            ],
            [
                -100,
                -1
            ],
            [
                -35,
                -35
            ],
            [
                -72,
                39
            ],
            [
                -39,
                -17
            ],
            [
                -58,
                83
            ],
            [
                -62,
                60
            ],
            [
                -17,
                62
            ],
            [
                22,
                73
            ],
            [
                -21,
                62
            ],
            [
                -48,
                87
            ],
            [
                35,
                27
            ],
            [
                44,
                76
            ],
            [
                -62,
                95
            ],
            [
                -37,
                7
            ],
            [
                -68,
                75
            ],
            [
                -68,
                35
            ],
            [
                -96,
                13
            ],
            [
                -15,
                -80
            ],
            [
                -60,
                24
            ],
            [
                -27,
                -57
            ],
            [
                -87,
                -14
            ],
            [
                -49,
                -26
            ],
            [
                -19,
                -141
            ],
            [
                -34,
                -54
            ],
            [
                6,
                -52
            ],
            [
                -63,
                11
            ],
            [
                -9,
                30
            ],
            [
                -53,
                31
            ],
            [
                -51,
                80
            ],
            [
                21,
                76
            ],
            [
                -27,
                58
            ],
            [
                -54,
                -30
            ],
            [
                -36,
                35
            ],
            [
                -158,
                -19
            ],
            [
                19,
                47
            ],
            [
                -14,
                39
            ],
            [
                -54,
                37
            ],
            [
                16,
                31
            ],
            [
                -22,
                57
            ],
            [
                65,
                99
            ],
            [
                45,
                -2
            ],
            [
                81,
                58
            ],
            [
                16,
                25
            ],
            [
                112,
                58
            ],
            [
                85,
                105
            ],
            [
                91,
                79
            ],
            [
                61,
                -9
            ],
            [
                107,
                74
            ],
            [
                28,
                52
            ],
            [
                85,
                92
            ],
            [
                91,
                67
            ],
            [
                8,
                56
            ],
            [
                60,
                17
            ],
            [
                44,
                56
            ],
            [
                87,
                76
            ],
            [
                46,
                -7
            ],
            [
                49,
                52
            ],
            [
                11,
                44
            ],
            [
                73,
                -9
            ],
            [
                94,
                43
            ],
            [
                38,
                36
            ],
            [
                59,
                17
            ],
            [
                76,
                72
            ],
            [
                79,
                15
            ],
            [
                51,
                65
            ],
            [
                44,
                -35
            ],
            [
                52,
                -4
            ],
            [
                21,
                31
            ],
            [
                79,
                59
            ],
            [
                86,
                -45
            ],
            [
                65,
                12
            ],
            [
                40,
                40
            ],
            [
                76,
                -13
            ],
            [
                65,
                80
            ],
            [
                79,
                30
            ],
            [
                14,
                -37
            ],
            [
                88,
                40
            ]
        ],
        [
            [
                60200,
                46191
            ],
            [
                36,
                -6
            ],
            [
                13,
                -117
            ],
            [
                93,
                -3
            ],
            [
                8,
                39
            ],
            [
                81,
                -18
            ],
            [
                15,
                -139
            ],
            [
                84,
                -11
            ],
            [
                -5,
                -48
            ],
            [
                46,
                -36
            ],
            [
                98,
                -4
            ],
            [
                12,
                -105
            ],
            [
                44,
                -120
            ],
            [
                94,
                -218
            ],
            [
                -15,
                -161
            ],
            [
                -42,
                -76
            ],
            [
                -41,
                -23
            ],
            [
                -99,
                19
            ],
            [
                -17,
                25
            ],
            [
                -130,
                64
            ],
            [
                -96,
                82
            ],
            [
                -131,
                63
            ],
            [
                -57,
                74
            ],
            [
                -154,
                91
            ],
            [
                -181,
                49
            ],
            [
                -90,
                -12
            ],
            [
                -29,
                -28
            ],
            [
                -125,
                -11
            ],
            [
                -119,
                21
            ],
            [
                37,
                134
            ],
            [
                -24,
                125
            ],
            [
                -78,
                17
            ],
            [
                -5,
                72
            ],
            [
                40,
                19
            ],
            [
                34,
                90
            ],
            [
                76,
                19
            ],
            [
                76,
                -8
            ],
            [
                75,
                16
            ],
            [
                42,
                -21
            ],
            [
                21,
                35
            ],
            [
                56,
                -15
            ],
            [
                32,
                16
            ],
            [
                53,
                -37
            ],
            [
                42,
                27
            ],
            [
                108,
                22
            ],
            [
                109,
                -17
            ],
            [
                37,
                64
            ],
            [
                -24,
                51
            ]
        ],
        [
            [
                630,
                787
            ],
            [
                57,
                -21
            ],
            [
                57,
                -97
            ],
            [
                9,
                -55
            ],
            [
                -53,
                -63
            ],
            [
                -61,
                -156
            ],
            [
                -14,
                -56
            ],
            [
                -51,
                -1
            ],
            [
                -24,
                -35
            ],
            [
                -2,
                -92
            ],
            [
                -34,
                -97
            ],
            [
                -2,
                -78
            ],
            [
                -45,
                -36
            ],
            [
                -54,
                41
            ],
            [
                -57,
                86
            ],
            [
                -21,
                52
            ],
            [
                -94,
                41
            ],
            [
                -34,
                1
            ],
            [
                -99,
                27
            ],
            [
                -72,
                -3
            ],
            [
                -36,
                42
            ],
            [
                22,
                65
            ],
            [
                -2,
                96
            ],
            [
                51,
                51
            ],
            [
                37,
                -4
            ],
            [
                18,
                -40
            ],
            [
                121,
                -23
            ],
            [
                90,
                35
            ],
            [
                55,
                72
            ],
            [
                46,
                33
            ],
            [
                30,
                42
            ],
            [
                -8,
                72
            ],
            [
                47,
                18
            ],
            [
                37,
                54
            ],
            [
                86,
                29
            ]
        ],
        [
            [
                7420,
                2020
            ],
            [
                41,
                -23
            ],
            [
                -6,
                -89
            ],
            [
                -61,
                -73
            ],
            [
                49,
                -106
            ],
            [
                -13,
                -76
            ],
            [
                10,
                -28
            ],
            [
                -22,
                -55
            ],
            [
                40,
                -84
            ],
            [
                51,
                -28
            ],
            [
                3,
                -48
            ],
            [
                31,
                -72
            ],
            [
                -4,
                -74
            ],
            [
                -21,
                -18
            ],
            [
                15,
                -97
            ],
            [
                -18,
                -77
            ],
            [
                -3,
                -78
            ],
            [
                -21,
                -51
            ],
            [
                33,
                -57
            ],
            [
                -13,
                -72
            ],
            [
                -71,
                -33
            ],
            [
                -28,
                -66
            ],
            [
                5,
                -58
            ],
            [
                -29,
                -45
            ],
            [
                -85,
                -30
            ],
            [
                -86,
                -16
            ],
            [
                -38,
                -37
            ],
            [
                -29,
                4
            ],
            [
                -39,
                -43
            ],
            [
                -77,
                -29
            ],
            [
                -32,
                -98
            ],
            [
                -70,
                -3
            ],
            [
                -38,
                41
            ],
            [
                -49,
                0
            ],
            [
                -57,
                36
            ],
            [
                -91,
                4
            ],
            [
                -8,
                51
            ],
            [
                -102,
                74
            ],
            [
                -73,
                90
            ],
            [
                -34,
                4
            ],
            [
                -56,
                67
            ],
            [
                -12,
                47
            ],
            [
                -108,
                236
            ],
            [
                8,
                100
            ],
            [
                -13,
                135
            ],
            [
                27,
                47
            ],
            [
                27,
                121
            ],
            [
                73,
                13
            ],
            [
                76,
                92
            ],
            [
                56,
                35
            ],
            [
                6,
                30
            ],
            [
                44,
                33
            ],
            [
                32,
                79
            ],
            [
                -18,
                18
            ],
            [
                13,
                80
            ],
            [
                26,
                55
            ],
            [
                -18,
                79
            ],
            [
                62,
                53
            ],
            [
                38,
                -43
            ],
            [
                71,
                28
            ],
            [
                48,
                -13
            ],
            [
                35,
                -60
            ],
            [
                92,
                -11
            ],
            [
                96,
                12
            ],
            [
                46,
                31
            ],
            [
                27,
                -32
            ],
            [
                106,
                -40
            ],
            [
                23,
                -38
            ],
            [
                68,
                21
            ],
            [
                33,
                50
            ],
            [
                -34,
                79
            ],
            [
                66,
                56
            ]
        ],
        [
            [
                2439,
                2153
            ],
            [
                20,
                -47
            ],
            [
                138,
                -17
            ],
            [
                22,
                -11
            ],
            [
                27,
                -67
            ],
            [
                35,
                1
            ],
            [
                66,
                -68
            ],
            [
                73,
                -32
            ],
            [
                15,
                -67
            ],
            [
                30,
                -23
            ],
            [
                -5,
                -92
            ],
            [
                14,
                -33
            ],
            [
                -40,
                -29
            ],
            [
                -106,
                -119
            ],
            [
                -36,
                -68
            ],
            [
                -38,
                4
            ],
            [
                -47,
                -51
            ],
            [
                -205,
                10
            ],
            [
                -49,
                72
            ],
            [
                -76,
                49
            ],
            [
                -16,
                76
            ],
            [
                -35,
                13
            ],
            [
                -29,
                53
            ],
            [
                19,
                123
            ],
            [
                -14,
                65
            ],
            [
                32,
                55
            ],
            [
                -5,
                41
            ],
            [
                41,
                70
            ],
            [
                37,
                36
            ],
            [
                132,
                56
            ]
        ],
        [
            [
                5420,
                3530
            ],
            [
                56,
                -21
            ],
            [
                49,
                -100
            ],
            [
                -64,
                -133
            ],
            [
                -24,
                9
            ],
            [
                -91,
                -49
            ],
            [
                -65,
                -61
            ],
            [
                -68,
                -27
            ],
            [
                -30,
                -47
            ],
            [
                -2,
                -50
            ],
            [
                -56,
                -38
            ],
            [
                -92,
                -131
            ],
            [
                -60,
                -54
            ],
            [
                -15,
                9
            ],
            [
                -84,
                -83
            ],
            [
                -25,
                -112
            ],
            [
                24,
                -106
            ],
            [
                -5,
                -60
            ],
            [
                -59,
                -76
            ],
            [
                1,
                -45
            ],
            [
                -64,
                -138
            ],
            [
                -9,
                -45
            ],
            [
                -37,
                -71
            ],
            [
                -27,
                -170
            ],
            [
                18,
                -44
            ],
            [
                -99,
                -102
            ],
            [
                -45,
                -84
            ],
            [
                -59,
                -69
            ],
            [
                -11,
                -38
            ],
            [
                -40,
                -52
            ],
            [
                -115,
                -88
            ],
            [
                -87,
                -2
            ],
            [
                -135,
                -94
            ],
            [
                -54,
                19
            ],
            [
                -30,
                -38
            ],
            [
                -39,
                4
            ],
            [
                -43,
                41
            ],
            [
                5,
                67
            ],
            [
                -29,
                83
            ],
            [
                -56,
                18
            ],
            [
                10,
                60
            ],
            [
                -9,
                48
            ],
            [
                -88,
                91
            ],
            [
                -48,
                75
            ],
            [
                -47,
                103
            ],
            [
                -3,
                48
            ],
            [
                -40,
                37
            ],
            [
                -71,
                221
            ],
            [
                24,
                58
            ],
            [
                -8,
                35
            ],
            [
                -58,
                51
            ],
            [
                -6,
                37
            ],
            [
                -48,
                58
            ],
            [
                -67,
                129
            ],
            [
                -43,
                12
            ],
            [
                -4,
                44
            ],
            [
                51,
                40
            ],
            [
                119,
                41
            ],
            [
                30,
                44
            ],
            [
                45,
                20
            ],
            [
                54,
                -55
            ],
            [
                172,
                -27
            ],
            [
                24,
                34
            ],
            [
                34,
                -12
            ],
            [
                21,
                38
            ],
            [
                30,
                -15
            ],
            [
                73,
                29
            ],
            [
                15,
                30
            ],
            [
                62,
                -10
            ],
            [
                141,
                -6
            ],
            [
                78,
                37
            ],
            [
                49,
                53
            ],
            [
                112,
                0
            ],
            [
                53,
                80
            ],
            [
                48,
                2
            ],
            [
                3,
                46
            ],
            [
                54,
                29
            ],
            [
                -6,
                25
            ],
            [
                80,
                82
            ],
            [
                17,
                101
            ],
            [
                112,
                112
            ],
            [
                42,
                -4
            ],
            [
                84,
                48
            ],
            [
                9,
                64
            ],
            [
                41,
                -19
            ],
            [
                70,
                -1
            ],
            [
                15,
                21
            ],
            [
                110,
                -20
            ],
            [
                56,
                -21
            ],
            [
                79,
                24
            ],
            [
                34,
                32
            ],
            [
                61,
                29
            ]
        ],
        [
            [
                11568,
                4155
            ],
            [
                56,
                -39
            ],
            [
                -1,
                -30
            ],
            [
                61,
                -37
            ],
            [
                38,
                -136
            ],
            [
                4,
                -234
            ],
            [
                17,
                -142
            ],
            [
                -16,
                -32
            ],
            [
                11,
                -102
            ],
            [
                -52,
                -174
            ],
            [
                -53,
                -54
            ],
            [
                -12,
                -48
            ],
            [
                23,
                -45
            ],
            [
                6,
                -97
            ],
            [
                22,
                -125
            ],
            [
                -11,
                -61
            ],
            [
                -29,
                -36
            ],
            [
                -34,
                -95
            ],
            [
                -8,
                -71
            ],
            [
                -64,
                -127
            ],
            [
                16,
                -60
            ],
            [
                -36,
                -50
            ],
            [
                -22,
                -84
            ],
            [
                -48,
                -67
            ],
            [
                -33,
                -21
            ],
            [
                -108,
                0
            ],
            [
                -54,
                -67
            ],
            [
                -25,
                8
            ],
            [
                -70,
                -34
            ],
            [
                -79,
                -4
            ],
            [
                -104,
                -47
            ],
            [
                -36,
                10
            ],
            [
                -43,
                -29
            ],
            [
                -67,
                -24
            ],
            [
                -102,
                -17
            ],
            [
                -64,
                -45
            ],
            [
                -33,
                -75
            ],
            [
                -84,
                -122
            ],
            [
                -16,
                -41
            ],
            [
                -83,
                -96
            ],
            [
                -58,
                -85
            ],
            [
                -90,
                0
            ],
            [
                -133,
                41
            ],
            [
                -111,
                52
            ],
            [
                -82,
                18
            ],
            [
                -26,
                -11
            ],
            [
                -9,
                81
            ],
            [
                55,
                36
            ],
            [
                61,
                -17
            ],
            [
                167,
                36
            ],
            [
                81,
                42
            ],
            [
                112,
                75
            ],
            [
                135,
                134
            ],
            [
                107,
                117
            ],
            [
                34,
                59
            ],
            [
                18,
                329
            ],
            [
                25,
                73
            ],
            [
                42,
                28
            ],
            [
                58,
                103
            ],
            [
                9,
                152
            ],
            [
                21,
                76
            ],
            [
                70,
                101
            ],
            [
                73,
                76
            ],
            [
                30,
                102
            ],
            [
                43,
                87
            ],
            [
                14,
                82
            ],
            [
                89,
                152
            ],
            [
                -15,
                83
            ],
            [
                18,
                31
            ],
            [
                19,
                86
            ],
            [
                47,
                100
            ],
            [
                -15,
                29
            ],
            [
                -19,
                99
            ],
            [
                48,
                31
            ],
            [
                69,
                73
            ],
            [
                40,
                -6
            ],
            [
                109,
                67
            ],
            [
                50,
                -12
            ],
            [
                47,
                30
            ]
        ],
        [
            [
                11770,
                4191
            ],
            [
                6,
                -44
            ],
            [
                -13,
                -67
            ],
            [
                -48,
                9
            ],
            [
                -10,
                49
            ],
            [
                65,
                53
            ]
        ],
        [
            [
                651,
                4529
            ],
            [
                26,
                -2
            ],
            [
                42,
                -57
            ],
            [
                115,
                -34
            ],
            [
                101,
                23
            ],
            [
                18,
                13
            ],
            [
                85,
                -15
            ],
            [
                9,
                -48
            ],
            [
                39,
                -78
            ],
            [
                31,
                -134
            ],
            [
                42,
                -45
            ],
            [
                18,
                -86
            ],
            [
                -17,
                -48
            ],
            [
                -74,
                -87
            ],
            [
                -24,
                -74
            ],
            [
                18,
                -56
            ],
            [
                32,
                -151
            ],
            [
                -15,
                -33
            ],
            [
                1,
                -71
            ],
            [
                -12,
                -74
            ],
            [
                -34,
                -69
            ],
            [
                -42,
                -44
            ],
            [
                -25,
                -94
            ],
            [
                -46,
                -72
            ],
            [
                -51,
                -150
            ],
            [
                -20,
                -19
            ],
            [
                -46,
                27
            ],
            [
                -31,
                48
            ],
            [
                -23,
                103
            ],
            [
                4,
                78
            ],
            [
                -12,
                66
            ],
            [
                -34,
                56
            ],
            [
                -20,
                64
            ],
            [
                -41,
                66
            ],
            [
                -33,
                25
            ],
            [
                3,
                69
            ],
            [
                -43,
                104
            ],
            [
                -23,
                31
            ],
            [
                -52,
                116
            ],
            [
                -18,
                61
            ],
            [
                -16,
                112
            ],
            [
                -26,
                41
            ],
            [
                -49,
                32
            ],
            [
                0,
                94
            ],
            [
                15,
                44
            ],
            [
                72,
                67
            ],
            [
                7,
                29
            ],
            [
                81,
                111
            ],
            [
                58,
                28
            ],
            [
                10,
                33
            ]
        ],
        [
            [
                12697,
                5950
            ],
            [
                5,
                -44
            ],
            [
                78,
                -23
            ],
            [
                43,
                -54
            ],
            [
                12,
                -89
            ],
            [
                -24,
                -109
            ],
            [
                -48,
                -30
            ],
            [
                -52,
                -71
            ],
            [
                3,
                -47
            ],
            [
                38,
                -48
            ],
            [
                4,
                -63
            ],
            [
                -47,
                -123
            ],
            [
                -9,
                -71
            ],
            [
                11,
                -54
            ],
            [
                -57,
                -87
            ],
            [
                -104,
                -75
            ],
            [
                -69,
                -86
            ],
            [
                -104,
                -4
            ],
            [
                -38,
                -21
            ],
            [
                -37,
                -57
            ],
            [
                -48,
                -3
            ],
            [
                -24,
                -37
            ],
            [
                -133,
                2
            ],
            [
                -121,
                -49
            ],
            [
                -58,
                -136
            ],
            [
                -55,
                -96
            ],
            [
                -30,
                6
            ],
            [
                -27,
                44
            ],
            [
                -37,
                -6
            ],
            [
                -49,
                23
            ],
            [
                -126,
                -12
            ],
            [
                2,
                71
            ],
            [
                -15,
                35
            ],
            [
                58,
                32
            ],
            [
                84,
                128
            ],
            [
                -14,
                36
            ],
            [
                9,
                72
            ],
            [
                -8,
                116
            ],
            [
                11,
                66
            ],
            [
                36,
                92
            ],
            [
                50,
                40
            ],
            [
                54,
                73
            ],
            [
                40,
                10
            ],
            [
                31,
                48
            ],
            [
                123,
                14
            ],
            [
                17,
                30
            ],
            [
                49,
                20
            ],
            [
                4,
                31
            ],
            [
                43,
                22
            ],
            [
                16,
                52
            ],
            [
                52,
                -1
            ],
            [
                23,
                -28
            ],
            [
                29,
                41
            ],
            [
                48,
                31
            ],
            [
                48,
                -7
            ],
            [
                61,
                -69
            ],
            [
                39,
                6
            ],
            [
                64,
                89
            ],
            [
                4,
                88
            ],
            [
                26,
                46
            ],
            [
                7,
                65
            ],
            [
                36,
                83
            ],
            [
                68,
                57
            ],
            [
                8,
                31
            ]
        ],
        [
            [
                12593,
                6134
            ],
            [
                69,
                -41
            ],
            [
                29,
                -40
            ],
            [
                -43,
                -57
            ],
            [
                -2,
                -37
            ],
            [
                -42,
                -73
            ],
            [
                -48,
                -24
            ],
            [
                -61,
                1
            ],
            [
                -6,
                54
            ],
            [
                37,
                23
            ],
            [
                31,
                69
            ],
            [
                -9,
                36
            ],
            [
                45,
                89
            ]
        ],
        [
            [
                12604,
                6602
            ],
            [
                41,
                -56
            ],
            [
                -47,
                -69
            ],
            [
                -65,
                10
            ],
            [
                -1,
                60
            ],
            [
                72,
                55
            ]
        ],
        [
            [
                6224,
                9339
            ],
            [
                18,
                -65
            ],
            [
                -55,
                10
            ],
            [
                10,
                51
            ],
            [
                27,
                4
            ]
        ],
        [
            [
                41419,
                55639
            ],
            [
                41,
                -32
            ],
            [
                3,
                -78
            ],
            [
                59,
                22
            ],
            [
                -6,
                -95
            ],
            [
                -50,
                -27
            ],
            [
                8,
                -39
            ],
            [
                55,
                -50
            ],
            [
                52,
                60
            ],
            [
                48,
                21
            ],
            [
                23,
                62
            ],
            [
                -5,
                40
            ],
            [
                27,
                59
            ],
            [
                90,
                -20
            ],
            [
                66,
                -66
            ],
            [
                69,
                -19
            ],
            [
                -15,
                -43
            ],
            [
                -14,
                -149
            ],
            [
                -38,
                -97
            ],
            [
                54,
                13
            ],
            [
                22,
                -37
            ],
            [
                56,
                -23
            ],
            [
                18,
                -69
            ],
            [
                58,
                38
            ],
            [
                98,
                -14
            ],
            [
                39,
                -33
            ],
            [
                110,
                1
            ],
            [
                -2,
                40
            ],
            [
                50,
                52
            ],
            [
                13,
                -67
            ],
            [
                102,
                -12
            ],
            [
                76,
                38
            ],
            [
                28,
                -28
            ],
            [
                49,
                -1
            ]
        ],
        [
            [
                40620,
                58291
            ],
            [
                110,
                -12
            ],
            [
                8,
                54
            ],
            [
                95,
                -32
            ],
            [
                44,
                -45
            ],
            [
                65,
                -14
            ],
            [
                20,
                -41
            ],
            [
                44,
                26
            ],
            [
                -26,
                84
            ],
            [
                -38,
                33
            ],
            [
                36,
                39
            ],
            [
                78,
                46
            ],
            [
                58,
                83
            ],
            [
                47,
                18
            ],
            [
                17,
                68
            ],
            [
                76,
                8
            ],
            [
                133,
                -11
            ],
            [
                55,
                -19
            ],
            [
                68,
                14
            ],
            [
                62,
                -8
            ],
            [
                13,
                30
            ],
            [
                114,
                56
            ],
            [
                43,
                -94
            ],
            [
                46,
                -11
            ],
            [
                100,
                -83
            ],
            [
                40,
                32
            ],
            [
                72,
                9
            ],
            [
                46,
                -78
            ],
            [
                64,
                0
            ],
            [
                69,
                -27
            ],
            [
                38,
                15
            ],
            [
                49,
                -66
            ],
            [
                94,
                15
            ],
            [
                25,
                -49
            ],
            [
                41,
                -7
            ],
            [
                70,
                -97
            ],
            [
                95,
                -18
            ],
            [
                184,
                -101
            ],
            [
                84,
                -23
            ],
            [
                126,
                -9
            ],
            [
                57,
                31
            ],
            [
                45,
                0
            ],
            [
                35,
                28
            ],
            [
                47,
                -14
            ],
            [
                76,
                -48
            ],
            [
                15,
                -26
            ],
            [
                70,
                25
            ],
            [
                66,
                -15
            ],
            [
                52,
                42
            ],
            [
                103,
                36
            ],
            [
                55,
                7
            ],
            [
                74,
                33
            ],
            [
                87,
                -27
            ],
            [
                76,
                85
            ],
            [
                97,
                -9
            ],
            [
                122,
                59
            ],
            [
                29,
                66
            ],
            [
                100,
                55
            ],
            [
                122,
                25
            ],
            [
                -25,
                -38
            ],
            [
                30,
                -65
            ],
            [
                -31,
                -45
            ],
            [
                123,
                -35
            ],
            [
                46,
                -42
            ],
            [
                -11,
                -50
            ],
            [
                30,
                -75
            ]
        ],
        [
            [
                30066,
                58741
            ],
            [
                27,
                70
            ],
            [
                8,
                84
            ],
            [
                34,
                34
            ],
            [
                -10,
                68
            ],
            [
                21,
                49
            ],
            [
                65,
                -5
            ],
            [
                98,
                22
            ],
            [
                41,
                49
            ],
            [
                63,
                3
            ],
            [
                124,
                -42
            ],
            [
                48,
                16
            ],
            [
                80,
                -4
            ],
            [
                39,
                -40
            ],
            [
                76,
                -2
            ],
            [
                36,
                46
            ],
            [
                116,
                -12
            ],
            [
                19,
                -27
            ],
            [
                69,
                21
            ],
            [
                61,
                -16
            ],
            [
                33,
                27
            ],
            [
                85,
                9
            ],
            [
                97,
                -54
            ],
            [
                54,
                15
            ],
            [
                98,
                -10
            ],
            [
                54,
                -18
            ],
            [
                109,
                6
            ],
            [
                36,
                67
            ],
            [
                85,
                -42
            ],
            [
                102,
                2
            ],
            [
                114,
                -33
            ],
            [
                102,
                49
            ],
            [
                43,
                -2
            ],
            [
                83,
                51
            ],
            [
                49,
                4
            ],
            [
                50,
                57
            ],
            [
                43,
                -42
            ],
            [
                69,
                -24
            ],
            [
                30,
                -47
            ],
            [
                40,
                23
            ],
            [
                90,
                -19
            ],
            [
                50,
                -35
            ],
            [
                36,
                18
            ],
            [
                103,
                21
            ],
            [
                78,
                44
            ],
            [
                17,
                30
            ],
            [
                55,
                -21
            ],
            [
                117,
                4
            ],
            [
                60,
                13
            ],
            [
                -1,
                55
            ],
            [
                55,
                -2
            ],
            [
                33,
                37
            ],
            [
                15,
                56
            ],
            [
                54,
                -4
            ],
            [
                81,
                64
            ],
            [
                1,
                62
            ],
            [
                32,
                3
            ],
            [
                31,
                -45
            ],
            [
                144,
                -60
            ],
            [
                -22,
                -62
            ],
            [
                55,
                -20
            ],
            [
                8,
                -47
            ],
            [
                83,
                -77
            ],
            [
                49,
                -19
            ],
            [
                44,
                13
            ],
            [
                10,
                -70
            ],
            [
                37,
                -29
            ],
            [
                74,
                -15
            ],
            [
                76,
                34
            ],
            [
                22,
                28
            ],
            [
                60,
                -38
            ],
            [
                221,
                16
            ],
            [
                69,
                -21
            ],
            [
                44,
                17
            ],
            [
                160,
                14
            ],
            [
                52,
                -17
            ],
            [
                57,
                -60
            ],
            [
                60,
                -13
            ],
            [
                156,
                17
            ],
            [
                81,
                -65
            ],
            [
                -13,
                -38
            ],
            [
                125,
                -85
            ],
            [
                23,
                -40
            ],
            [
                119,
                4
            ],
            [
                31,
                15
            ],
            [
                110,
                17
            ],
            [
                9,
                -24
            ],
            [
                89,
                -5
            ],
            [
                78,
                -41
            ],
            [
                108,
                -20
            ],
            [
                103,
                13
            ],
            [
                102,
                -12
            ],
            [
                23,
                13
            ],
            [
                54,
                -34
            ],
            [
                151,
                -43
            ],
            [
                121,
                -12
            ],
            [
                7,
                -19
            ],
            [
                98,
                -11
            ],
            [
                81,
                -47
            ],
            [
                45,
                4
            ],
            [
                44,
                -30
            ],
            [
                78,
                -6
            ],
            [
                68,
                -31
            ],
            [
                34,
                8
            ],
            [
                70,
                -21
            ],
            [
                101,
                4
            ],
            [
                58,
                -18
            ],
            [
                60,
                25
            ],
            [
                61,
                -27
            ]
        ],
        [
            [
                47091,
                37868
            ],
            [
                -10,
                -77
            ],
            [
                -82,
                -61
            ],
            [
                -17,
                -40
            ],
            [
                7,
                -61
            ],
            [
                -73,
                -99
            ],
            [
                -35,
                -12
            ],
            [
                -53,
                -110
            ],
            [
                102,
                -189
            ],
            [
                90,
                -74
            ],
            [
                103,
                -37
            ],
            [
                45,
                -36
            ],
            [
                31,
                39
            ],
            [
                68,
                -37
            ],
            [
                -55,
                -97
            ],
            [
                -89,
                -35
            ],
            [
                -56,
                -2
            ],
            [
                -118,
                -71
            ],
            [
                -123,
                4
            ],
            [
                -49,
                -32
            ],
            [
                -134,
                -56
            ],
            [
                -87,
                12
            ],
            [
                -6,
                57
            ],
            [
                -48,
                33
            ],
            [
                -76,
                11
            ],
            [
                -27,
                -48
            ],
            [
                -39,
                25
            ],
            [
                -119,
                7
            ],
            [
                -50,
                -45
            ],
            [
                -36,
                -1
            ],
            [
                -49,
                -56
            ],
            [
                52,
                -71
            ],
            [
                -152,
                24
            ],
            [
                -14,
                58
            ],
            [
                -36,
                38
            ],
            [
                -56,
                24
            ],
            [
                -110,
                15
            ],
            [
                -29,
                -24
            ],
            [
                -1,
                -48
            ],
            [
                -67,
                1
            ],
            [
                -114,
                18
            ],
            [
                -30,
                -25
            ],
            [
                -65,
                -21
            ],
            [
                -55,
                -77
            ],
            [
                -44,
                -27
            ],
            [
                -25,
                -59
            ],
            [
                -39,
                -2
            ],
            [
                -101,
                -55
            ],
            [
                -37,
                -51
            ],
            [
                -38,
                -90
            ],
            [
                -2,
                -106
            ],
            [
                -44,
                -10
            ],
            [
                -49,
                37
            ],
            [
                -86,
                -77
            ],
            [
                -27,
                0
            ],
            [
                -59,
                -41
            ],
            [
                -27,
                8
            ],
            [
                -62,
                -69
            ],
            [
                -47,
                -33
            ]
        ]
    ],
    "objects": {
        "ESP_adm1": {
            "type": "GeometryCollection",
            "geometries": [
                {
                    "arcs": [
                        [
                            [
                                0
                            ]
                        ],
                        [
                            [
                                1
                            ]
                        ],
                        [
                            [
                                2,
                                3,
                                4,
                                5,
                                6
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 935,
                        "NAME_1": "Andalucía",
                        "VARNAME_1": "Andalousie|Andaluc¡a|Andalusien|Andaluzia",
                        "HASC_1": "ES.AN",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            7,
                            8,
                            9,
                            10,
                            11,
                            12,
                            13,
                            14,
                            15
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 936,
                        "NAME_1": "Aragón",
                        "VARNAME_1": "Aragão|Aragó|Aragón|Aragona|Aragonien",
                        "HASC_1": "ES.AR",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                16
                            ]
                        ],
                        [
                            [
                                17,
                                18,
                                19,
                                20
                            ],
                            [
                                21
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 937,
                        "NAME_1": "Cantabria",
                        "VARNAME_1": "Cantàbria|Cantábria|Cantabrie|Kantabrien",
                        "HASC_1": "ES.CB",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                22
                            ]
                        ],
                        [
                            [
                                -22
                            ]
                        ],
                        [
                            [
                                23,
                                24,
                                -14,
                                25,
                                26,
                                27,
                                28,
                                29,
                                30,
                                31,
                                -19
                            ],
                            [
                                32
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 938,
                        "NAME_1": "Castilla y León",
                        "VARNAME_1": "Castile and Leon|Castela e Leão|Castella i Lleó|Castile-Leon|Castilha-Leão|Castilla y León|Castille et Léon|Kastilien-León",
                        "HASC_1": "ES.CL",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            -13,
                            33,
                            -11,
                            34,
                            35,
                            -5,
                            36,
                            -28,
                            37,
                            -26
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 939,
                        "NAME_1": "Castilla-La Mancha",
                        "VARNAME_1": "Castela-La Mancha|Castela-Mancha|Castella-la Manxa|Castilha-La Mancha",
                        "HASC_1": "ES.CM",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                38
                            ]
                        ],
                        [
                            [
                                39,
                                -9,
                                40
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 940,
                        "NAME_1": "Cataluña",
                        "VARNAME_1": "Catalogna|Catalogne|Catalonia|Catalunha|Catalunya|Katalonien",
                        "HASC_1": "ES.CT",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                41
                            ]
                        ],
                        [
                            [
                                42
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 941,
                        "NAME_1": "Ceuta y Melilla",
                        "VARNAME_1": "",
                        "HASC_1": "ES.CE",
                        "TYPE_1": "Ciudades Autónomas",
                        "ENGTYPE_1": "Autonomous City"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                -33
                            ]
                        ],
                        [
                            [
                                -27,
                                -38
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 942,
                        "NAME_1": "Comunidad de Madrid",
                        "VARNAME_1": "Madrid|Communauté de Madrid| Community of Madrid|Comunidad de Madrid |Comunidade de Madrid|Comunitat de Madrid",
                        "HASC_1": "ES.MD",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            -16,
                            43,
                            44,
                            45
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 943,
                        "NAME_1": "Comunidad Foral de Navarra",
                        "VARNAME_1": "Communauté forale de Navarre|Comunidade Foral de Navarra|Comunitat Foral|Navarra",
                        "HASC_1": "ES.NA",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                -34,
                                -12
                            ]
                        ],
                        [
                            [
                                -40,
                                46,
                                47,
                                -35,
                                -10
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 944,
                        "NAME_1": "Comunidad Valenciana",
                        "VARNAME_1": "Valencia|Communauté de Valence|Comunidade Valenciana|Comunidad Valenciana|Comunitat Valenciana",
                        "HASC_1": "ES.VC",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            -37,
                            -4,
                            48,
                            -29
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 945,
                        "NAME_1": "Extremadura",
                        "VARNAME_1": "Estremadura|Estrémadure",
                        "HASC_1": "ES.EX",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                49
                            ]
                        ],
                        [
                            [
                                50
                            ]
                        ],
                        [
                            [
                                51,
                                -31,
                                52
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 946,
                        "NAME_1": "Galicia",
                        "VARNAME_1": "Galice|Gal¡cia|Galicien|Galiza|Galizia",
                        "HASC_1": "ES.GA",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                53
                            ]
                        ],
                        [
                            [
                                54
                            ]
                        ],
                        [
                            [
                                55
                            ]
                        ],
                        [
                            [
                                56
                            ]
                        ],
                        [
                            [
                                57
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 947,
                        "NAME_1": "Islas Baleares",
                        "VARNAME_1": "Balearic Islands|Balearen|Balearene|Baleares|Islas Baleares|Baleari|Îles Baléares|Ilhas Baleares|Illes Balears",
                        "HASC_1": "ES.IB",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            [
                                58
                            ]
                        ],
                        [
                            [
                                59
                            ]
                        ],
                        [
                            [
                                60
                            ]
                        ],
                        [
                            [
                                61
                            ]
                        ],
                        [
                            [
                                62
                            ]
                        ],
                        [
                            [
                                63
                            ]
                        ],
                        [
                            [
                                64
                            ]
                        ],
                        [
                            [
                                65
                            ]
                        ],
                        [
                            [
                                66
                            ]
                        ],
                        [
                            [
                                67
                            ]
                        ],
                        [
                            [
                                68
                            ]
                        ]
                    ],
                    "type": "MultiPolygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 948,
                        "NAME_1": "Islas Canarias",
                        "VARNAME_1": "Canarias|Canary Islands|Canárias|Ilhas Canárias|Canarie|Îles Canaries|Illes Canàries|Kanariøyene|Kanarische Inseln",
                        "HASC_1": "ES.CN",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            69,
                            -44,
                            -15,
                            -25
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 949,
                        "NAME_1": "La Rioja",
                        "VARNAME_1": "Rioja",
                        "HASC_1": "ES.RI",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            -45,
                            -70,
                            -24,
                            -18,
                            70
                        ],
                        [
                            -17
                        ],
                        [
                            -23
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 950,
                        "NAME_1": "País Vasco",
                        "VARNAME_1": "Basque Country|Baskenland|Basque Autonomous Community|Basque Provinces|CAV|Comunidad Autonoma Vasca",
                        "HASC_1": "ES.PV",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            -20,
                            -32,
                            -52,
                            71
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 951,
                        "NAME_1": "Principado de Asturias",
                        "VARNAME_1": "Astúrias|Asturie|Asturien|Asturies|Astúries|Asturias",
                        "HASC_1": "ES.AS",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                },
                {
                    "arcs": [
                        [
                            -48,
                            72,
                            -6,
                            -36
                        ]
                    ],
                    "type": "Polygon",
                    "properties": {
                        "ISO": "ESP",
                        "NAME_0": "Spain",
                        "ID_1": 952,
                        "NAME_1": "Región de Murcia",
                        "VARNAME_1": "Murcia|Região de Múrcia|Regió de Múrcia|Région de Murcie|Region of Murcia",
                        "HASC_1": "ES.MC",
                        "TYPE_1": "Comunidad Autónoma",
                        "ENGTYPE_1": "Autonomous Community"
                    }
                }
            ]
        }
    }
}