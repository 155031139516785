import React from "react"
import { Box, Text } from "@sqymagma/elements"


export default (item) => {
    const Item = ({title, data, color}) => <Text as="p" textStyle="s" color={color}>{title}:<Text as="span" color="text06"> {( data >= 0) ? data : "n/a"}</Text></Text>
    return (
        <Box>
            <Text as="p" textStyle="s">{item.place}</Text>
            {item.data &&
                <>
                    <Item title="Casos confirmados" color="support02" data={item.data.casosConfirmadosDiario}/>
                    <Item title="Casos fallecidos" color="support01" data={item.data.casosFallecidosDiario}/>
                    <Item title="Casos recuperados" color="support03" data={item.data.casosRecuperadosDiario}/>
                </>
            }
        </Box>
    )
}