import { COLORS } from "./constants.js"
import { multipleLines, zeroLines } from "./utils"
import annotations from "./annotations"

/**
 * Objeto base de la configuración de ApexCharts
 */
export const ApexChartsConfig = {
  options: {
    theme: {
      mode: "light",
      palette: "palette1",
      monochrome: {
        enabled: false,
        color: "#255aee",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    chart: {
      id: "covid",
      type: "line",
      defaultLocale: "es",
      width: "100%",
      locales: [
        {
          name: "es",
          options: {
            months: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            shortMonths: [
              "Ene",
              "Feb",
              "Mar",
              "Abr",
              "May",
              "Jun",
              "Jul",
              "Ago",
              "Sep",
              "Oct",
              "Nov",
              "Dic",
            ],
            toolbar: {
              download: "Descargar",
              selection: "Selección",
              selectionZoom: "Seleccionar Zoom",
              zoomIn: "Zoom In",
              zoomOut: "Zoom Out",
              pan: "Panning",
              reset: "Reiniciar Zoom",
            },
          },
        },
      ],
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: -10,
        tools: {
          download: `<img width="40px" class="graph-icon download-icon" src="/../../../images/svg/graph/download.svg" />`,
          zoom: `<img class="graph-icon" src="/../../../images/svg/graph/zoom.svg" />`,
          zoomin: `<img class="graph-icon" src="/../../../images/svg/graph/zoomin.svg" />`,
          zoomout: `<img class="graph-icon" src="/../../../images/svg/graph/zoomout.svg" />`,
          pan: `<img width="20px" class="graph-icon pan-icon" src="/../../../images/svg/graph/drag.svg" />`,
          reset: `<img class="graph-icon" src="/../../../images/svg/graph/reset.svg" />`,
          customIcons: [],
        },
        autoSelected: "zoom",
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: "dd - MMM",
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    noData: {
      text: "NO DATA",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    legend: {
      show: false,
    },
    markers: {
      size: 0,
      hover: {
        size: 0,
      },
    },
    responsive: [
      {
        breakpoint: 500,
        options: {
          chart: {
            height: 360,
          },
          yaxis: {
            min: 0,
            showForNullSeries: false,
            forceNiceScale: true,
            labels: {
              maxWidth: 20,
              style: {
                fontSize: "10px",
              },
            },
          },
          xaxis: {
            forceNiceScale: true,
            labels: {
              max: 12,
              style: {
                fontSize: "10px",
              },
            },
          },
        },
      },
    ],
  },
  series: [],
  annotations,
}

const getCCAAName = globalName => {
  return globalName
    .replace("Confirmados - ", "")
    .replace("Fallecidos - ", "")
    .replace("Recuperados - ", "")
}

const getFormattedDate = originalDate => {
  const date = new Date(originalDate)
  const options = { month: "long", day: "numeric" }
  return date.toLocaleDateString("es-ES", options)
}

const seriesLabels = {
  confirmed: { label: "Confirmados", color: COLORS[0] },
  recovered: { label: "Recuperados", color: COLORS[1] },
  deaths: { label: "Fallecidos", color: COLORS[2] },
}

/**
 * Genera la función para mostrar correctamente el tooltip dependiendo del estado que se encuentre el gráfico
 * @param {Object} selectedSeries Series seleccionadas
 * @param {String[]} selectedCCAA Array de comunidades seleccionadas
 */
export const tooltipFunction = (selectedSeries, selectedCCAA) => {
  return ({ series, seriesIndex, dataPointIndex, w }) => {
    if (multipleLines(selectedSeries)) {
      const name = getCCAAName(selectedCCAA[0])
      const date = getFormattedDate(w.config.series[seriesIndex].data[dataPointIndex][0])

      const filteredMeta = Object.values(seriesLabels).forEach((_, index) => {
        if (index === 0 && selectedSeries.confirmed) return true
        if (index === 1 && selectedSeries.recovered) return true
        if (index === 2 && selectedSeries.deaths) return true
      })

      return `
        <div class="tooltip">
          <p class="tooltip-title"><img class="tooltip-icon" src="/../../../images/svg/graph/calendar.svg" /> ${date} - ${name}</p>
          ${series && series
            .map((serie, index) => {
              return `
              <p class="tooltip-element"><span class="tooltip-color" style="background-color: ${filteredMeta[index].color}"></span><span class="tooltip-ccaa">${filteredMeta[index].label}</span> <span class="tooltip-data">${serie[dataPointIndex]}</span></p>
            `
            })
            .join("")}
        </div>
      `
    }

    const filteredCCAA = w.config.series
      .filter(serie => {
        const name = getCCAAName(serie.name)
        return selectedCCAA.includes(name)
      })
      .map(serie => ({
        ...serie,
        name: getCCAAName(serie.name),
        data: serie.data[dataPointIndex],
        date: getFormattedDate(w.config.series[seriesIndex].data[dataPointIndex][0]),
      }))

    return `
      <div class="tooltip">
        <p class="tooltip-title"><img class="tooltip-icon" src="/../../../images/svg/graph/calendar.svg"/> ${filteredCCAA[0] &&
      filteredCCAA[0].date}</p>
        ${filteredCCAA
          .map((ccaa, index) => {
            return `<p class="tooltip-element"><span class="tooltip-color" style="background-color: ${
              COLORS[selectedCCAA.indexOf(ccaa.name)]
            }"></span><span class="tooltip-ccaa">${ccaa.name}</span> <span class="tooltip-data">${
              ccaa.data[1]
            }</span></p>`
          })
          .join("")}
      </div>
    `
  }
}

/**
 * Generador de la función para colorear las series en función del estado que se encuentre la gráfica.
 * @param {Object} selectedSeries Series seleccionadas
 * @param {String[]} selectedCCAA Array de comunidades seleccionadas
 */
export const formatColors = (selectedSeries, selectedCCAA) => {
  if (selectedCCAA.length === 0) {
    return ["#cbd5e0"]
  } else if (zeroLines(selectedSeries)) {
    return ["#FFF"]
  } else if (multipleLines(selectedSeries)) {
    return [
      function({ value, seriesIndex, w }) {
        if (value < 0) {
          return "#FFF"
        }

        if (w.config.series[seriesIndex].name) {
          const serieType = w.config.series[seriesIndex].name

          if (serieType.includes("Confirmados")) {
            return COLORS[0]
          }
          if (serieType.includes("Recuperados")) {
            return COLORS[1]
          }
          if (serieType.includes("Fallecidos")) {
            return COLORS[2]
          }
        } else {
          return "#cbd5e0"
        }
      },
    ]
  } else {
    return [
      function({ value, seriesIndex, w }) {
        if (value < 0) {
          return "#FFF"
        }

        if (w.config.series[seriesIndex].name) {
          let indexSelected = selectedCCAA.indexOf(
            w.config.series[seriesIndex].name.split(" - ")[1]
          )
          if (indexSelected > -1) {
            return COLORS[indexSelected]
          } else {
            return "#cbd5e0"
          }
        } else {
          return "#cbd5e0"
        }
      },
    ]
  }
}
