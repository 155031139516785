import React from "react"
import ContentLoader from "react-content-loader" 

const MapLoader = () => (
  <ContentLoader 
    speed={2}
    style={{ width: "100%", maxWidth:"500px"}}
    viewBox="0 0 400 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="10" cy="20" r="8" /> 
    <rect x="25" y="15" rx="5" ry="5" width="79" height="10" /> 
    <circle cx="122" cy="19" r="8" /> 
    <rect x="137" y="14" rx="5" ry="5" width="79" height="10" /> 
    <circle cx="235" cy="19" r="8" /> 
    <rect x="250" y="14" rx="5" ry="5" width="79" height="10" /> 
    <circle cx="32" cy="-107" r="8" /> 
    <rect x="47" y="-112" rx="5" ry="5" width="79" height="10" /> 
    <circle cx="166" cy="203" r="146" />
  </ContentLoader>
)

export default MapLoader