import React, { useState } from 'react'
import Modal from 'react-modal'
import { Text, Flex, Box } from "@sqymagma/elements"
import { MenuClose } from "./IconSystem"

export default ({ textLink, children }) => {
    
    const [ isModalOpen, setIsModalOpen ] = useState(false)

    const handleModalOpen = event => {
      setIsModalOpen(true)
    }
  
    const handleModalClose = event => {
      setIsModalOpen(false)
    }
  
    Modal.setAppElement('#modal')


        return (
            <span id="modal">
            <Box as="span" position="relative">
            <Text onClick={handleModalOpen} css={`{ cursor: pointer }`}>{textLink}</Text>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
                contentLabel={textLink}
                style={{
                    overlay: {
                        zIndex: "9999999",
                        backgroundColor: '#292929ad',
                        maxHeight: '100vh',
                        overflow: 'hidden'
                    },
                    content: {
                      color: 'lightsteelblue'
                    }
                  }}
            >
                {children}
                <Flex position="fixed" right="16px" top="16px" zIndex="2">
                    <MenuClose fill="inverse04" height="16px" cursor="pointer" onClick={handleModalClose}/>
                </Flex>

            </Modal>
            </Box>
            </span>
        )
  }