export const populationCCAA = [
  {
    "Name": "Andalucía",
    "population": 8405294
  },
  {
    "Name": "Aragón",
    "population": 1316064
  },
  {
    "Name": "Asturias",
    "population": 1024381
  },
  {
    "Name": "Baleares",
    "population": 1176627
  },
  {
    "Name": "Canarias",
    "population": 2188626
  },
  {
    "Name": "Cantabria",
    "population": 580997
  },
  {
    "Name": "Castilla-La Mancha",
    "population": 2030807
  },
  {
    "Name": "Castilla y León",
    "population": 2410819
  },
  {
    "Name": "Cataluña",
    "population": 7516544
  },
  {
    "Name": "Ceuta",
    "population": 84913
  },
  {
    "Name": "Comunidad Valenciana",
    "population": 4948411
  },
  {
    "Name": "Extremadura",
    "population": 1067272
  },
  {
    "Name": "Galicia",
    "population": 2699299
  },
  {
    "Name": "Madrid",
    "population": 6587711
  },
  {
    "Name": "Melilla",
    "population": 84667
  },
  {
    "Name": "Murcia",
    "population": 1479098
  },
  {
    "Name": "Navarra",
    "population": 646197
  },
  {
    "Name": "País Vasco",
    "population": 2172591
  },
  {
    "Name": "La Rioja",
    "population": 312719
  }
]
