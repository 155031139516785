import React from "react";
import { theme } from '../../utils/themeGet'
import { Box, Flex, Text } from "@sqymagma/elements"

export const GraphContainer = ({ children, my, p, overflow="visible" }) => (
    <Box my={my} p={p} overflow={overflow}
        css={`{
            border: 1px solid #DDDEE0;
            border-radius: ${theme("radii.xs")};
            background: ${theme("color.primaryBackground")};
        }`}
    >
        { children }
    </Box>
)

export const GraphLegend = ({arrItems, color, mb=0}) => {
    return (
    <Flex flexWrap={["no-wrap","wrap"]}>
        {arrItems.map((item, idx) => (
        <Flex key={idx} alignItems="center" mr="s"  mb={mb}
            css={`{white-space: nowrap}`}
        >
            <Box bg={color} opacity={item.opacity} width={["9px","11px"]} height={["9px","11px"]} mr="4px" borderRadius={["9px","11px"]}/>
            <Text textStyle="tiny">{item.legend}</Text>
        </Flex>
        ))}
    </Flex>
    )
}

export const Line = _ => <Box as="span" my={["xs","s"]} mh="auto" width={1} display="block" css={`border-top: 1px solid ${theme(`color.ui03`)}`}/>