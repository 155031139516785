import React, {useState, useEffect} from "react"
import { GraphContainer } from "./UIElements";
import Chart from "react-apexcharts";
import useColoringSeries from "../../utils/useColoringSeries"
import  stackedBarOptions  from "./graphOptions"

const ChartCases = ({ ccaa, mode }) => {

    const [ state, setState ] = useState(null)
    const [ dataset, setDataset ] = useState(null)
    const seriesColor = useColoringSeries(mode)
    const getPerCapitaData = (data, population) =>  (data / population * 100000).toFixed(1)
    
    useEffect(() => {
        if(mode.switched) {
            setDataset([...ccaa].map(el => (
                {
                    uciCases : getPerCapitaData(el.data.casosUci, el.data.population),
                    hospitalCases : getPerCapitaData(el.data.casosHospitalizados, el.data.population),
                    isolatedCases : getPerCapitaData((el.data.casosConfirmados - (el.data.casosHospitalizados + el.data.casosUci)), el.data.population),
                    percapita: el.data.percapita,
                    ccaa: el.properties.NAME_1,
                    colors: ['#1A73E8', '#B32824', "#000000"]
                }
            )).sort((a, b) => b.percapita - a.percapita))
        } else {
            setDataset([...ccaa].map(el => (
                {
                    uciCases : el.data.casosUci,
                    hospitalCases : el.data.casosHospitalizados,
                    isolatedCases : el.data.casosConfirmados - (el.data.casosHospitalizados + el.data.casosUci),
                    totalCases: el.data.casosConfirmados,
                    ccaa: el.properties.NAME_1,
                    colors: ['#1A73E8', '#B32824', "#000000"]
                }
            )).sort((a, b) => b.totalCases - a.totalCases))
        }
    }, [mode, ccaa])

    useEffect(() => {
        dataset && setState(stackedBarOptions(dataset, seriesColor));
    }, [dataset, seriesColor])

    return (
        state 
        ?   <GraphContainer p="0" my="s">
                <Chart options={state.options} series={state.series} type="bar" height="600px"/>
            </GraphContainer>
        : "Loading data..."
        
    )
}

export default ChartCases