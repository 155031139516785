import React, {useState, useEffect} from 'react'
import { MaxWidth, Columns, Column, Text, Box, Flex } from '@sqymagma/elements';
import spainIsocodes from './endpoints.json'
import GraphToggle from "./GraphToggle";
import LineChartItem from './LineChartItem';


const LineCharts = _ => {
    const [ mode, setMode ] = useState(null)

    useEffect(() => {
        setMode({
            title: "Lineal",
            switched: false,
            color: 'support03'
        })
    }, [])

    const switchMode = () => {
        console.log('called')
        setMode({
            title: mode.title === "Lineal" ? "Logarítmica" : "Lineal",
            switched: !mode.switched,
            color: 'support03'
        })
    }

    return(
        <>
        <LineChartsStickyHeader mode={mode} switchMode={switchMode}/>
        <MaxWidth>
            <Columns hs="s" vs="s" mb="l">
            { Object.keys(spainIsocodes.ccaa).map((ccaa, idx) => (
                    <Column key={idx} width={[1, null, 1/2, null, null, 1/3]}>
                        <LineChartItem isocode={ccaa} ccaa={spainIsocodes.ccaa[ccaa]} mode={mode}/>
                    </Column>
            )) }
            </Columns>
        </MaxWidth>
        </>
    )
}
export default LineCharts;

    
const LineChartsStickyHeader = (props) => {
    const { mode, switchMode } = props
    return (
    <Box bg="ui07" minHeight="30px" width="100vw" py="xs" mb="l" position="sticky" top="49px" zIndex={999}>
        <MaxWidth>
            <GraphToggle 
                mode={mode} 
                switchMode={switchMode} 
                opt1="Lineal" opt2="Logarítmica" 
                width={'200px'}
            />
        </MaxWidth>
    </Box>
    )
}