import React from "react"
import DataEsLoader from "../../Loaders/DataEsLoader"
import { formatNumberDot } from '../../../utils/formatNumber'
import { Box, Text, Inline } from '@sqymagma/elements'


 export default ( { item, title, width } ) => (
    item ? (
        <Box width={width} p="xs" >
            <Text as="h4" textStyle="xs" caps
                css={`{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }`}
            >
                { title }
            </Text>
            <Text as="p" textStyle="xl" color="text05">{formatNumberDot(item.acumulado)}</Text>
            <Inline alignItems="center" mb="xs">
                <Text as="h4" textStyle="l" color={item.color}>{item.variacion}%</Text>
                <Box height="22px" width="22px">
                    <img src={item.tendencia !== 0 ? (item.tendencia >= 1  ? "/images/svg/trend-positive.svg" : "/images/svg/trend-negative.svg" ) : "/images/svg/trend-neutral.svg"} alt="Tendencia"/>
                </Box>
            </Inline>
            <Box 
                bg="ui03" height="10px" borderRadius="10px"
                position="relative" overflow="hidden"
            >
                <Box 
                    width={`${item.progreso}%`} 
                    bg={item.color}
                    position="absolute" top="0" bottom="0"
                />
            </Box>
        </Box>
    ) : <Box  width={width} p="xs">
        <DataEsLoader/>
        </Box>
 )