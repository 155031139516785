const countTrues = (selectedSeries) => {
  const countTrueValues = Object.values(selectedSeries).reduce((acc, value) => {
    if (value) {
      return acc + 1
    } else {
      return acc
    }
  }, 0)
  return countTrueValues 
}

export const multipleLines = (selectedSeries) => {
  return countTrues(selectedSeries) > 1 ? true : false
}

export const zeroLines = (selectedSeries) => {
  return countTrues(selectedSeries) === 0
}

export const orderSeriesBySelected = (series, selectedCCAA) => {
  const newSeries = [...series].sort(serie => {
    let indexSelected = selectedCCAA.indexOf(serie.name.split(' - ')[1])
    if (indexSelected > -1 ) return -1
    return 0
  })
  return newSeries
}