import React from "react"
import ContentLoader from "react-content-loader" 

const BarChartLoader = () => (
  <ContentLoader 
    speed={2}
    style={{width:"100%", height: "100%", maxWidth:"500px"}}
    viewBox="0 0 350 500"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle cx="37" cy="20" r="8" /> 
    <rect x="52" y="15" rx="5" ry="5" width="220" height="10" /> 
    <circle cx="37" cy="50" r="8" /> 
    <rect x="52" y="45" rx="5" ry="5" width="220" height="10" /> 
    <circle cx="37" cy="80" r="8" /> 
    <rect x="52" y="75" rx="5" ry="5" width="220" height="10" /> 
    <circle cx="-60" cy="118" r="8" /> 
    <rect x="29" y="137" rx="5" ry="5" width="50" height="10" /> 
    <rect x="10" y="158" rx="5" ry="5" width="70" height="10" /> 
    <rect x="-210" y="219" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-200" y="229" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-190" y="239" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-180" y="249" rx="5" ry="5" width="50" height="10" /> 
    <rect x="29" y="116" rx="5" ry="5" width="50" height="10" /> 
    <rect x="29" y="202" rx="5" ry="5" width="50" height="10" /> 
    <rect x="10" y="223" rx="5" ry="5" width="70" height="10" /> 
    <rect x="29" y="181" rx="5" ry="5" width="50" height="10" /> 
    <rect x="29" y="267" rx="5" ry="5" width="50" height="10" /> 
    <rect x="10" y="288" rx="5" ry="5" width="70" height="10" /> 
    <rect x="29" y="246" rx="5" ry="5" width="50" height="10" /> 
    <rect x="29" y="332" rx="5" ry="5" width="50" height="10" /> 
    <rect x="10" y="353" rx="5" ry="5" width="70" height="10" /> 
    <rect x="29" y="311" rx="5" ry="5" width="50" height="10" /> 
    <rect x="28" y="397" rx="5" ry="5" width="50" height="10" /> 
    <rect x="9" y="418" rx="5" ry="5" width="70" height="10" /> 
    <rect x="28" y="376" rx="5" ry="5" width="50" height="10" /> 
    <rect x="28" y="462" rx="5" ry="5" width="50" height="10" /> 
    <rect x="9" y="483" rx="5" ry="5" width="70" height="10" /> 
    <rect x="28" y="441" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-181" y="555" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-200" y="576" rx="5" ry="5" width="70" height="10" /> 
    <rect x="-181" y="534" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-181" y="620" rx="5" ry="5" width="50" height="10" /> 
    <rect x="-200" y="641" rx="5" ry="5" width="70" height="10" /> 
    <rect x="-181" y="599" rx="5" ry="5" width="50" height="10" /> 
    <rect x="90" y="116" rx="5" ry="5" width="220" height="10" /> 
    <rect x="92" y="136" rx="5" ry="5" width="220" height="10" /> 
    <rect x="90" y="159" rx="5" ry="5" width="220" height="10" /> 
    <rect x="92" y="179" rx="5" ry="5" width="220" height="10" /> 
    <rect x="90" y="203" rx="5" ry="5" width="220" height="10" /> 
    <rect x="92" y="223" rx="5" ry="5" width="220" height="10" /> 
    <rect x="90" y="246" rx="5" ry="5" width="220" height="10" /> 
    <rect x="92" y="266" rx="5" ry="5" width="220" height="10" /> 
    <rect x="93" y="290" rx="5" ry="5" width="220" height="10" /> 
    <rect x="95" y="310" rx="5" ry="5" width="220" height="10" /> 
    <rect x="93" y="333" rx="5" ry="5" width="220" height="10" /> 
    <rect x="95" y="353" rx="5" ry="5" width="220" height="10" /> 
    <rect x="93" y="377" rx="5" ry="5" width="220" height="10" /> 
    <rect x="95" y="397" rx="5" ry="5" width="220" height="10" /> 
    <rect x="93" y="420" rx="5" ry="5" width="220" height="10" /> 
    <rect x="95" y="440" rx="5" ry="5" width="220" height="10" /> 
    <rect x="94" y="462" rx="5" ry="5" width="220" height="10" /> 
    <rect x="96" y="482" rx="5" ry="5" width="220" height="10" /> 
    <rect x="-339" y="855" rx="5" ry="5" width="220" height="10" /> 
    <rect x="-337" y="875" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)

export default BarChartLoader