export default (dataset, seriesColor) =>  (
    {
    series: [{
      name: 'Cuidados intensivos',
      data: dataset.map(el => el.uciCases)
    }, {
      name: 'Hospitalizado con síntomas',
      data: dataset.map(el => el.hospitalCases)
    }, {
      name: 'Aislamiento en el hogar',
      data: dataset.map(el => el.isolatedCases)
    }],
    options: {
        dataLabels: {
            enabled: false
        },
        colors: seriesColor,
        chart: {
            type: 'bar',
            height: '600px',
            stacked: true,
            toolbar: {
              show: true,
              tools: {
                  download: `<img width="40px" styles={"zIndex": 1} src="/../../../images/svg/graph/download.svg" />`,
              }
            }
        },
        plotOptions: {
            bar: {
            horizontal: true,
            },
        },
        stroke: {
            width: 0.5,
            colors: ['#fff']
        },
        xaxis: {
            categories: dataset.map(el => el.ccaa),
            labels: {
                minWidth: 0,
            },
            tickAmount: 4,
            forceNiceScale: true
        },
        yaxis: {
            labels: {
                minWidth: 100,
                maxWidth: 140,
            },
            title: {
            text: undefined
            },
            tickAmount: 3,
            forceNiceScale: true
        },
        tooltip: {
            y: {
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: -10,
            offsetY: 10,
            width: "100%",
            markers: {
               width: 11,
               height: 11,
               radius: 11
            }
        },
        responsive: [
            {
              breakpoint: 500,
              options: {
                chart: {
                  height: 450,
                },
                yaxis: {
                  min: 0,
                  showForNullSeries: false,
                  forceNiceScale: true,
                  labels: {
                    maxWidth: 100,
                    style: {
                      fontSize: "9px",
                    },
                  },
                },
                xaxis: {
                  forceNiceScale: true,
                  labels: {
                    max: 12,
                    style: {
                      fontSize: "9px",
                    },
                  },
                },
              },
              tooltip:{
                 fixed: {
                        enabled:true,
                        offsetX: 50,
                        position: "topRight"
                  }
              }
            },
          ],
    }
  }
)