import React from "react"
import ContentLoader from "react-content-loader" 

const DataEsLoader = () => (
  <ContentLoader 
    speed={2}
    style={{width:"100%"}}
    viewBox="0 0 165 75"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="120" height="10" /> 
    <rect x="0" y="45" rx="3" ry="3" width="53" height="11" /> 
    <rect x="187" y="48" rx="3" ry="3" width="72" height="11" /> 
    <rect x="58" y="45" rx="3" ry="3" width="20" height="11" /> 
    <rect x="0" y="19" rx="3" ry="3" width="140" height="16" /> 
    <rect x="166" y="23" rx="3" ry="3" width="173" height="11" /> 
    <rect x="0" y="65" rx="3" ry="3" width="165" height="9" />
  </ContentLoader>
)

export default DataEsLoader