import React from "react"
import { Box } from "@sqymagma/elements"
import { theme } from '../../utils/themeGet'
import { geoMercator, geoPath } from "d3-geo"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import MapTooltip from './MapTooltip'

const projection = geoMercator()
    .center([0, 40])
    .scale(1500)

const SpainMap = ({ ccaa, casesRanges, mode }) => {
    const getOpacity = (data, casesRanges, switched) => {
        const numCases = switched ? data.percapita : data.total; 
        let opacity = 1    
        casesRanges.forEach(range => {
            if ( numCases <= range.maxNumCases ) opacity = range.opacity
            return
        })
        return opacity;
    }

    return (
        ccaa && (
        <Box my={[0, "s", "m"]} 
            css={`{
                position:relative;
                svg {
                    overflow: visible;

                    path {
                        fill: ${theme(`color.${mode.color}`)};
                        stroke: ${theme("color.ui06")};
                        transition: fill ease-out .2s;
                
                        &#es-cn {
                            transform: translate(45%, -55%);
                            position: absolute;
                            outline: 1px solid #A0A6AF;
                            outline-offset: 5px
                        }
                    }
                }

                @media screen and (max-width: 1050px) and (min-width: 851px){
                    svg > g{
                        transform: scale(.8) translate(32%, 20%);
                    }
                }
                @media screen and (max-width: 850px)  and (min-width: 768px){
                    svg > g{
                        transform: scale(.7) translate(55%, 40%);
                    }
                }
                @media screen and (max-width: 600px){
                    svg {
                        height: 300px;
                    }
                    svg > g{
                        transform: translateX(5%);
                    }
                }
                @media screen and (max-width: 480px){
                    svg {
                        height: 200px;
                    }
                    svg > g{
                    }
                }
           
            }`}
        >

        <svg height="450" width="100%" viewBox="220 80 300 400">
            <g className="countries">
            {ccaa.map((item, i) => {
                return (
                    <Tippy 
                        key={i}
                        content={MapTooltip({data: item.data, place: item.properties.NAME_1})} 
                        trigger='mouseenter focus' 
                    >
                    <path
                        id={item.properties.HASC_1}
                        key={`path-${i}`}
                        d={geoPath().projection(projection)(item)}
                        className="country"
                        strokeWidth={.75}
                        fillOpacity={getOpacity({
                            total: item.data.casosConfirmadosDiario,
                            percapita: item.data.percapita
                        }, 
                            casesRanges, 
                            mode.switched
                        )}
                    >

                        <Tippy content={MapTooltip(i)} trigger='mouseenter focus' offset={[0, -10]}/>

                    </path>
                    </Tippy>
                    )
                })}
            </g>
        </svg>
        </Box>
        )
    )
}

export default SpainMap

