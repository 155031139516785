import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Box, Flex, Text } from "@sqymagma/elements";
import { theme } from "../utils/themeGet";
import IconSystem from "../components/IconSystem";

export default ({ file }) => (
  <Box
    position="relative"
    overflow="hidden"
    css={`
       {
        padding: 2px;
        cursor: pointer;
        &:hover {
          overflow: visible;
          transition: all ease 0.3s;
          .text-box {
            transition: all ease 0.3s;
            transform: translate(-90%, -50%);
            width: auto;
            opacity: 1;
          }
        }
      }
    `}
  >
    <a href={`/${file}`} target="_blank" rel="noopener noreferrer">
      <Flex
        borderRadius="50%"
        width={["45px", "80px"]}
        height={["45px", "80px"]}
        bg="brand04"
        position="relative"
        zIndex="1"
        alignItems="center"
        justifyContent="center"
        css={`
           {
            box-shadow: 0 0 4px ${theme("color.ui08")};
          }
        `}
      >
        <Flex
          borderRadius="50%"
          width={["35px", "50px"]}
          height={["35px", "50px"]}
          bg="ui09"
          alignItems="center"
          justifyContent="center"
          css={`
             {
              border: 1px solid rgba(0, 0, 0, 0.5);
            }
          `}
        >
          <IconSystem name="download" height="24px" fill="text01" />
        </Flex>
      </Flex>
      <Box
        className="text-box"
        position="absolute"
        left="0"
        opacity="0"
        top="50%"
        bg="brand01"
        color="text06"
        width="0"
        css={`
           {
            transform: translate(-50%, -50%);
            border-radius: 9px;
            padding: 0 24px 3px 12px;
          }
        `}
      >
        <Text
          textStyle="xs"
          css={`
             {
              white-space: nowrap;
            }
          `}
        >
          Descarga este material
        </Text>
      </Box>
    </a>
  </Box>
);
