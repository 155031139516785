export default {
  position: "front",
  xaxis: [
    {
      x: new Date("9 May 2020").getTime(),
      x2: new Date("23 May 2020").getTime(),
      strokeDashArray: 1,
      fillColor: "rgba(30, 30, 30, .1)",
      borderColor: "#AFB6C0",
      label: {
        borderColor: "rgba(30, 30, 30, .0)",
        style: {
          color: "#AFB6C0",
          background: "rgba(30, 30, 30, .0)",
          fontFamily: "Work Sans",
        },
        offsetX: 20,
        text: "Cuarta Prórroga",
      },
    },
    {
      x: new Date("25 Apr 2020").getTime(),
      x2: new Date("9 May 2020").getTime(),
      strokeDashArray: 1,
      fillColor: "rgba(30, 30, 30, .01)",
      borderColor: "#AFB6C0",
      label: {
        borderColor: "rgba(30, 30, 30, .0)",
        style: {
          color: "#AFB6C0",
          background: "rgba(30, 30, 30, .0)",
          fontFamily: "Work Sans",
        },
        offsetX: 20,
        text: "Tercera Prórroga",
      },
    },
    {
      x: new Date("11 Apr 2020").getTime(),
      x2: new Date("25 Apr 2020").getTime(),
      strokeDashArray: 1,
      fillColor: "rgba(30, 30, 30, .1)",
      borderColor: "#AFB6C0",
      label: {
        borderColor: "rgba(30, 30, 30, .0)",
        style: {
          color: "#AFB6C0",
          background: "rgba(30, 30, 30, .0)",
          fontFamily: "Work Sans",
        },
        offsetX: 20,
        text: "Segunda Prórroga",
      },
    },
    {
      x: new Date("27 Mar 2020").getTime(),
      x2: new Date("11 Apr 2020").getTime(),
      strokeDashArray: 1,
      fillColor: "rgba(30, 30, 30, .01)",
      borderColor: "#AFB6C0",
      label: {
        borderColor: "rgba(30, 30, 30, .0)",
        style: {
          color: "#AFB6C0",
          background: "rgba(30, 30, 30, .0)",
          fontFamily: "Work Sans",
        },
        offsetX: 20,
        text: "Primera Prórroga",
      },
    },
    {
      x: new Date("14 Mar 2020").getTime(),
      x2: new Date("27 Mar 2020").getTime(),
      fillColor: "rgba(30, 30, 30, 0.1)",
      borderColor: "#AFB6C0",
      label: {
        borderColor: "rgba(30, 30, 30, .0)",
        style: {
          fontSize: "10px",
          color: "#98A1AE",
          background: "rgba(30, 30, 30, .0)",
          fontFamily: "Work Sans",
        },
        offsetX: 20,
        offsetY: 0,
        text: "Estado de alarma",
      },
    },
  ],
  yaxis: [],
};
