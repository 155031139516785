import React, { useState, useEffect } from "react";
import { Text, MaxWidth, Columns, Column, Flex } from '@sqymagma/elements'
import SpainMap from "./SpainMap";
import ChartCases from "./ChartCases";
import { populationCCAA } from '../../data/spain/populationCCAA'
import { feature } from "topojson-client"
import { spainTopoJson } from "../../data/spain/spain-map"
import { GraphContainer, GraphLegend, Line } from "./UIElements";
import BarChartLoader from '../Loaders/BarChartLoader'
import MapLoader from '../Loaders/MapLoader'
import GraphToggle from "./GraphToggle";

export default () => {
    const [ mode, setMode ] = useState(null)
    const [ccaa, setCcaa] = useState(null)

    useEffect(() => {
        setMode({
            title: "Total",
            switched: false,
            color: "brand02"
        })
    }, [])

    const switchMode = () => {
        setMode({
            title: mode.title === "Total" ? "Per cápita" : "Total",
            color: mode.color === "brand02" ? "support02" : "brand02",
            switched: !mode.switched
        })
    }

    const casesRanges = [
        {
            maxNumCases: Infinity,
            opacity: 1,
            legend: "1000 o más"
        },
        {
            maxNumCases: 999,
            opacity: 0.7,
            legend: "100-999"
        },
        {
            maxNumCases: 99,
            opacity: 0.5,
            legend: "10-99"
        }, 
        {
            maxNumCases: 9,
            opacity: 0.2,
            legend: "1-9"
        }
    ]
    
    useEffect(() => {
        fetch(`https://covid19.secuoyas.io/api/v1/es/ccaa?ultimodia=true`)
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
            let ccaaArr = resultData.timeline[0].regiones;
            const features = feature(spainTopoJson, spainTopoJson.objects.ESP_adm1).features
            const spainCCAA = features
            .sort((a, b) => a.properties.HASC_1.localeCompare(b.properties.HASC_1))
            .map((item, i) => {
                item.data = ccaaArr[i].data
                item.data.population = populationCCAA[i].population
                item.data.percapita = +((ccaaArr[i].data.casosConfirmados / populationCCAA[i].population) * 100000).toFixed(2)
                item.properties.HASC_1 = item.properties.HASC_1.toLowerCase()
                .split(".")
                .join("-")
                return item
            })
            setCcaa(spainCCAA)
        }) 
        .catch(err => console.log("Error getting data from API: "+err))
    }, [])
    

  return (
    <>
    <MaxWidth>
        <GraphContainer p={["s", "20px"]}>
            {mode && ccaa && 
               <Flex alignItem="baseline" justifyContent="space-between" mb="m" flexWrap="wrap">
                   <Text color="ui06" textStyle="s" caps mb={["s", 0]} >Casos confirmados en España</Text>
                   <GraphToggle mode={mode} switchMode={switchMode} opt1="Total" opt2="Per cápita"/>
               </Flex>
           }
            <Columns hs="s" minHeight="600px" flexDirection={['column', null, 'row']}>
                <Column width={[1, null, 3/5]}>
                   {mode && ccaa ?
                        <>
                            <Line/>
                            <GraphLegend arrItems={casesRanges} color={mode.color}/>
                            <Line/>
                            <SpainMap ccaa={ccaa} casesRanges={casesRanges} mode={mode}/>
                        </>
                        :
                        <Flex width={1} alignItems="center" justifyContent="center">
                            <MapLoader/>
                        </Flex>
                   }
                </Column>
                <Column width={[1, null, 2/5]}>
                    {mode && ccaa 
                        ? <ChartCases ccaa={ccaa} casesRanges={casesRanges} mode={mode}/>
                        : 
                        <Flex width={1} alignItems="center" justifyContent="center">
                            <BarChartLoader/>
                        </Flex>
                    }
                </Column>
            </Columns>
        </GraphContainer>
    </MaxWidth>
    </>
  )
};